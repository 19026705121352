import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { CellProps } from 'react-table';
import { openModal, useModalContext } from '../../../contexts/ModalContext';
import { Purchase, SharedPurchase, User } from '../../../types/types';
import { formatDollar } from '../../../utils/functions';
import Button from '../../core/button/Button/Button';
import Icon from '../../core/display/Icon';
import Table, { CustomColumn } from '../../core/display/Table/Table';
import FilterTab from '../../core/layout/FilterTab/FilterTab';
import SharePurchaseMenu from '../purchasing/SharePurchaseMenu';

type TableData = {
  purchase: Purchase;
  purchaseId: string;
  name: string;
  date: string;
  price: number;
  seats: number;
  seatsRemaining: number;
  active: boolean;
  refunded: boolean;
  status: string;
  shares: SharedPurchase[];
};

interface Props {
  purchases: Purchase[];
  sharedPurchases: Purchase[];
  updateData: () => void;
  user: User;
}

function PurchaseHistory({ purchases, sharedPurchases, updateData, user }: Props): JSX.Element {
  const [myPurchasesTableData, setMyPurchasesTableData] = useState<TableData[]>([]);
  const [sharedPurchasesTableData, setSharedPurchasesTableData] = useState<TableData[]>([]);
  const [tableColumns, setTableColumns] = useState<CustomColumn<TableData>[]>([]);
  const [filterList, setFilterList] = useState<string[]>([]);

  const { modalDispatch } = useModalContext();

  const openShareMenu = useCallback(
    (purchase: Purchase) => {
      modalDispatch(
        openModal({
          heading: 'Share Purchase',
          closeButton: true,
          noActionButtons: true,
          form: false,
          children: <SharePurchaseMenu purchase={purchase} updateData={updateData} />,
        }),
      );
    },
    [modalDispatch, updateData],
  );

  useEffect(() => {
    const columns: CustomColumn<TableData>[] = [
      {
        Header: 'Date',
        accessor: 'date',
        className: 'left-align',
        Cell: function render({ cell: { value } }: CellProps<TableData>) {
          return <>{moment(value).format('MMM D YYYY @ h:mm A')}</>;
        },
      },
      { Header: 'Name', accessor: 'name', className: 'left-align' },

      {
        Header: 'Price',
        accessor: 'price',
        Cell: function render({ cell: { value } }: CellProps<TableData>) {
          return <>{formatDollar(value)}</>;
        },
      },
      { Header: 'Status', accessor: 'status' },
    ];
    if (user.role === 'TEACHER') {
      columns.splice(2, 0, {
        Header: 'Seats',
        columns: [
          { Header: 'Unused', accessor: 'seatsRemaining' },
          { Header: 'Total', accessor: 'seats' },
        ],
      });
      columns.push({
        Header: 'Sharing',
        accessor: 'shares',
        Cell: function render({ cell: { row } }: CellProps<TableData>) {
          if (row.original.purchase.user.userId === user.userId)
            return (
              <Button
                className="share-btn"
                variant="sm"
                onClick={() => openShareMenu(row.original.purchase)}
                ariaLabel="Share purchase"
              >
                <Icon code="people" />
                {row.original.shares.length}
              </Button>
            );
          return <>Shared by {user.name}</>;
        },
      });
    }

    const parsePurchasesToTableData = (purchaseList: Purchase[]): TableData[] => {
      const dataTable: TableData[] = [];
      purchaseList.forEach((purchase) => {
        const newRow: TableData = {
          purchase: purchase,
          purchaseId: purchase.purchaseId,
          name: purchase.name,
          date: purchase.createdAt,
          price: purchase.price,
          seats: purchase.seats,
          seatsRemaining: purchase.seatsRemaining,
          active: purchase.active,
          refunded: purchase.refunded,
          status: `${purchase.active ? 'Active' : 'Inactive'}${purchase.refunded ? ', Refunded' : ''}`,
          shares: purchase.sharedPurchases,
        };

        dataTable.push(newRow);
      });
      return dataTable;
    };

    setMyPurchasesTableData(parsePurchasesToTableData(purchases));
    setSharedPurchasesTableData(parsePurchasesToTableData(sharedPurchases));
    setTableColumns(columns);
  }, [purchases, sharedPurchases, user, openShareMenu]);

  const tableData = filterList.includes('Shared With Me') ? sharedPurchasesTableData : myPurchasesTableData;

  return (
    <>
      {user.role === 'TEACHER' ? (
        <FilterTab label="Show:" setFilterList={setFilterList}>
          <FilterTab.Button id="btn-mine" type="radio" name="purchase-filters" defaultChecked={true}>
            My Purchases
          </FilterTab.Button>
          <FilterTab.Button id="btn-shared" type="radio" name="purchase-filters">
            Shared With Me
          </FilterTab.Button>
        </FilterTab>
      ) : null}

      {tableData.length > 0 ? (
        <Table
          columns={tableColumns}
          data={tableData}
          sortBy="date"
          title="Purchase History"
          id="purchase-history-table"
          noWrapper
        />
      ) : (
        <p id="no-purchases">No purchases to show</p>
      )}
    </>
  );
}

export default PurchaseHistory;
