import _ from 'lodash';
import React, { useEffect, useRef, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import MainSidebar, { sidebarPaths } from '../Sidebar/MainSidebar';

interface Props {
  children: React.ReactNode;
}

function SiteContainer({ children }: Props): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const calcOffset = useMemo(
    () =>
      _.debounce(
        () => {
          requestAnimationFrame(() => {
            const siteBanner = document.getElementById('site-banner');
            if (siteBanner && containerRef.current)
              containerRef.current.style.height = `calc(100vh - ${siteBanner.offsetHeight}px)`;
          });
        },
        100,
        { maxWait: 1000 },
      ),
    [],
  );

  useEffect(() => {
    const config: MutationObserverInit = { attributes: true, childList: true, subtree: true };
    const observer = new MutationObserver(calcOffset);
    const siteBanner = document.getElementById('site-banner');
    if (siteBanner) observer.observe(siteBanner, config);
    return () => {
      observer.disconnect();
    };
  }, [calcOffset]);

  return (
    <div ref={containerRef} id="site-container">
      <Routes>
        {sidebarPaths.map((path) => (
          <Route key={path} path={path} element={<MainSidebar />} />
        ))}
        <Route path="*" element={<></>} />
      </Routes>
      <main ref={contentRef} id="site-content">
        <div tabIndex={-1} className="sr-only">
          Main Content Start
        </div>
        {children}
        <a className="sr-only" href="#site-content">
          Back to top
        </a>
      </main>
    </div>
  );
}

export default SiteContainer;
