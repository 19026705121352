import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CellProps } from 'react-table';
import { Course, PageQuery } from '../../types/types';
import { adminGetCourses } from '../../utils/requests';
import Table, { CustomColumn } from '../core/display/Table/Table';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';

function AdminCoursesMenu(): JSX.Element {
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [active, setActive] = useState(true);
  const [query, setQuery] = useState<PageQuery<Course> | null>(null);

  const parseNum = (val: string, cb: (num: number) => void) => cb(Math.min(Math.max(parseInt(val), 1), 999));

  useEffect(() => adminGetCourses(limit, page - 1, active, setQuery), [limit, page, active]);

  return (
    <div id="admin-courses" className="page">
      <h1>Courses</h1>
      <div className="ctrls-row">
        <div className="input-wrapper">
          <label htmlFor="limit">Results Per Page:</label>
          <input
            id="limit"
            name="limit"
            type="number"
            placeholder="# / Page"
            min={1}
            value={limit}
            onChange={(e) => parseNum(e.target.value, setLimit)}
          />
        </div>
        <div className="input-wrapper">
          <label htmlFor="page">Page Number:</label>
          <input
            id="page"
            name="page"
            type="number"
            placeholder="Page #"
            min={1}
            value={page}
            onChange={(e) => parseNum(e.target.value, setPage)}
          />
        </div>
        <div className="input-wrapper">
          <label htmlFor="active">Active Only:</label>
          <input
            id="active"
            name="active"
            type="checkbox"
            checked={active}
            onChange={(e) => setActive(e.target.checked)}
          />
        </div>
      </div>

      {query ? (
        <>
          <p className="results-num">
            Query contains <b>{query.totalResults} total results</b>
          </p>
          <CourseTable courses={query.results} defaultPageSize={limit} />
        </>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
}

interface CourseTableProps {
  courses: Course[];
  defaultPageSize: number;
}

function CourseTable({ courses, defaultPageSize }: CourseTableProps): JSX.Element {
  const navigate = useNavigate();

  const tableColumns = useMemo<CustomColumn<Course>[]>(
    () => [
      { Header: 'Name', accessor: 'courseName', className: 'left-align' },
      { Header: 'Created By', accessor: 'createdBy' },
      { Header: 'Semester', accessor: 'semester' },
      { Header: 'Year', accessor: 'year' },
      { Header: 'Discipline', accessor: 'discipline', className: 'left-align' },
      { Header: 'University', accessor: 'university', className: 'left-align' },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        Cell: ({ value }: CellProps<Course, string | undefined>) => <span>{moment(value).format('MMM D, YYYY')}</span>,
        className: 'left-align',
      },
      { Header: 'Status', accessor: 'status' },
      {
        Header: 'Student Purchase',
        accessor: 'studentPurchaseEnabled',
        Cell: function render({ cell: { value } }: CellProps<Course>) {
          return <>{value ? 'Enabled' : 'Disabled'}</>;
        },
      },
    ],
    [],
  );

  return (
    <Table
      columns={tableColumns}
      data={courses}
      sortBy="courseName"
      id="admin-course-table"
      defaultPageSize={defaultPageSize}
      hidePagination
      noWrapper
      informOfRow={(row) => navigate(`/course/${row.original.courseId}/assignments`)}
    />
  );
}

export default AdminCoursesMenu;
