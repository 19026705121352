import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import { RootState } from '../../store';
import { AverageRating, Tag } from '../../types/types';
import { KEY_SELECTED_TAG } from '../../utils/constants';
import { formDataToObjectParsed, getPropsFromQuery, storageAvailable } from '../../utils/functions';
import { getTag, getTagRatingAverages, updateTag } from '../../utils/requests';
import Button from '../core/button/Button/Button';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import TagDisplay from './TagDisplay';
import Accordion from '../core/layout/Accordion/Accordion';

function TagPage(): JSX.Element {
  const { tagId } = useParams() as { tagId: string };

  const query: { copy?: boolean } = getPropsFromQuery(new URLSearchParams(useLocation().search));
  const [tag, setTag] = useState<Tag | null>(null);
  const [ratingAverages, setRatingAverages] = useState<AverageRating[] | null>(null);

  const user = useSelector((state: RootState) => state.user);

  const navigate = useNavigate();
  const { modalDispatch } = useModalContext();

  useEffect(() => {
    let storedTag = null;
    if (storageAvailable('sessionStorage')) {
      storedTag = JSON.parse(window.sessionStorage.getItem(KEY_SELECTED_TAG) ?? 'null') as Tag | null;
      window.sessionStorage.setItem(KEY_SELECTED_TAG, 'null');
    }
    if (storedTag != null && storedTag.tagId === tagId) setTag(storedTag);
    else getTag(tagId, setTag);
    getTagRatingAverages(tagId, setRatingAverages);
  }, [tagId]);

  const onEdit = useCallback(
    (tag: Tag) =>
      modalDispatch(
        openModal({
          heading: 'Edit Tag',
          closeButton: true,
          cancelHide: true,
          buttonText: 'Save',
          form: true,
          onSubmit: (formData) => {
            const data = formDataToObjectParsed(formData) as {
              content: string;
            };
            updateTag(tag.tagId, { ...tag, content: data.content }, (updatedTag) => setTag(updatedTag));
          },
          children: (
            <>
              <label htmlFor="update-tag" className="sr-only">
                Tag Name
              </label>
              <input
                id="create-tag"
                type="text"
                name="content"
                placeholder="Tag Name"
                defaultValue={tag.content}
                required
              />
            </>
          ),
        }),
      ),
    [modalDispatch],
  );

  const returnButtonProps = query.copy ? { onClick: () => navigate(-1) } : { href: '/rubrics/tags', route: true };

  if (tag)
    return (
      <>
        <section className="prompt-page-main">
          <div className="main-wrapper">
            <Button variant="alt rad xs low" {...returnButtonProps}>
              Return to Prompt List
            </Button>
            <TagDisplay
              tag={tag}
              onEdit={user.admin || user.schoolAdmin || user.userId === tag.user.userId ? onEdit : undefined}
            />
          </div>
        </section>
        <section>
          <Accordion name="Tagged Rating Mastery">
            {ratingAverages ? (
              <table className="bar-chart">
                <thead>
                  <tr>
                    <th className="name">Rating</th>
                    <th className="score">Average Mastery</th>
                    <th className="track">Track</th>
                  </tr>
                </thead>
                <tbody>
                  {ratingAverages.map((avg, i) => {
                    let barColor = '#2e74a3';
                    if (avg.averageRating >= 80) barColor = '#298547';
                    else if (avg.averageRating < 60) barColor = '#A32E2E';
                    return (
                      <tr key={avg.averageRatingId ?? `avg-${i}`}>
                        <td className="name">{avg?.rating?.name}</td>
                        <td
                          className="score"
                          style={{
                            backgroundImage: `linear-gradient(to right, ${barColor} ${avg.averageRating}%, rgba(255, 255, 255, 0) ${avg.averageRating}%)`,
                          }}
                        >
                          {Math.round(avg.averageRating)}%
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <p>Loading . . .</p>
            )}
          </Accordion>
        </section>
      </>
    );
  return <LoadingSpinner />;
}

export default TagPage;
