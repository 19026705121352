import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Assignment, Course, UserRole } from '../../../../types/types';
import CommentTutorial from '../../../tutorial/CommentTutorial';
import RatingTutorial from '../../../tutorial/RatingTutorial';
import StudentAssignmentTutorial from '../../../tutorial/StudentAssignmentTutorial';

interface Props {
  course?: Course;
  assignment?: Assignment;
  role: UserRole;
  path: string;
}

function HelpMenu({ course, assignment, role, path }: Props): JSX.Element {
  const asyncEnabled = course?.asyncEnabled;

  switch (role) {
    case 'STUDENT':
      return (
        <ul>
          <Routes>
            {path === '/course/:courseId/*' ? (
              <>
                {['/assignments', '/assignment/:assignmentId/dashboard'].map((path) => (
                  <Route
                    key={path}
                    path={path}
                    element={
                      <>
                        <ModalBtn>
                          <StudentAssignmentTutorial buttonMode buttonText="Peerceptiv Assignments" />
                        </ModalBtn>
                        <Link href="https://peerceptiv.zendesk.com/hc/en-us/articles/7562946617491-Peerceptiv-Student-Videos">
                          Peerceptiv Intro Videos
                        </Link>
                      </>
                    }
                  />
                ))}
                <Route
                  path="/assignment/:assignmentId/submission/*"
                  element={
                    <Link href="https://peerceptiv.zendesk.com/hc/en-us/articles/4413491526547-Upload-Your-Submission">
                      Upload Your Submission
                    </Link>
                  }
                />
                <Route
                  path="/assignment/:assignmentId/review/:reviewId"
                  element={
                    <>
                      <ModalBtn>
                        <CommentTutorial buttonMode buttonText="Tips for Helpful Commenting" pinningEnabled />
                      </ModalBtn>
                      <ModalBtn>
                        <RatingTutorial buttonMode buttonText="Tips for Accurate Rating" />
                      </ModalBtn>
                      <Link href="https://peerceptiv.zendesk.com/hc/en-us/articles/4412714539795-How-to-Review-Peer-Submissions-A-Step-by-Step-Guide">
                        How to Review Peer Submissions
                      </Link>
                    </>
                  }
                />
                <Route
                  path="/assignment/:assignmentId/review/:reviewId/feedback/*"
                  element={
                    <Link href="https://peerceptiv.zendesk.com/hc/en-us/articles/4412715195923-Provide-Feedback-on-Reviews">
                      How to Provide Feedback
                    </Link>
                  }
                />
                <Route
                  path="/assignment/:assignmentId/evaluate/*"
                  element={
                    <Link href="https://peerceptiv.zendesk.com/hc/en-us/articles/5448172236563-Complete-a-Peer-Evaluation#h_01G379S2DRNSSP1HY7HQP7ANCQ">
                      How to Complete a Team Member Evaluation
                    </Link>
                  }
                />
                <Route
                  path="/assignment/:assignmentId/results"
                  element={
                    <Link
                      href={
                        asyncEnabled
                          ? 'https://peerceptiv.zendesk.com/hc/en-us/articles/4413756735763-Understanding-Peerceptiv-Results-Async-Mode-Assignments'
                          : 'https://peerceptiv.zendesk.com/hc/en-us/articles/4412819850259-Understanding-Peerceptiv-Results-Sync-Mode-Assignments'
                      }
                    >
                      Understanding Peerceptiv Results
                    </Link>
                  }
                />
                <Route
                  path="/assignment/:assignmentId/groups/*"
                  element={
                    <Link href="https://peerceptiv.zendesk.com/hc/en-us/articles/4483829701523-How-to-Participate-in-a-Group-Assignment">
                      How to Participate in a Group Assignment
                    </Link>
                  }
                />
              </>
            ) : null}

            <Route
              path="*"
              element={
                <li>
                  <p>No page-specific resources available</p>
                </li>
              }
            />
          </Routes>

          <Link href="https://peerceptiv.zendesk.com/hc/en-us/categories/6265536320659-Students">
            Peerceptiv Knowledge Base
          </Link>
        </ul>
      );

    case 'ADMIN':
    case 'SCHOOL ADMIN':
    case 'TEACHER':
    case 'LEAD INSTRUCTOR':
    case 'TEACHING ASSISTANT':
    case 'GRADER':
    case 'EXTERNAL REVIEWER':
      return (
        <ul>
          <Routes>
            {path === '/course/:courseId/*' ? (
              <>
                <Route
                  index
                  element={
                    <Link href="https://peerceptiv.zendesk.com/hc/en-us/articles/4412904812435-Create-a-New-Course">
                      Create a New Course
                    </Link>
                  }
                />
                <Route
                  path="/assignment/setup/menu"
                  element={
                    <Link href="https://peerceptiv.zendesk.com/hc/en-us/sections/6296010703507-Create-Assignments">
                      Create Assignments
                    </Link>
                  }
                />
                {['/assignment/setup/*', '/assignment/new/*'].map((path) => (
                  <Route
                    key={path}
                    path={path}
                    element={
                      <Link href="https://peerceptiv.zendesk.com/hc/en-us/articles/4412929096467-Build-Your-Own-Assignment-Individual-Student-Submission">
                        Build Your Own Assignment
                      </Link>
                    }
                  />
                ))}
                {assignment?.peerEvaluationEnabled ? (
                  <Route
                    path="/assignment/:assignmentId/dashboard/*"
                    element={
                      <Link href="https://peerceptiv.zendesk.com/hc/en-us/articles/6463889696659-Peer-Evaluation-Assignments">
                        Team Member Evaluation Assignments
                      </Link>
                    }
                  />
                ) : null}
                <Route
                  path="/assignment/:assignmentId/wizard/edit/*"
                  element={
                    <Link href="https://peerceptiv.zendesk.com/hc/en-us/articles/4413905737363-Edit-Settings-and-Deadlines">
                      Edit Settings and Deadlines
                    </Link>
                  }
                />
                <Route
                  path="/assignment/:assignmentId/rubric/*"
                  element={
                    <Link href="https://peerceptiv.zendesk.com/hc/en-us/articles/4413759132435-Build-a-Rubric">
                      Build a Rubric
                    </Link>
                  }
                />
                <Route
                  path="/assignment/:assignmentId/progress/*"
                  element={
                    <Link href="https://peerceptiv.zendesk.com/hc/en-us/articles/4802078043027-Use-the-Student-Progress-Table-View-Student-Details-Override-Grades-and-Enter-Teacher-Reviews">
                      Use the Student Progress Table
                    </Link>
                  }
                />
                <Route
                  path="/assignment/:assignmentId/results"
                  element={
                    <Link
                      href={
                        asyncEnabled
                          ? 'https://peerceptiv.zendesk.com/hc/en-us/articles/4413913297171-Assignment-Results-Asynchronous-'
                          : 'https://peerceptiv.zendesk.com/hc/en-us/articles/4414264956179-Assignment-Results-Synchronous-'
                      }
                    >
                      How to Understand Results
                    </Link>
                  }
                />
                <Route
                  path="/assignment/:assignmentId/results/benchmark/*"
                  element={
                    <Link href="https://peerceptiv.zendesk.com/hc/en-us/articles/6881779966355-Benchmark-Submissions">
                      Benchmark Submissions
                    </Link>
                  }
                />
                <Route
                  path="/assignment/:assignmentId/student/:userId/*"
                  element={
                    <Link href="https://peerceptiv.zendesk.com/hc/en-us/articles/4802078043027-Use-the-Student-Progress-Table-View-Student-Details-Override-Grades-and-Enter-Teacher-Reviews">
                      Use the Student Progress Table
                    </Link>
                  }
                />
                <Route
                  path="/assignment/:assignmentId/groups/*"
                  element={
                    <Link href="https://peerceptiv.zendesk.com/hc/en-us/articles/6854114171539-Group-Peer-Assessment-with-Peer-Evaluation-#h_01G54Z9SZ4KQ8TYF750467JVX5">
                      How to Create Groups
                    </Link>
                  }
                />
              </>
            ) : null}

            <Route
              path="/rubrics/library/*"
              element={
                <>
                  <Link href="https://peerceptiv.zendesk.com/hc/en-us/articles/8187613512979-Manage-Your-Rubric-Library">
                    Manage Your Rubric Library
                  </Link>
                  <Link href="https://peerceptiv.zendesk.com/hc/en-us/articles/4413759132435-Build-a-Rubric">
                    Build a Rubric
                  </Link>
                </>
              }
            />

            <Route
              path="*"
              element={
                <li>
                  <p>No page-specific resources available</p>
                </li>
              }
            />
          </Routes>

          <Link href="https://peerceptiv.zendesk.com/hc/en-us/categories/4412639312659-Instructors">
            Peerceptiv Knowledge Base
          </Link>
        </ul>
      );
  }
}

function Link({ children, href }: { children: React.ReactNode; href: string }): JSX.Element {
  return (
    <li className="link">
      <a href={href} target="_blank" rel="noreferrer">
        {children}
      </a>
    </li>
  );
}

function ModalBtn({ children }: { children: React.ReactNode }): JSX.Element {
  return <li className="modal-btn">{children}</li>;
}

export default HelpMenu;
