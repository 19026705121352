import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { getSubmission, getSubmissionInfo } from '../../../../utils/requests';
import { isPDF as pdfCheck, copyTextToClipboard } from '../../../../utils/functions';
import PDF from '../PDF';
import RichReader from '../RichReader';
import Icon from '../Icon';
import VideoJS from '../VideoJS';
import YouTubePlayer, { validateYouTubeUrl } from '../YouTubePlayer';
import { SubmissionInfo } from '../../../../types/types';
import LoadingSpinner from '../../layout/LoadingSpinner/LoadingSpinner';

interface Props {
  flexDirection?: 'column-reverse';
  linkMenu?: (url: string) => JSX.Element;
  miscFileMenu?: (submissionSrc: string, fileName: string) => JSX.Element;
  onPdf?: () => void;
  pdfDisplay?: boolean;
  pdfMenu?: (submissionSrc: string, fileName: string, pdfSupported: boolean) => JSX.Element;
  submissionId: string;
  textMenu?: (textSubmission: string) => JSX.Element;
  presentationMenu?: () => JSX.Element;
}

function Submission({
  flexDirection,
  linkMenu = () => <></>,
  miscFileMenu = () => <></>,
  onPdf = () => undefined,
  pdfDisplay = true,
  pdfMenu = () => <></>,
  submissionId,
  textMenu = () => <></>,
  presentationMenu = () => <></>,
}: Props): JSX.Element {
  // State
  const [submissionInfo, setSubmissionInfo] = useState<SubmissionInfo | null>(null);
  const [submissionSrc, setSubmissionSrc] = useState<string | null>(null);
  const [pdfSupported, setPdfSupported] = useState(false);
  const [blob, setBlob] = useState<Blob | null>(null);
  const [updateKey, setUpdateKey] = useState(0);

  const isPDF = useMemo(() => submissionInfo?.fileName && pdfCheck(submissionInfo.fileName), [submissionInfo]);

  const loadSubmission = useCallback(
    (id: string) =>
      getSubmission(id, (blob) => {
        setBlob(blob);
        setSubmissionSrc(URL.createObjectURL(blob));
      }),
    [],
  );

  /**
   * Load the submission info once a valid submission ID property is received
   */
  useEffect(() => {
    if (submissionId !== '') {
      getSubmissionInfo(submissionId, setSubmissionInfo);
    }
  }, [updateKey, submissionId]);

  /**
   * Load the submission source if a valid file name is loaded into state
   */
  useEffect(() => {
    if (
      submissionInfo?.fileName &&
      submissionId !== '' &&
      (submissionInfo.emcJobStatus === null || submissionInfo.emcJobStatus === 'COMPLETE')
    ) {
      loadSubmission(submissionId);
    }
  }, [submissionInfo, submissionId, loadSubmission]);

  /**
   * Determine if submission is a PDF. If so, embed only if
   * screen size is big enough
   */
  useEffect(() => {
    if (isPDF && pdfSupported) {
      onPdf();
    }
  }, [isPDF, pdfSupported, onPdf]);

  useEffect(() => {
    if (submissionInfo?.emcJobStatus === 'PROGRESSING') {
      const timer = setTimeout(() => setUpdateKey((prevKey) => prevKey + 1), 3000);
      return () => clearTimeout(timer);
    }
  }, [submissionInfo]);

  // Get class for flex direction
  let directionClass;
  switch (flexDirection) {
    case 'column-reverse':
      directionClass = ' col-reverse';
      break;
    default:
      directionClass = '';
  }

  if (submissionInfo) {
    const { fileName, url, textSubmission, presentation } = submissionInfo;
    return (
      <div className="submission-wrapper">
        {fileName ? (
          isPDF && submissionSrc ? (
            // PDF submission:
            <div className={`document-interface${directionClass}`}>
              {pdfMenu(submissionSrc, fileName, pdfSupported)}
              <PDF
                id={'submission-pdf'}
                src={submissionSrc}
                fileName={fileName}
                display={pdfDisplay}
                setPdfSupported={setPdfSupported}
              />
            </div>
          ) : submissionInfo.videoStream ? (
            // Video submission:
            <div className={`document-interface${directionClass}`}>
              {submissionSrc ? miscFileMenu(submissionSrc, fileName) : null}
              <div className="video-file-submission-viewer">
                <h2>Submission</h2>
                {submissionInfo.emcJobStatus === 'PROGRESSING' ? (
                  <div className="processing-video">
                    <div className="title">
                      <LoadingSpinner size={64} position="static" />
                      <b>Video Processing...</b>
                    </div>
                    <p>This may take some time. You may have to check back later if your video was long.</p>
                    <p>You must verify your video has processed correctly before confirming your submission.</p>
                  </div>
                ) : submissionInfo.emcJobStatus === 'ERROR' ? (
                  <div className="processing-video error">
                    <div className="title">
                      <Icon code="error" ariaHidden />
                      <b>Processing Error</b>
                    </div>
                    <p>
                      We could not process your video. Please press <b>&apos;Cancel&apos;</b> and resubmit.
                    </p>
                    <p>Make sure that your video file is not corrupted before submitting.</p>
                  </div>
                ) : null}
                {blob && submissionSrc ? (
                  <VideoJS
                    options={{
                      controls: true,
                      responsive: true,
                      sources: [{ src: submissionSrc, type: blob.type }],
                    }}
                  />
                ) : null}
              </div>
            </div>
          ) : (
            // Non-PDF file submission:
            <div className={`document-interface${directionClass}`}>
              {submissionSrc ? miscFileMenu(submissionSrc, fileName) : null}
              <div className="misc-file-submission-viewer">
                <h2>Submission</h2>
                <Icon code="description" ariaHidden />
                <p>{fileName}</p>
              </div>
            </div>
          )
        ) : url ? (
          // Link submission:
          <div className={`document-interface${directionClass}`}>
            {linkMenu(url)}
            <div className="link-view-card panel-sm flex-col">
              <p>Submission Link:</p>
              {validateYouTubeUrl(url) ? (
                <YouTubePlayer url={url} />
              ) : (
                <div className="flex-row">
                  <a className="link-view" href={url} target="_blank" rel="noreferrer">
                    {url}
                  </a>
                  <button
                    className="button-mini"
                    onClick={() => {
                      copyTextToClipboard(url);
                    }}
                  >
                    <Icon code="content_copy" label="Copy to clipboard" />
                  </button>
                </div>
              )}
            </div>
          </div>
        ) : textSubmission ? (
          // Text submission:
          <div className={`document-interface${directionClass}`}>
            {textMenu(textSubmission)}
            <div className="panel-sm flex-col text-submission-wrapper">
              <h2>Submission</h2>
              <RichReader content={textSubmission} />
            </div>
          </div>
        ) : presentation ? (
          // Presentation submission:
          <div className={`document-interface${directionClass}`}>{presentationMenu()}</div>
        ) : null}
      </div>
    );
  }
  return <LoadingSpinner />;
}

export default Submission;
