import React, { useState, useEffect } from 'react';
import { AssignmentProgress, Report, AccessPermission } from '../../types/types';
import { getAccessPermission, getAssignmentProgress, getAssignmentReports } from '../../utils/requests';
import ProgressTable from './ProgressTable';
import { useLocation, useParams } from 'react-router';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import { setPageTitle } from '../../utils/functions';
import ReportsTable from './ReportsTable';
import { useSelector } from 'react-redux';
import { selectAssignment } from '../../store/selectors';
import { RootState } from '../../store';

function AssignmentProgressPage(): JSX.Element {
  useEffect(() => setPageTitle('Assignment Progress'), []);
  const { assignmentId } = useParams() as { assignmentId: string };

  const location = useLocation();

  const assignment = useSelector(selectAssignment);
  const [progress, setProgress] = useState<AssignmentProgress[] | null>(null);
  const [reports, setReports] = useState<Report[] | null>(null);
  const [accessPermission, setAccessPermission] = useState<AccessPermission>();
  const course = useSelector((state: RootState) => state.course);
  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    getAssignmentProgress(assignmentId, setProgress);
    getAssignmentReports(assignmentId, setReports);
  }, [assignmentId]);

  useEffect(() => {
    setTimeout(() => {
      const hashElem = document.getElementById(location.hash.slice(1));
      if (hashElem) hashElem.scrollIntoView();
    }, 250);
  }, [location, reports]);

  useEffect(() => {
    if (user.userId && course?.courseId) {
      getAccessPermission(course?.courseId, user.userId, setAccessPermission);
    }
  }, [course?.courseId, user.userId]);

  if (assignment)
    return (
      <div className="page teacher-results-row">
        <div className="roster-container">
          {progress ? (
            <section>
              <ProgressTable assignment={assignment} progressData={progress} accessPermission={accessPermission} />
            </section>
          ) : (
            ''
          )}
          {reports &&
          reports.length > 0 &&
          accessPermission?.viewStudentGradePermission &&
          accessPermission.viewAndManageReportPermission ? (
            <section>
              <ReportsTable reports={reports} accessPermission={accessPermission} />
            </section>
          ) : null}
        </div>
      </div>
    );
  return <LoadingSpinner />;
}

export default AssignmentProgressPage;
