import React, { useMemo } from 'react';
import Button from '../../core/button/Button/Button';
import Icon from '../../core/display/Icon';
import SubmitterUsersList from './SubmitterUsersList';
import { ReviewManagementMenuProps } from './ReviewManagement';

function DeanonymizeMenu({ progress, changeToMenu }: ReviewManagementMenuProps): JSX.Element {
  const submissionExists = useMemo(() => progress?.some((item) => item.submissionId != null), [progress]);

  if (progress) {
    return (
      <div className="page" id="review-management">
        <div id="deanonymize-list">
          <Button className="close-btn button-mini" classOverride onClick={() => changeToMenu('MAIN')}>
            <Icon code="close" />
          </Button>
          <h1>Submitters</h1>
          {submissionExists ? <SubmitterUsersList progress={progress} /> : <div>There is no submission yet.</div>}
        </div>
      </div>
    );
  }

  return (
    <div className="page" id="review-management">
      <div id="existing-groups">
        <Button className="close-btn button-mini" classOverride onClick={() => changeToMenu('MAIN')}>
          <Icon code="close" />
        </Button>
        <b>There are no submissions yet.</b>
      </div>
    </div>
  );
}

export default DeanonymizeMenu;
