import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import { Group, UserWithGroupInfo } from '../../types/types';
import Button from '../core/button/Button/Button';
import Avatar from '../core/display/Avatar/Avatar';
import Icon from '../core/display/Icon';
import SearchBar from '../core/input/SearchBar/SearchBar';
import { selectAssignment, selectUser } from '../../store/selectors';

interface Props {
  canSelfManageGroup: boolean;
  groupsRoster: UserWithGroupInfo[];
  myGroup: Group | null;
  myPendingGroups: Group[];
  requestAddUserToGroup: (userId: string, groupId: string, callback?: () => void) => void;
}

function StudentGroupsStudentList({
  canSelfManageGroup,
  groupsRoster,
  myGroup,
  myPendingGroups,
  requestAddUserToGroup,
}: Props): JSX.Element {
  const [searchFilter, setSearchFilter] = useState(''); // Debounced
  const [searchValue, setSearchValue] = useState(''); // Raw Data
  const [showSoloOnly, setShowSoloOnly] = useState(false);
  const [sendLoading, setSendLoading] = useState<string[]>([]);

  const user = useSelector(selectUser);
  const assignment = useSelector(selectAssignment);

  const { modalDispatch } = useModalContext();

  const debouncedSetSearchFilter = useMemo(
    () =>
      _.debounce(setSearchFilter, 500, {
        maxWait: 1000,
      }),
    [],
  );

  useEffect(() => {
    debouncedSetSearchFilter(searchValue);
  }, [searchValue, debouncedSetSearchFilter]);

  const isPendingInvite = (userId: string): boolean => {
    let userIsPending = false;
    if (myGroup)
      myGroup.pendingMembers.some((pendingMember) => {
        if (pendingMember.user.userId === userId) {
          userIsPending = true;
          return true;
        }
      });
    return userIsPending;
  };

  const isPendingJoin = (groupId: string): boolean => {
    let groupIsPending = false;
    myPendingGroups.some((group) => {
      if (group.groupId === groupId) {
        groupIsPending = true;
        return true;
      }
    });
    return groupIsPending;
  };

  const addSendLoadingState = (userId: string) => {
    setSendLoading((prevLoadingList) => [...prevLoadingList, userId]);
  };

  useEffect(() => {
    setSendLoading([]);
  }, [myGroup, myPendingGroups]);

  const getCourseUserButton = (rosterEntry: UserWithGroupInfo): JSX.Element => {
    if (sendLoading.includes(rosterEntry.user.userId))
      return (
        <Button className="sending-wait-btn" variant="rad" disabled>
          {rosterEntry.group?.openAccess ? 'Joining…' : 'Sending…'}
        </Button>
      );
    else if (rosterEntry.groupId)
      if (rosterEntry.groupId === myGroup?.groupId)
        return (
          <Button variant="rad" disabled>
            In My Group
          </Button>
        );
      else if (isPendingJoin(rosterEntry.groupId))
        return (
          <Button className="invite-sent-btn" variant="rad" disabled>
            <span>Request Sent</span>
            <Icon code="done" ariaHidden />
          </Button>
        );
      else if (canSelfManageGroup && assignment?.enableGroupLeaders === false && !rosterEntry.group?.openAccess)
        return (
          <Button variant="rad" disabled>
            Closed Group
          </Button>
        );
      else if (rosterEntry.group && rosterEntry.group.groupMembers.length >= (assignment?.groupSizeLimit ?? 100))
        return (
          <Button variant="rad" disabled>
            Group Full
          </Button>
        );
      else
        return (
          <Button
            variant="rad"
            disabled={!canSelfManageGroup}
            tooltip={!canSelfManageGroup ? 'Only your instructor(s) may manage groups for this assignment.' : undefined}
            onClick={() => {
              const sendRequest = () => {
                requestAddUserToGroup(user.userId, rosterEntry.groupId);
                addSendLoadingState(rosterEntry.user.userId);
              };

              if (myGroup !== null) {
                modalDispatch(
                  openModal({
                    heading: 'Already in a Group',
                    inputType: 'none',
                    buttonText: rosterEntry.group?.openAccess ? 'Join Group' : 'Send Request',
                    children: (
                      <div id="already-in-group-modal-content">
                        <p>
                          {`You are already in a group. Are you sure you want to join `}
                          <b>{rosterEntry.user.name}</b>
                          {` and their group?`}
                        </p>
                        {rosterEntry.group?.openAccess ? (
                          <p>{`Note: Joining a new group will cause you to leave your current group.`}</p>
                        ) : (
                          <p>{`Note: You will remain in your current group until your request is accepted.`}</p>
                        )}
                      </div>
                    ),
                    onConfirm: sendRequest,
                  }),
                );
              } else {
                sendRequest();
              }
            }}
          >
            {rosterEntry.group?.openAccess ? 'Join Group' : 'Request to Join'}
          </Button>
        );
    else {
      if (isPendingInvite(rosterEntry.user.userId))
        return (
          <Button className="invite-sent-btn" variant="rad" disabled>
            <span>Invitation Sent</span>
            <Icon code="done" />
          </Button>
        );
      else
        return (
          <Button
            variant="rad"
            onClick={() => {
              if (myGroup) {
                requestAddUserToGroup(rosterEntry.user.userId, myGroup.groupId);
                addSendLoadingState(rosterEntry.user.userId);
              }
            }}
            disabled={!canSelfManageGroup || !myGroup}
            tooltip={!canSelfManageGroup ? 'Only your instructor(s) may manage groups for this assignment.' : undefined}
          >
            Invite to Group
          </Button>
        );
    }
  };

  return (
    <div id="student-list">
      <div id="list-ctrls">
        <SearchBar placeholder="Search Students" value={searchValue} setValue={setSearchValue} />

        <span>
          <input
            id="showSoloOnly"
            name="showSoloOnly"
            type="checkbox"
            checked={showSoloOnly}
            onChange={(e) => {
              setShowSoloOnly(e.target.checked);
            }}
          />
          <label htmlFor="showSoloOnly">Show Students Without Groups Only</label>
        </span>
      </div>

      <div id="list-wrapper">
        {groupsRoster
          .filter((rosterEntry) => {
            if (rosterEntry.user.userId === user.userId) return false;
            if (showSoloOnly && rosterEntry.groupId) return false;
            if (
              searchFilter !== '' &&
              rosterEntry.user.name?.toLocaleLowerCase().indexOf(searchFilter.toLocaleLowerCase()) === -1
            )
              return false;
            return true;
          })
          .sort((a, b) => (a.user.name || '').localeCompare(b.user.name || ''))
          .map((rosterEntry) => (
            <div key={`user-${rosterEntry.user.userId}`} className="student-entry">
              <div className="left-wrapper">
                <Avatar user={rosterEntry.user} size={40} anonymous={assignment?.anonymousGroups} />
                <div className="entry-details">
                  <div>{assignment?.anonymousGroups ? 'Anonymous' : rosterEntry.user.name}</div>
                  {rosterEntry.groupId ? (
                    <div className="in-group">{rosterEntry.group?.groupName}</div>
                  ) : (
                    <div className="no-group">No Group</div>
                  )}
                </div>
              </div>

              {getCourseUserButton(rosterEntry)}
            </div>
          ))}
      </div>
    </div>
  );
}

export default StudentGroupsStudentList;
