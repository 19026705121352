import React, { useState, useEffect, useCallback, useRef } from 'react';
import ProgressBar from '../../display/Progress/ProgressBar';
import Button from '../../button/Button/Button';
import { SWIPE_THRESHOLD_FACTOR, MINIMUM_SWIPE_FACTOR } from '../../../../utils/constants';
import { focusFirstElement } from '../../../../utils/functions';
import { isAnimationOff } from '../../../auth/profile/AccessibilityOptions';

interface FormProps {
  children?: React.ReactNode;
  id?: string;
  onLoad?: () => void;
  onPageChange?: () => void;
  onSubmit?: () => void;
  pages: JSX.Element[];
  save?: () => void;
  saveTimestamp?: string;
  onPageIndexChange?: (pageIndex: number) => void;
  submitButtonText?: string;
}

function Form({
  children,
  id,
  onLoad = () => undefined,
  onPageChange = () => undefined,
  onSubmit = () => undefined,
  pages,
  save,
  saveTimestamp,
  onPageIndexChange = () => undefined,
  submitButtonText = 'Submit',
}: FormProps): JSX.Element {
  // State:
  const [pageIndex, setPageIndex] = useState(0);
  const [recentPageIndex, setRecentPageIndex] = useState(-1);
  const [loaded, setLoaded] = useState(false);
  const [touchStart, setTouchStart] = useState({ x: 0, y: 0, timestamp: 0 });
  const [touchMove, setTouchMove] = useState({ x: 0, y: 0, timestamp: 0 });
  const [touchEnd, setTouchEnd] = useState({ x: 0, y: 0, timestamp: 0 });

  const formEl = useRef<HTMLFormElement>(null);
  const footerEl = useRef<HTMLDivElement>(null);

  const resetFocus = useCallback(() => {
    window.scrollTo({ top: 0 });
    // Wait out page transition animation
    setTimeout(() => {
      const content = document.getElementById(`${id}-container`);
      if (content) focusFirstElement(content);
    }, 550);
  }, [id]);

  /**
   * Behavior for changing to the previous form page
   */
  const previousFormPage = useCallback(() => {
    resetFocus();
    setPageIndex((prevPageIndex) => {
      const newPageIndex = prevPageIndex > 0 ? prevPageIndex - 1 : 0;
      if (newPageIndex < prevPageIndex) setRecentPageIndex(prevPageIndex);
      return newPageIndex;
    });
  }, [resetFocus]);

  /**
   * Behavior for changing to the next form page
   */
  const nextFormPage = useCallback(() => {
    resetFocus();
    setPageIndex((prevPageIndex) => {
      const newPageIndex = prevPageIndex < pages.length - 1 ? prevPageIndex + 1 : pages.length - 1;
      if (newPageIndex > prevPageIndex) setRecentPageIndex(prevPageIndex);
      return newPageIndex;
    });
  }, [resetFocus, pages]);

  const setFormSubmitter = useCallback((submitterId: string) => {
    if (formEl.current) formEl.current.submitter = submitterId;
  }, []);

  /**
   * Handler for form submission, triggered by either
   * the "previous", "next", or "submit" buttons
   * (or by touch events mimicking these buttons).
   * @param {object} e Event
   */
  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      // Do not continue if page is transitioning
      if (recentPageIndex > -1) return false;

      if (formEl.current) {
        let submitterId = (e.nativeEvent as SubmitEvent).submitter?.id;
        if (!submitterId) submitterId = formEl ? formEl.current.submitter : -1;
        switch (submitterId) {
          case 'form-btn-previous':
          case 'form-swipe-previous':
            previousFormPage();
            break;
          case 'form-btn-next':
          case 'form-swipe-next':
          case 'auto-submit':
            if (!formEl.current.checkValidity()) return false;
            nextFormPage();
            break;
          case 'form-btn-submit':
            onSubmit();
            break;
          default:
            console.error('Unexpected form submission');
        }
      }
      if (save) save();
      onPageChange();
      return true;
    },
    [recentPageIndex, save, previousFormPage, nextFormPage, onSubmit, onPageChange],
  );

  /**
   * Handler for an invalid form submission
   * @param {object} e Event
   */
  const handleInvalid = useCallback(() => {
    // Reset any page transform
    const pageElem = document.getElementById(`page-${pageIndex}`);
    if (pageElem) pageElem.style.transform = `translate(0px)`;
  }, [pageIndex]);

  /**
   * Function for calculating the progress percentage for the progress bar
   */
  const getProgressPercent = useCallback(() => {
    if (pageIndex > -1 && pages) return Math.floor(((pageIndex + 1) / pages.length) * 100);
    return 0;
  }, [pageIndex, pages]);

  /**
   * Add event listeners for swipe controls
   */
  useEffect(() => {
    const createTouchHandler = (stateCallback: (arg0: { x: number; y: number; timestamp: number }) => void) => {
      return (e: TouchEvent) => {
        const touch = e.changedTouches[0];
        stateCallback({
          x: touch.clientX,
          y: touch.clientY,
          timestamp: e.timeStamp,
        });
      };
    };

    const handleTouchStart = createTouchHandler(setTouchStart);
    const handleTouchMove = createTouchHandler(setTouchMove);
    const handleTouchEnd = createTouchHandler(setTouchEnd);

    const formSnapshot = formEl.current;
    if (formSnapshot) {
      formSnapshot.addEventListener('touchstart', handleTouchStart, false);
      formSnapshot.addEventListener('touchmove', handleTouchMove, false);
      formSnapshot.addEventListener('touchend', handleTouchEnd, false);
      formSnapshot.addEventListener('touchcancel', handleTouchEnd, false);
    }

    return () => {
      if (formSnapshot) {
        formSnapshot.removeEventListener('touchstart', handleTouchStart);
        formSnapshot.removeEventListener('touchmove', handleTouchMove);
        formSnapshot.removeEventListener('touchend', handleTouchEnd);
        formSnapshot.removeEventListener('touchcancel', handleTouchEnd);
      }
    };
  }, [id]);

  /**
   * When the a moving touch occurs, translate the page with the touch
   */
  useEffect(() => {
    // Check that the latest event was a touch move
    if (touchMove.timestamp > touchStart.timestamp) {
      let dx = touchMove.x - touchStart.x;

      // Calculate minimum swipe
      const container = document.getElementById(id + '-container');
      const containerWidth = container ? container.clientWidth : 0;
      const minSwipe = MINIMUM_SWIPE_FACTOR * containerWidth;

      // Only proceed if swipe is sufficiently large
      if (Math.abs(dx) > minSwipe) {
        // Calculate the swipe threshold
        const swipeThreshold = SWIPE_THRESHOLD_FACTOR * containerWidth;

        // Have translation logarithmically scale with swipe threshold
        dx =
          (Math.log(Math.abs(dx * SWIPE_THRESHOLD_FACTOR)) / Math.log(swipeThreshold)) *
          (swipeThreshold * Math.sign(dx));

        const pageElem = document.getElementById(`page-${pageIndex}`);
        // Only translate if within page bounds
        if (!(dx > 0 && pageIndex === 0) && !(dx < 0 && pageIndex === pages.length - 1))
          if (pageElem) {
            pageElem.style.transform = `translate(${dx}px)`;
          }
      }
    }
  }, [touchMove, touchStart, pageIndex, pages, id]);

  /**
   * When the a touch ends, determine whether it's a swipe.
   * If it is, then change page accordingly.
   */
  useEffect(() => {
    // Check that the latest event was a touch end
    if (touchEnd.timestamp > touchStart.timestamp) {
      // Get change in X and Y between start & end touches
      const dx = touchEnd.x - touchStart.x;
      const dy = touchEnd.y - touchStart.y;

      // Determine if swipe distance meets swipe threshold for container
      const container = document.getElementById(id + '-container');
      const containerWidth = container ? container.clientWidth : 0;
      const swipeThreshold = SWIPE_THRESHOLD_FACTOR * containerWidth;

      // Check if swipe was decisively horizontal & sufficiently long
      if (Math.abs(dx) > Math.abs(dy) && Math.abs(dx) > swipeThreshold) {
        // Submit form, choosing submitter depending on swipe direction
        dx > 0 ? setFormSubmitter('form-swipe-previous') : setFormSubmitter('form-swipe-next');
        if (formEl.current) {
          const event = document.createEvent('Event');
          event.initEvent('submit', true, true);
          formEl.current.dispatchEvent(event);
        }
      } else {
        // Reset any page transform
        const pageElem = document.getElementById(`page-${pageIndex}`);
        if (pageElem) pageElem.style.transform = `translate(0px)`;
      }

      // Reset touch states
      setTouchStart({ x: 0, y: 0, timestamp: 0 });
      setTouchMove({ x: 0, y: 0, timestamp: 0 });
      setTouchEnd({ x: 0, y: 0, timestamp: 0 });
    }
  }, [touchEnd, touchStart, pageIndex, id, setFormSubmitter]);

  /**
   * Animate the UI timestamp to fade in each time it's updated
   */
  useEffect(() => {
    if (saveTimestamp) {
      const timestampElem = document.getElementById('form-last-saved-timestamp');
      if (timestampElem) {
        timestampElem.style.opacity = '0';
        requestAnimationFrame(() => {
          timestampElem.classList.remove('animate__animated');
          timestampElem.classList.remove('animate__fadeIn');
          requestAnimationFrame(() => {
            timestampElem.style.opacity = '1';
            timestampElem.classList.add('animate__animated');
            timestampElem.classList.add('animate__fadeIn');
          });
        });
      }
    }
  }, [saveTimestamp]);

  /**
   * Once there are pages in the list state, set the form as loaded
   */
  useEffect(() => {
    if (pages && pages.length > 0) setLoaded(true);
  }, [pages]);

  /**
   * Once form is loaded, call onLoad prop
   */
  useEffect(() => {
    if (loaded && onLoad) {
      onLoad();
    }
  }, [loaded, onLoad]);

  /**
   * Animate form page transitions
   */
  useEffect(() => {
    const clearAnimation = () => {
      const recentPage = document.getElementById(`page-${recentPageIndex}`);
      const newPage = document.getElementById(`page-${pageIndex}`);
      if (recentPage) {
        recentPage.classList.remove('page-exit-left');
        recentPage.classList.remove('page-exit-right');
        recentPage.style.display = `none`;
      }
      if (newPage) {
        newPage.classList.remove('page-enter-left');
        newPage.classList.remove('page-enter-right');
        newPage.style.transform = `translate(0px)`;
      }
      setRecentPageIndex(-1);
    };

    if (recentPageIndex > -1) {
      const recentPage = document.getElementById(`page-${recentPageIndex}`);
      const newPage = document.getElementById('page-' + pageIndex);
      if (recentPage && newPage) {
        newPage.style.display = '';
        if (recentPageIndex < pageIndex) {
          recentPage.classList.add('page-exit-left');
          newPage.classList.add('page-enter-right');
        } else if (recentPageIndex > pageIndex) {
          recentPage.classList.add('page-exit-right');
          newPage.classList.add('page-enter-left');
        }

        if (isAnimationOff()) {
          recentPage.style.height = newPage.clientHeight + 'px';
          clearAnimation();
        } else {
          requestAnimationFrame(() => {
            recentPage.style.height = newPage.clientHeight + 'px';
            setTimeout(clearAnimation, 500);
          });
        }
      }
    }
  }, [pageIndex, recentPageIndex]);

  useEffect(() => {
    onPageIndexChange(pageIndex);
  }, [pageIndex, onPageIndexChange]);

  return (
    <div className="peer-form-container" id={id + '-container'} style={{ opacity: loaded ? 1 : 0 }}>
      <form ref={formEl} className="peer-form" id={id} onSubmit={handleSubmit} onInvalid={handleInvalid}>
        <input id="auto-submit" type="submit" style={{ display: 'none' }} />
        <Button
          className="sr-only"
          classOverride
          type="button"
          onClick={() => focusFirstElement(footerEl.current ?? undefined)}
        >
          Skip to page end
        </Button>

        {children}

        {recentPageIndex > -1 ? (
          <div key={`page-${recentPageIndex}`} className="peer-form-page" id={`page-${recentPageIndex}`}>
            {pages ? pages[recentPageIndex] : ''}
          </div>
        ) : null}

        <div
          key={'page-' + pageIndex}
          className="peer-form-page"
          id={'page-' + pageIndex}
          style={{ display: recentPageIndex > -1 ? 'none' : '' }}
        >
          {pages ? pages[pageIndex] : ''}
        </div>

        <div ref={footerEl} className="peer-form-footer">
          <Button
            id="form-btn-previous"
            variant="alt rad"
            disabled={pageIndex === 0}
            formNoValidate={true}
            onClick={() => setFormSubmitter('form-btn-previous')}
            ariaLabel="Previous Page"
          >
            Back
          </Button>
          {saveTimestamp ? (
            <div
              className="form-last-saved-wrapper"
              style={{
                display: save ? '' : 'none',
                opacity: saveTimestamp ? 1 : 0,
              }}
            >
              <span>Last saved: </span>
              <span id="form-last-saved-timestamp">{saveTimestamp}</span>
            </div>
          ) : null}
          {pages && pageIndex < pages.length - 1 ? (
            <Button
              id="form-btn-next"
              variant="primary rad"
              onClick={() => setFormSubmitter('form-btn-next')}
              ariaLabel="Next Page"
            >
              Next
            </Button>
          ) : (
            <Button id="form-btn-submit" variant="secondary rad" onClick={() => setFormSubmitter('form-btn-submit')}>
              {submitButtonText}
            </Button>
          )}
          <ProgressBar id="form-progress-bar" percent={getProgressPercent()} />
        </div>
      </form>
    </div>
  );
}

function Header({ children }: { children: React.ReactNode }): JSX.Element {
  return <div className="peer-form-header">{children}</div>;
}

function Title({ children }: { children: React.ReactNode }): JSX.Element {
  return <h1>{children}</h1>;
}

function Description({ children, size }: { children: React.ReactNode; size: 'lg' | 'sm' }) {
  const getTagsFromSize = () => {
    switch (size) {
      case 'lg':
        return <h2>{children}</h2>;
      case 'sm':
        return <p>{children}</p>;
      default:
        return <h2>{children}</h2>;
    }
  };

  return <div className="form-description">{getTagsFromSize()}</div>;
}

function Body({ children }: { children: React.ReactNode }): JSX.Element {
  return <div className="peer-form-body">{children}</div>;
}

function Row({ children }: { children: React.ReactNode }): JSX.Element {
  return <div className="peer-form-row">{children}</div>;
}

function Col({ children }: { children: React.ReactNode }): JSX.Element {
  return <div className="peer-form-col">{children}</div>;
}

Form.Header = Header;
Form.Title = Title;
Form.Description = Description;
Form.Body = Body;
Form.Row = Row;
Form.Col = Col;

export default Form;
