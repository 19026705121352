import React, { useRef, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { outsideClick, handleKeySelect } from '../../../../utils/functions';
import Icon from '../../display/Icon';

interface Props {
  align?: 'left' | 'right';
  ariaLabel?: string;
  buttonContent?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  iconCode?: string;
  id?: string;
  minWidth?: string;
  top?: string;
}

function Dropdown({
  align = 'right',
  ariaLabel,
  buttonContent,
  children,
  className,
  disabled,
  iconCode,
  id,
  minWidth,
  top,
}: Props): JSX.Element {
  const contentEl = useRef<HTMLDivElement>(null);

  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const handleMouseDown = (e: MouseEvent) => {
      if (contentEl.current && outsideClick(e, [contentEl.current])) {
        setShowContent(false);
      }
    };
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') setShowContent(false);
    };

    window.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('keydown', handleEscape);

    return () => {
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('keydown', handleEscape);
    };
  }, []);

  const handleSelect = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation();
    setShowContent(true);
    if (contentEl.current && contentEl.current.style.display === 'none') {
      requestAnimationFrame(() => (contentEl.current?.firstElementChild as HTMLElement | null)?.focus());
    }
  };

  return (
    <div id={id} className="peer-dropdown" onClick={handleSelect} onKeyDown={(e) => handleKeySelect(e, handleSelect)}>
      <button
        className={className ? className : 'button-mini'}
        disabled={disabled}
        aria-label={ariaLabel ?? 'Action Menu'}
      >
        {buttonContent}
        <Icon code={iconCode ? iconCode : 'more_vert'} ariaHidden />
      </button>
      <div
        ref={contentEl}
        className="content"
        style={{
          display: showContent ? 'block' : 'none',
          right: align === 'right' ? 0 : 'auto',
          left: align === 'left' ? 0 : 'auto',
          top: top ?? undefined,
          minWidth: minWidth,
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setShowContent(false);
        }}
      >
        {children}
      </div>
    </div>
  );
}

interface LinkProps {
  children: React.ReactNode;
  href: string;
  onClick?: (e?: React.MouseEvent) => void;
  route?: boolean;
}

function Link({ children, href, onClick, route = false }: LinkProps) {
  return route ? (
    <RouterLink to={href} onClick={onClick}>
      {children}
    </RouterLink>
  ) : (
    <a href={href} onClick={onClick}>
      {children}
    </a>
  );
}

Dropdown.Link = Link;

export default Dropdown;
