import React, { useState } from 'react';
import Button from '../core/button/Button/Button';
import { formDataToObject } from '../../utils/functions';
import Papa from 'papaparse';

interface UploadMenuProps {
  circles?: boolean;
  requestImportGroups: (groups: string[][]) => void;
}

function UploadMenu({ circles, requestImportGroups }: UploadMenuProps): JSX.Element {
  const [tab, setTab] = useState<'UPLOAD' | 'TEXT'>('UPLOAD');
  const [file, setFile] = useState<File | null>(null);
  const groupType = circles ? 'Circle' : 'Group';

  return (
    <form
      id="group-upload-menu"
      onSubmit={(e) => {
        e.preventDefault();
        const formData = new FormData(e.target as HTMLFormElement);
        const { csvString } = formDataToObject(formData) as { csvString?: string };

        if (file) {
          Papa.parse(file, {
            complete: (results) => {
              requestImportGroups(results.data as string[][]);
            },
          });
        } else if (csvString) {
          const results = Papa.parse(csvString);
          const resultData = results.data as string[][] | null;
          if (resultData) {
            for (let i = 0; i < resultData.length; i++) {
              for (let j = 0; j < resultData[i].length; j++) {
                resultData[i][j] = resultData[i][j].trim();
              }
            }
            requestImportGroups(results.data as string[][]);
          }
        }
      }}
    >
      <div className="modal-tabs">
        <span
          className={tab === 'UPLOAD' ? 'selected' : ''}
          role="button"
          onClick={() => {
            setTab('UPLOAD');
          }}
        >
          Upload
        </span>
        <span
          className={tab === 'TEXT' ? 'selected' : ''}
          role="button"
          onClick={() => {
            setTab('TEXT');
          }}
        >
          Copy &amp; Paste
        </span>
      </div>

      {tab === 'UPLOAD' ? (
        <div className="modal-tab">
          <p id="explainer">
            Upload a CSV spreadsheet file of student emails, with one row for each {groupType.toLocaleLowerCase()}. The
            CSV file should be formatted like this:
          </p>
          <table id="csv-example">
            <tbody>
              <tr>
                <td>email1.edu</td>
                <td>{groupType} Name 1</td>
              </tr>
              <tr>
                <td>email2.edu</td>
                <td>{groupType} Name 2</td>
              </tr>
            </tbody>
          </table>
          <label className="peer-button button-rad">
            Choose CSV
            <input
              id="group-csv-upload"
              type="file"
              name="csvFile"
              accept="text/csv"
              onChange={(e) => {
                const target = e.target as HTMLInputElement;
                const files = target.files;
                if (files) {
                  const file = files.length > 0 ? files[0] : null;
                  setFile(file);
                }
              }}
              required
            />
          </label>
          <p>{file ? file.name : 'No file chosen'}</p>
        </div>
      ) : null}

      {tab === 'TEXT' ? (
        <div className="modal-tab">
          <p id="explainer">
            Paste in a list of student emails separated by a comma. Each {groupType.toLocaleLowerCase()} should be on
            its own row.
          </p>
          <label htmlFor="group-csv-paste">Paste comma-separated values here:</label>
          <textarea
            id="group-csv-paste"
            name="csvString"
            placeholder={`email1.edu, ${groupType} Name 1\u000D\u000Aemail2.edu, ${groupType} Name 2`}
          />
        </div>
      ) : null}

      <Button type="submit">Upload</Button>
    </form>
  );
}

export default UploadMenu;
