import React from 'react';
import { User } from '../../../../types/types';

interface Props {
  anonymous?: boolean;
  size?: number;
  user: User;
}

function Avatar({ anonymous = false, size = 32, user }: Props): JSX.Element {
  return (
    <div
      className="avatar"
      style={{ width: `${size}px`, height: `${size}px`, fontSize: `${size * 0.5}px` }}
      aria-hidden
    >
      {user.name && !anonymous ? user.name.charAt(0) : '?'}
    </div>
  );
}

export default Avatar;
