import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import Calendar, { CalendarRange } from '../../core/display/Calendar/Calendar';
import { useMemo } from 'react';
import Button from '../../core/button/Button/Button';
import _ from 'lodash';
import { AssignmentWizardPageProps } from '../AssignmentWizard';
import { useSelector } from 'react-redux';
import { selectCourse } from '../../../store/selectors';
import { Course } from '../../../types/types';

export interface DeadlinesProps {
  asyncEndDeadline: string | null;
  submissionDeadline: string | null;
  reviewDeadline: string | null;
  feedbackDeadline: string | null;
  reflectionDeadline: string | null;
  peerEvaluationDeadline: string | null;
  publicationTime: string;
  deadlineTime: string;
}

type Deadline = {
  id:
    | 'publicationTime'
    | 'submissionDeadline'
    | 'reviewDeadline'
    | 'feedbackDeadline'
    | 'peerEvaluationDeadline'
    | 'asyncEndDeadline'
    | 'reflectionDeadline';
  date: string;
  duration: number;
  title: string;
  className: string;
  color: string;
};

interface Props extends AssignmentWizardPageProps<DeadlinesProps> {
  edit?: boolean;
}

function DeadlinesPage({
  edit = false,
  assignmentSettings,
  updateSettings,
  onInvalid = () => undefined,
}: Props): JSX.Element {
  const { timeZone } = useSelector(selectCourse) as Course;

  const [selectingIndex, setSelectingIndex] = useState<number | undefined>(undefined);
  const [deadlineTime, setDeadlineTime] = useState(assignmentSettings.deadlineTime.slice(0, 5));
  const [deadlines, setDeadlines] = useState<Deadline[]>(() => {
  const initDeadlines: Deadline[] = [];

    initDeadlines.push({
      id: 'publicationTime',
      date: assignmentSettings.publicationTime,
      duration: 0,
      title: 'Publication Date',
      className: 'publication-date',
      color: '#B8DCFF',
    });
    if (assignmentSettings.submissionDeadline !== null)
      initDeadlines.push({
        id: 'submissionDeadline',
        date: assignmentSettings.submissionDeadline,
        duration: 0,
        title: 'Submission Phase',
        className: 'submission-phase',
        color: '#7878F1',
      });
    if (assignmentSettings.reviewDeadline !== null)
      initDeadlines.push({
        id: 'reviewDeadline',
        date: assignmentSettings.reviewDeadline,
        duration: 0,
        title: 'Review Phase',
        className: 'review-phase',
        color: '#E676E3',
      });
    if (assignmentSettings.feedbackDeadline !== null)
      initDeadlines.push({
        id: 'feedbackDeadline',
        date: assignmentSettings.feedbackDeadline,
        duration: 0,
        title: 'Feedback Phase',
        className: 'feedback-phase',
        color: '#E4C445',
      });
    if (assignmentSettings.peerEvaluationDeadline !== null)
      initDeadlines.push({
        id: 'peerEvaluationDeadline',
        date: assignmentSettings.peerEvaluationDeadline,
        duration: 0,
        title: 'Evaluation Phase',
        className: 'evaluation-phase',
        color: '#55C92D',
      });
    if (assignmentSettings.reflectionDeadline !== null)
      initDeadlines.push({
        id: 'reflectionDeadline',
        date: assignmentSettings.reflectionDeadline,
        duration: 0,
        title: 'Reflection Phase',
        className: 'reflection-phase',
        color: '#3CB371',
      });
    if (assignmentSettings.asyncEndDeadline)
      initDeadlines.push({
        id: 'asyncEndDeadline',
        date: assignmentSettings.asyncEndDeadline,
        duration: 0,
        title: 'Async Assignment',
        className: 'async-assignment',
        color: '#7878F1',
      });

    // Set durations
    for (let i = 1; i < initDeadlines.length; i++) {
      const lastMoment = moment(initDeadlines[i - 1].date);
      const currMoment = moment(initDeadlines[i].date);
      initDeadlines[i].duration = currMoment.diff(lastMoment, 'days');
    }

    return initDeadlines;
  });

  useEffect(() => {
    const updatedDeadlines: DeadlinesProps = {
      asyncEndDeadline: null,
      submissionDeadline: null,
      reviewDeadline: null,
      feedbackDeadline: null,
      reflectionDeadline: null,
      peerEvaluationDeadline: null,
      publicationTime: '',
      deadlineTime,
    };

    deadlines.forEach((deadline) => {
      updatedDeadlines[deadline.id] = deadline.date;
    });

    updateSettings(updatedDeadlines);
  }, [updateSettings, deadlines, deadlineTime]);

  const validateNewDate = (index: number, date: string): boolean => {
    const currMoment = moment();
    if (currMoment.isAfter(date)) {
      onInvalid('Invalid Date', 'Dates must be in the future');
      return false;
    }

    const selectedMoment = moment(date, 'YYYY-MM-DD');
    if (selectedMoment.diff(currMoment, 'days') < index) {
      onInvalid(
        'Date is Too Soon',
        `The date you've chosen is too soon to give the assignment sufficient time. Please select a date further in the future.`,
      );
      return false;
    }

    return true;
  };

  const handleDurationChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    setDeadlines((prevDeadlines) => {
      const newDeadlines = _.clone(prevDeadlines);

      const diff = parseInt(e.target.value) - prevDeadlines[index].duration;
      newDeadlines[index].duration += diff;
      for (let j = index; j < newDeadlines.length; j++) {
        newDeadlines[j].date = moment(newDeadlines[j].date).add(diff, 'days').format('YYYY-MM-DD');
      }

      return newDeadlines;
    });
  }, []);

  const handleDateSelect = useCallback((index: number, date: string) => {
    setDeadlines((prevDeadlines) => {
      const newDeadlines = _.clone(prevDeadlines);

      const lowerBound = index - 1 >= 0 ? newDeadlines[index - 1].date : '1000-01-01';
      const upperBound = index + 1 < newDeadlines.length ? newDeadlines[index + 1].date : '9999-12-31';
      if (moment(date).isBetween(lowerBound, upperBound)) {
        newDeadlines[index].date = date;
        if (index > 0) {
          newDeadlines[index].duration = moment(date).diff(lowerBound, 'days');
        }
        if (index + 1 < newDeadlines.length) {
          newDeadlines[index + 1].duration = moment(newDeadlines[index + 1].date).diff(date, 'days');
        }
      } else if (moment(date).isSameOrBefore(lowerBound)) {
        let nextDate = date;
        for (let i = index; i >= 0; i--) {
          newDeadlines[i].date = nextDate;
          if (i - 1 >= 0)
            nextDate = moment(newDeadlines[i].date).subtract(newDeadlines[i].duration, 'days').format('YYYY-MM-DD');
        }
      } else if (moment(date).isSameOrAfter(upperBound)) {
        let nextDate = date;
        for (let i = index; i < newDeadlines.length; i++) {
          newDeadlines[i].date = nextDate;
          if (i + 1 < newDeadlines.length)
            nextDate = moment(newDeadlines[i].date)
              .add(newDeadlines[i + 1].duration, 'days')
              .format('YYYY-MM-DD');
        }
      }

      return newDeadlines;
    });
  }, []);

  const ranges: CalendarRange[] = useMemo(
    () =>
      deadlines.length === 1
        ? [{ color: deadlines[0].color, low: deadlines[0].date, high: deadlines[0].date, label: deadlines[0].title }]
        : deadlines.slice(1).map((deadline, i) => ({
            color: deadline.color,
            low: deadlines[i].date,
            high: deadline.date,
            label: deadline.title,
          })),
    [deadlines],
  );

  const publicationMoment = moment(deadlines[0].date, 'YYYY-MM-DD');
  return (
    <>
      <div className="calendar-interface">
        <Calendar
          className={selectingIndex !== undefined ? 'focus-pulse' : undefined}
          focusTrap={selectingIndex !== undefined}
          onSelect={(date) => {
            if (selectingIndex !== undefined) {
              if (edit || validateNewDate(selectingIndex, date)) handleDateSelect(selectingIndex, date);
              setSelectingIndex(undefined);
            }
          }}
          onOutsideClick={() => setSelectingIndex(undefined)}
          ranges={ranges}
        />
        <div className="date-list">
          <p id="timezone-prompt">
            All dates in{' '}
            <b>
              <i>{timeZone}</i>
            </b>{' '}
            time
          </p>
          <div>
            <h3 id="publicationDateLabel" style={{ display: 'inline-block' }}>
              Publication Date:
            </h3>
            <Button
              variant="alt rad low"
              type="button"
              onClick={() => setSelectingIndex(0)}
              ariaLabel={`Publication date ${publicationMoment.format('MMMM DD YYYY')}; Select to change`}
            >
              {publicationMoment.format('MM/DD/YYYY')}
            </Button>
          </div>
          <div>
            <label htmlFor="deadlineTime">Deadline Time:</label>
            <input
              type="time"
              id="deadlineTime"
              name="deadlineTime"
              step={60}
              value={deadlineTime}
              onChange={(e) => {
                setDeadlineTime(e.target.value);
              }}
              required
            />
            <b> {moment().tz(timeZone).format('z')}</b>
          </div>

          {deadlines.slice(1).map((deadline, i) => {
            const realIndex = i + 1;
            const deadlineMoment = moment(deadline.date);
            return (
              <div key={deadline.id} className={`phase-time-settings ${deadline.className}`}>
                <h3>
                  <div className="key-color-indicator" style={{ backgroundColor: deadline.color }} />
                  {deadline.title}
                </h3>
                <div className="phase-inputs-wrapper">
                  <div>
                    <div>
                      Start:
                      <b>{moment(deadlines[realIndex - 1].date).format('MM/DD/YYYY')}</b>
                    </div>
                    <div>
                      End:
                      <Button
                        variant="alt rad low"
                        type="button"
                        onClick={() => setSelectingIndex(realIndex)}
                        ariaLabel={`${deadline.title} end deadline ${deadlineMoment.format(
                          'MMMM DD YYYY',
                        )}; Select to change`}
                      >
                        {deadlineMoment.format('MM/DD/YYYY')}
                      </Button>
                    </div>
                  </div>
                  <div>
                    <label>
                      Duration:
                      <input
                        id={`${deadline.id}-duration`}
                        type="number"
                        value={deadline.duration}
                        onChange={(e) => handleDurationChange(e, realIndex)}
                        min={1}
                      />
                      days
                    </label>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default DeadlinesPage;
