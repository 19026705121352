import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Assignment, Course } from '../../types/types';
import { setPageTitle } from '../../utils/functions';
import { getStandardWorkflows } from '../../utils/requests';
import Button from '../core/button/Button/Button';
import RichReader from '../core/display/RichReader';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import AssignmentDiagram from './AssignmentDiagram';

interface Props {
  course: Course;
}

function AssignmentChooseWorkflow({ course }: Props): JSX.Element {
  useEffect(() => setPageTitle('Choose a Workflow'), []);

  const [workflows, setWorkflows] = useState<Assignment[] | null>(null);
  const [checkedId, setCheckedId] = useState('');
  const [checkedWorkflow, setCheckedWorkflow] = useState<Assignment | null>(null);

  const navigate = useNavigate();

  useEffect(() => getStandardWorkflows(course.asyncEnabled, setWorkflows), [course]);

  useEffect(() => {
    if (workflows) {
      const found = workflows.find((wf) => wf.assignmentId === checkedId);
      if (found) setCheckedWorkflow(found);
    }
  }, [workflows, checkedId]);

  const handleSelect = useCallback(
    (workflow: Assignment) => {
      navigate(`/course/${course.courseId}/assignment/setup/wizard/workflow/${workflow.assignmentId}`);
    },
    [course, navigate],
  );

  if (workflows !== null)
    return (
      <div className="page assignment-row">
        <div className="panel-white workflows-menu">
          <fieldset>
            <legend>
              <h1>Choose a Standard Workflow</h1>
            </legend>
            {workflows.map((workflow) => (
              <div className="workflow-radio-wrapper" key={`workflow-${workflow.assignmentId}`}>
                <input
                  type="radio"
                  id={`workflow-${workflow.assignmentId}`}
                  name="workflow"
                  value={workflow.assignmentId}
                  onChange={(e) => setCheckedId(e.target.value)}
                />
                <label htmlFor={`workflow-${workflow.assignmentId}`}>{workflow.assignmentName}</label>
              </div>
            ))}
          </fieldset>
          {workflows.length <= 0 ? <p>No workflows available. Please try again later.</p> : null}
        </div>
        <div className="panel-white workflows-menu">
          <h1>Workflow Preview</h1>
          {checkedWorkflow ? (
            <div key={checkedWorkflow.assignmentId} id="selected-workflow-desc">
              <RichReader content={checkedWorkflow.assignmentDescription} />
              <AssignmentDiagram assignment={checkedWorkflow} scale={0.75} />
              <Button id="use-workflow-btn" variant="rad green" onClick={() => handleSelect(checkedWorkflow)}>
                Use Workflow
              </Button>
            </div>
          ) : (
            <p>Please select a workflow.</p>
          )}
        </div>
      </div>
    );
  return <LoadingSpinner />;
}

export default AssignmentChooseWorkflow;
