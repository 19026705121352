import React, { useState, useEffect } from 'react';
import { ErrorObj } from '../../../utils/requests';
import RichEditor from '../../core/editor/RichEditor/RichEditor';
import { AssignmentWizardPageProps } from '../AssignmentWizard';

export interface DetailsProps {
  assignmentDescription: string;
  assignmentName: string;
}

function DetailsPage({
  assignmentSettings,
  updateSettings,
  onInvalid = () => undefined,
  updateValidation = () => undefined,
}: AssignmentWizardPageProps<DetailsProps>): JSX.Element {
  const [assignmentName, setAssignmentName] = useState(assignmentSettings.assignmentName);
  const [assignmentDescription, setAssignmentDescription] = useState<string>(assignmentSettings.assignmentDescription);

  const [descriptionIsEmpty, setDescriptionIsEmpty] = useState(true);

  const handleEditorChange = (value: string) => {
    setAssignmentDescription(value);
  };

  useEffect(() => {
    updateSettings({ assignmentDescription, assignmentName });
  }, [updateSettings, , assignmentName, assignmentDescription]);

  useEffect(() => {
    updateValidation(() => {
      if (!descriptionIsEmpty) return true;
      onInvalid('No Assignment Description', 'All assignments must include an assignment description.');
      return false;
    });

    return () => {
      updateValidation(undefined);
    };
  }, [updateValidation, onInvalid, descriptionIsEmpty]);

  return (
    <>
      <label htmlFor="assignmentName">Assignment Name</label>
      <input
        id="assignmentName"
        name="assignmentName"
        type="text"
        value={assignmentName}
        onChange={(e) => {
          setAssignmentName(e.target.value);
        }}
        required={true}
      />
      <label>Assignment Description</label>
      <RichEditor
        initContent={assignmentSettings.assignmentDescription || undefined}
        onChange={handleEditorChange}
        isEmpty={setDescriptionIsEmpty}
      />
    </>
  );
}

export default DetailsPage;
