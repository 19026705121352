import React, { useCallback, useMemo, useState } from 'react';
import { AssignmentProgress, ReviewUsers } from '../../../types/types';
import { useSelector } from 'react-redux';
import { selectAssignment } from '../../../store/selectors';
import Table, { CustomColumn } from '../../core/display/Table/Table';
import { CellProps } from 'react-table';
import Button from '../../core/button/Button/Button';
import { openModal, useModalContext } from '../../../contexts/ModalContext';
import { assignReviews, deleteReviewsOfUsersInAssignment } from '../../../utils/requests';
import { useDispatch } from 'react-redux';
import { newUpdateKey } from '../../../actions';
import AlertBar from '../../core/display/AlertBar';

interface Props {
  reviewUsers: ReviewUsers[];
  progress: AssignmentProgress[];
  updateData: () => void;
}

function ReviewUsersList({ progress, reviewUsers, updateData }: Props): JSX.Element {
  const assignment = useSelector(selectAssignment);
  const { modalDispatch } = useModalContext();

  const showGroups = useMemo(() => assignment?.groupsEnabled || assignment?.reviewingCirclesEnabled, [assignment]);

  const openEditModal = useCallback(
    (submitterReviewUsers: ReviewUsers) =>
      modalDispatch(
        openModal({
          heading: 'Edit Assigned Reviewers for Submitter',
          closeButton: true,
          noActionButtons: true,
          children: (
            <EditMenu
              submitterReviewUsers={submitterReviewUsers}
              reviewUsers={reviewUsers}
              progress={progress}
              updateData={updateData}
            />
          ),
        }),
      ),
    [modalDispatch, reviewUsers, progress, updateData],
  );

  const tableColumns = useMemo<CustomColumn<ReviewUsers>[]>(() => {
    const cols: CustomColumn<ReviewUsers>[] = [];
    cols.push({ Header: 'Submitter', accessor: 'submitterName', className: 'left-align' });
    if (showGroups) cols.push({ Header: 'Group', accessor: 'groupName', className: 'left-align' });
    cols.push({
      Header: 'Assigned Reviewers',
      accessor: 'reviewers',
      Cell: function render({ cell: { row } }: CellProps<ReviewUsers>) {
        const { reviewers } = row.original;
        if (reviewers.length > 0)
          return (
            <ol>
              {reviewers.map((reviewer) => (
                <li key={reviewer.userId}>{reviewer.name}</li>
              ))}
            </ol>
          );
        return <span className="rand">Assigned Randomly</span>;
      },
    });
    cols.push({
      Header: 'Edit',
      accessor: 'submitter',
      Cell: function render({ cell: { row } }: CellProps<ReviewUsers>) {
        return (
          <Button variant="low xs" onClick={() => openEditModal(row.original)}>
            Edit
          </Button>
        );
      },
    });
    return cols;
  }, [openEditModal, showGroups]);

  return (
    <Table
      id="review-users-list-table"
      columns={tableColumns}
      data={reviewUsers}
      sortBy={showGroups ? 'groupName' : 'submitterName'}
      hideDownload
      noWrapper
    />
  );
}

interface EditMenuProps extends Props {
  submitterReviewUsers: ReviewUsers;
}

function EditMenu({ progress, submitterReviewUsers, updateData }: EditMenuProps): JSX.Element {
  const [tab, setTab] = useState<'ASSIGN' | 'DELETE'>('ASSIGN');
  const [emails, setEmails] = useState<string[]>([]);
  const [reviewersToDelete, setReviewersToDelete] = useState<string[]>([]);

  const dispatch = useDispatch();
  const assignment = useSelector(selectAssignment);
  const showGroups = assignment?.groupsEnabled || assignment?.reviewingCirclesEnabled;

  const { submitter, submitterGroup, reviewers } = submitterReviewUsers;
  return (
    <div id="assign-reviews-to-student-menu">
      <div>
        <mark>
          <b>Submitter: {submitter.name}</b>
        </mark>
      </div>

      <div className="modal-tabs">
        <span className={tab === 'ASSIGN' ? 'selected' : ''} role="button" onClick={() => setTab('ASSIGN')}>
          Assign Reviews
        </span>
        <span
          className={tab === 'DELETE' ? 'selected' : ''}
          role="button"
          onClick={() => {
            setTab('DELETE');
          }}
        >
          Delete Reviews
        </span>
      </div>

      {tab === 'ASSIGN' ? (
        <>
          <select
            aria-label="Students"
            value=""
            onChange={(e) => setEmails((prevEmails) => [...prevEmails, e.target.value])}
          >
            <option value="" disabled>
              Select a student to review this submitter
            </option>
            {progress
              .filter(
                (progress) =>
                  progress.user.userId !== submitter.userId &&
                  !reviewers.some((reviewer) => reviewer.userId === progress.user.userId) &&
                  !emails.includes(progress.user.email) &&
                  (submitterGroup === null || progress.group?.groupId != submitterGroup?.groupId),
              )
              .sort((a, b) => a.user.email.localeCompare(b.user.email))
              .map((progress) => (
                <option key={progress.user.userId} value={progress.user.email}>
                  {progress.user.name}
                  {showGroups ? ` [${progress.group?.groupName ?? 'No Group'}]` : null} ({progress.user.email})
                </option>
              ))}
          </select>

          <p>
            <b>Assigning the following student(s) to review {submitter.name}:</b>
          </p>
          <pre>{emails.length > 0 ? emails.join('\n') : 'No students selected yet'}</pre>
          <Button
            onClick={() =>
              assignReviews(assignment?.assignmentId ?? '', [[submitterReviewUsers.submitter.email, ...emails]], () => {
                updateData();
                dispatch(newUpdateKey());
              })
            }
          >
            Assign
          </Button>
        </>
      ) : null}

      {tab === 'DELETE' ? (
        <>
          <table className="nice-table">
            <thead>
              <tr>
                <th>Reviewer</th>
                <th>Mark for Deletion</th>
              </tr>
            </thead>
            <tbody>
              {reviewers.map((reviewer) => (
                <tr key={reviewer.userId}>
                  <td>{reviewer.name}</td>
                  <td>
                    <label className="sr-only" htmlFor="mark-for-deletion">
                      Mark {reviewer.name} for Deletion
                    </label>
                    <input
                      id="mark-for-deletion"
                      type="checkbox"
                      checked={reviewersToDelete.includes(reviewer.userId)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setReviewersToDelete((prevList) => [...prevList, reviewer.userId]);
                        } else {
                          setReviewersToDelete((prevList) => prevList.filter((prevId) => prevId !== reviewer.userId));
                        }
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <AlertBar>Warning: Students will lose progress if they have already completed their review.</AlertBar>
          <Button
            onClick={() =>
              deleteReviewsOfUsersInAssignment(
                assignment?.assignmentId ?? '',
                submitter.userId,
                reviewersToDelete,
                () => {
                  updateData();
                  dispatch(newUpdateKey());
                },
              )
            }
            disabled={reviewersToDelete.length < 1}
          >
            Delete Reviews
          </Button>
        </>
      ) : null}
    </div>
  );
}

export default ReviewUsersList;
