import React, { useState, useMemo, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Form from '../core/input/Form/Form';
import SubmissionSetup from './SetupPages/SubmissionSetup';
import ReviewSetup from './SetupPages/ReviewSetup';
import FeedbackSetup from './SetupPages/FeedbackSetup';
import { Assignment, Course } from '../../types/types';
import DeadlineSetup from './SetupPages/DeadlineSetup';
import TypeSetup from './SetupPages/TypeSetup';
import EvaluationSetup from './SetupPages/EvaluationSetup';
import VisualConfirmSetup from './SetupPages/VisualConfirmSetup';
import { getPhaseStatus, setPageTitle, storageAvailable } from '../../utils/functions';
import { DEFAULT_ASSIGNMENT, WIZARD_INIT_ASSIGNMENT_KEY } from '../../utils/constants';
import LiveModeSetup from './SetupPages/LiveModeSetup';
import ReflectionSetup from './SetupPages/ReflectionSetup';

export interface CoreSettings {
  courseId: string;
  peerEvaluationOnly: boolean;
  groupsEnabled: boolean;
  instructorUpload: boolean;
  peerEvaluationEnabled: boolean;
  instructorGradedOnly: boolean;
  feedbackEnabled: boolean;
  reflectionEnabled: boolean;
  studentSelectedEvaluations: boolean;
  liveMode: boolean;
}

interface Props {
  course: Course;
}

function AssignmentInitialSetup({ course }: Props): JSX.Element {
  useEffect(() => setPageTitle('New Assignment Setup'), []);

  const courseId = (useParams() as { courseId: string }).courseId;

  const [peerEvaluationOnly, setPeerEvaluationOnly] = useState<boolean>(false);
  const [groupsEnabled, setGroupsEnabled] = useState(false);
  const [instructorUpload, setInstructorUpload] = useState(false);
  const [peerEvaluationEnabled, setPeerEvaluationEnabled] = useState<boolean>(false);
  const [instructorGradedOnly, setInstructorGradedOnly] = useState<boolean>(false);
  const [feedbackEnabled, setFeedbackEnabled] = useState<boolean>(true);
  const [reflectionEnabled, setReflectionEnabled] = useState<boolean>(false);
  const [showFeedbackPage, setShowFeedbackPage] = useState(true);
  const [studentSelectedEvaluations, setStudentSelectedEvaluations] = useState<boolean>(false);
  const [liveMode, setLiveMode] = useState<boolean>(false);
  const [deadlineSetting, setDeadlineSetting] = useState<'NONE' | 'END' | 'PHASES'>(
    course.asyncEnabled ? 'NONE' : 'PHASES',
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (peerEvaluationOnly) {
      setGroupsEnabled(true);
      setInstructorUpload(false);
      setPeerEvaluationEnabled(true);
      setInstructorGradedOnly(false);
      setFeedbackEnabled(false);
      setReflectionEnabled(false);
    } else {
      setGroupsEnabled(false);
      setPeerEvaluationEnabled(false);
      setFeedbackEnabled(true);
      setStudentSelectedEvaluations(false);
      setReflectionEnabled(false);
    }
  }, [peerEvaluationOnly]);

  useEffect(() => {
    if (liveMode) {
      setFeedbackEnabled(false);
    } else {
      setFeedbackEnabled(true);
    }
  }, [liveMode]);

  useEffect(() => {
    if (studentSelectedEvaluations) setGroupsEnabled(false);
  }, [studentSelectedEvaluations]);

  useEffect(() => {
    if (peerEvaluationEnabled) setInstructorGradedOnly(false);
  }, [peerEvaluationEnabled]);

  const pages = useMemo(() => {
    const pages = [];
    pages.push(
      <TypeSetup
        key="setup-pg-0"
        peerEvaluationOnly={peerEvaluationOnly}
        setPeerEvaluationOnly={setPeerEvaluationOnly}
      />,
    );
    if (!peerEvaluationOnly) {
      pages.push(<LiveModeSetup liveMode={liveMode} setLiveMode={setLiveMode} />);
      pages.push(
        <SubmissionSetup
          key="setup-pg-1"
          groupsEnabled={groupsEnabled}
          setGroupsEnabled={setGroupsEnabled}
          instructorUpload={instructorUpload}
          setInstructorUpload={setInstructorUpload}
          peerEvaluationEnabled={peerEvaluationEnabled}
          setPeerEvaluationEnabled={setPeerEvaluationEnabled}
        />,
      );
    }
    if (!course.asyncEnabled && !instructorUpload && !peerEvaluationOnly && !peerEvaluationEnabled)
      pages.push(
        <ReviewSetup
          key="setup-pg-2"
          instructorGradedOnly={instructorGradedOnly}
          setInstructorGradedOnly={setInstructorGradedOnly}
        />,
      );
    if (showFeedbackPage && !peerEvaluationOnly && !liveMode)
      pages.push(
        <FeedbackSetup key="setup-pg-3" feedbackEnabled={feedbackEnabled} setFeedbackEnabled={setFeedbackEnabled} />,
      );
    if (!peerEvaluationOnly && !instructorUpload)
      pages.push(
        <ReflectionSetup
          key="setup-pg-6"
          reflectionEnabled={reflectionEnabled}
          setReflectionEnabled={setReflectionEnabled}
        />,
      );
    if (peerEvaluationOnly === true)
      pages.push(
        <EvaluationSetup
          key="setup-pg-7"
          studentSelectedEvaluations={studentSelectedEvaluations}
          setStudentSelectedEvaluations={setStudentSelectedEvaluations}
        />,
      );
    if (course.asyncEnabled)
      pages.push(
        <DeadlineSetup
          key="setup-pg-4"
          deadlineSetting={deadlineSetting}
          setDeadlineSetting={setDeadlineSetting}
          oneDeadline={peerEvaluationOnly === true}
        />,
      );
    pages.push(
      <VisualConfirmSetup
        key="setup-pg-5"
        peerEvaluationOnly={peerEvaluationOnly === true}
        groupsEnabled={groupsEnabled}
        instructorUpload={instructorUpload}
        peerEvaluationEnabled={peerEvaluationEnabled === true}
        instructorGradedOnly={instructorGradedOnly === true}
        feedbackEnabled={feedbackEnabled === true}
        reflectionEnabled={reflectionEnabled}
      />,
    );
    return pages;
  }, [
    peerEvaluationOnly,
    groupsEnabled,
    instructorUpload,
    peerEvaluationEnabled,
    instructorGradedOnly,
    feedbackEnabled,
    showFeedbackPage,
    studentSelectedEvaluations,
    deadlineSetting,
    liveMode,
    reflectionEnabled,
    course,
  ]);

  useEffect(() => {
    if (instructorUpload || instructorGradedOnly === true || liveMode) {
      setShowFeedbackPage(false);
      setFeedbackEnabled(false);
    } else {
      setShowFeedbackPage(true);
      setFeedbackEnabled(true);
    }

    if (instructorUpload && instructorGradedOnly === true) setInstructorGradedOnly(false);
  }, [instructorUpload, instructorGradedOnly, liveMode]);

  return (
    <div className="page assignment-row">
      <Form
        id="initial-assignment-setup"
        pages={pages}
        onSubmit={() => {
          const coreSettings: CoreSettings = {
            courseId,
            peerEvaluationOnly,
            groupsEnabled,
            instructorUpload,
            peerEvaluationEnabled,
            instructorGradedOnly,
            feedbackEnabled,
            reflectionEnabled,
            studentSelectedEvaluations,
            liveMode,
          };
          const phaseStatus = getPhaseStatus(coreSettings);
          const assignmentSettingsToStore: Assignment = {
            ...DEFAULT_ASSIGNMENT,
            ...coreSettings,
            submissionDeadline: deadlineSetting === 'PHASES' && phaseStatus.submission ? '' : null,
            reviewDeadline: deadlineSetting === 'PHASES' && phaseStatus.review ? '' : null,
            feedbackDeadline: deadlineSetting === 'PHASES' && phaseStatus.feedback ? '' : null,
            reflectionDeadline: deadlineSetting === 'PHASES' && phaseStatus.reflection ? '' : null,
            peerEvaluationDeadline: deadlineSetting === 'PHASES' && phaseStatus.evaluate ? '' : null,
            asyncEndDeadline: deadlineSetting === 'END' ? '' : null,
          };
          if (storageAvailable('sessionStorage'))
            window.sessionStorage.setItem(WIZARD_INIT_ASSIGNMENT_KEY, JSON.stringify(assignmentSettingsToStore));
          navigate(`/course/${courseId}/assignment/setup/wizard/new`);
        }}
      />
    </div>
  );
}

export default AssignmentInitialSetup;
