import React, { useRef, useEffect } from 'react';

interface Props {
  children: React.ReactNode;
  color: string;
  r: number;
}

function PieChart({ children, color, r }: Props): JSX.Element {
  const svgEl = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (children && svgEl.current) {
      const circles = Array.from(svgEl.current.children).slice(1);
      const percents = new Array<number>(circles.length);

      if (percents.length > 0) {
        if (children.hasOwnProperty('length'))
          (children as JSX.Element[]).forEach((child, i) => {
            percents[i] = child.props.percent;
          });
        else percents[0] = (children as JSX.Element).props.percent;

        (circles as SVGCircleElement[]).forEach((circle, i) => {
          let percentSum = 0;
          for (let j = 0; j < i; j++) percentSum += percents[j];
          const rotation = (percentSum / 100) * 360;
          circle.setAttribute('transform', `rotate(${rotation - 90}, 10, 10)`);
        });
      }
    }
  }, [children]);

  return (
    <svg
      ref={svgEl}
      className="pie-chart"
      height="20"
      width="20"
      viewBox="0 0 20 20"
      style={{ width: `${2 * r}px`, height: `${2 * r}px` }}
    >
      <circle r="10" cx="10" cy="10" fill={color} strokeWidth="10" />
      {children}
    </svg>
  );
}

interface SliceProps {
  color: string;
  percent: number;
}

function Slice({ color, percent }: SliceProps) {
  const mainStroke = (percent * 31.4) / 100;

  return (
    <circle
      r="5"
      cx="10"
      cy="10"
      fill="transparent"
      stroke={color}
      strokeWidth="10"
      strokeDasharray={percent > 0 ? `${mainStroke} 31.4` : undefined}
      transform="rotate(-90, 10, 10)"
      opacity={percent <= 0 ? 0 : undefined}
    />
  );
}

PieChart.Slice = Slice;

export default PieChart;
