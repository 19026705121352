import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Assignment } from '../../types/types';
import { adminGetAssignments } from '../../utils/requests';
import QueryTable, { Column } from '../core/display/QueryTable/QueryTable';

function AllAssignments(): JSX.Element {
  const navigate = useNavigate();

  const handleAssignmentSelect = useCallback(
    (assignment: Assignment) =>
      navigate(`/course/${assignment.courseId}/assignment/${assignment.assignmentId}/dashboard`),
    [navigate],
  );

  const tableColumns = useMemo<Column<Assignment>[]>(
    () => [
      { Header: 'Assignment Name', accessor: 'assignmentName', className: 'bold' },
      {
        Header: 'Created By',
        accessor: 'user',
        className: 'left-align',
        Cell: (assignment: Assignment) => (assignment.user?.admin ? 'Peerceptiv' : assignment.user?.name),
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        Cell: (assignment: Assignment) => moment(assignment.createdAt).format('MMM D, YYYY'),
        defaultSort: true,
        sortDirection: 'DESC',
      },
    ],
    [],
  );

  return (
    <>
      <h1 className="sr-only">Assignments</h1>
      <QueryTable
        columns={tableColumns}
        queryRequest={adminGetAssignments}
        onRowSelect={handleAssignmentSelect}
        classNames={{
          ctrlsClassName: 'ctrls-row',
          ctrlsSearchClassName: 'col-2',
          ctrlsSelectClassName: 'col-2',
          tableClassName: 'home-body',
        }}
      />
    </>
  );
}

export default AllAssignments;
