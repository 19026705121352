import React, { useCallback, useEffect, useState } from 'react';
import { AccessPermission, Assignment, Course } from '../../../types/types';
import { TaskStatus } from '../student/StudentDashboard';
import AssignmentActionsCard from './AssignmentActionsCard';
import AssignmentActivityCard from './AssignmentActivityCard';
import AssignmentDetailsCard from '../AssignmentDetailsCard';
import AssignmentTimelineCard from './AssignmentTimelineCard';
import {
  deleteAssignment,
  endPhase,
  getAccessPermission,
  runGPTZeroScan,
  unpublishAssignment,
} from '../../../utils/requests';
import Tippy from '@tippyjs/react';
import Dropdown from '../../core/button/Dropdown/Dropdown';
import { openModal, useModalContext } from '../../../contexts/ModalContext';
import Icon from '../../core/display/Icon';
import { useDispatch, useSelector } from 'react-redux';
import { setReduxAssignment } from '../../../actions';
import { getPhaseStatus } from '../../../utils/functions';
import AlertBar from '../../core/display/AlertBar';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingSpinner from '../../core/layout/LoadingSpinner/LoadingSpinner';
import { RootState } from '../../../store';

interface Props {
  assignment: Assignment;
  course: Course;
  timeRemaining: string;
}

function TeacherDashboard({ assignment, course, timeRemaining }: Props): JSX.Element {
  const { assignmentId } = useParams() as { assignmentId: string };
  const [accessPermission, setAccessPermission] = useState<AccessPermission>();
  const user = useSelector((state: RootState) => state.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { modalDispatch } = useModalContext();

  const [loading, setLoading] = useState(false);

  const handleSelectChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const value = e.target.value as 'UNPUBLISHED' | 'PUBLISHED';
      if (assignment.assignmentId)
        switch (value) {
          case 'PUBLISHED':
            endPhase(assignment.assignmentId, (data) => dispatch(setReduxAssignment(data)));
            break;
          case 'UNPUBLISHED':
            unpublishAssignment(assignment.assignmentId, (data) => dispatch(setReduxAssignment(data)));
        }
    },
    [assignment, dispatch],
  );

  useEffect(() => {
    if (user.userId && course?.courseId) {
      getAccessPermission(course?.courseId, user.userId, setAccessPermission);
    }
  }, [course?.courseId, user.userId]);

  const visibilityValue = assignment.progressStats?.published ? 'PUBLISHED' : 'UNPUBLISHED';

  const phaseStatus = getPhaseStatus(assignment);

  const wrapWithTooltip = (children: JSX.Element) => {
    if (assignment.publishableRubric === false)
      return (
        <Tippy content="Assignment must have a rubric before publishing." interactive>
          {children}
        </Tippy>
      );
    if (
      assignment.instructorUpload &&
      assignment.progressStats &&
      assignment.progressStats.submissionCount < assignment.numberOfReviewers
    )
      return (
        <Tippy content="Must submit as an instructor before publishing." interactive>
          {children}
        </Tippy>
      );
    return children;
  };

  return (
    <div id="teacher-dashboard" className="page">
      <div className="dashboard-main">
        {assignment.template ? (
          <AlertBar backgroundColor="#c1daf7" id="template-disclaimer">
            This page represents an Assignment Template. This assignment is not active.
          </AlertBar>
        ) : null}
        <AssignmentDetailsCard
          assignment={assignment}
          options={
            <>
              {assignment.template
                ? null
                : wrapWithTooltip(
                    <span className="publish-toggle-wrapper">
                      <Icon code={visibilityValue === 'PUBLISHED' ? 'visibility' : 'visibility_off'} ariaHidden />
                      <label className="sr-only" htmlFor="visibility-select">
                        Visibility
                      </label>
                      <select
                        id="visibility-select"
                        name="visibility"
                        value={visibilityValue}
                        onChange={handleSelectChange}
                        disabled={
                          assignment.publishableRubric === false ||
                          (assignment.instructorUpload &&
                            assignment.progressStats &&
                            assignment.progressStats.submissionCount < assignment.numberOfReviewers)
                        }
                      >
                        <option value="UNPUBLISHED">Unpublished</option>
                        <option value="PUBLISHED">Published</option>
                      </select>
                    </span>,
                  )}
              <Dropdown id="assignment-options" iconCode="settings" align="right">
                <Dropdown.Link
                  href={`/course/${assignment.courseId}/assignment/${assignment.assignmentId}/wizard/edit`}
                  route
                >
                  Edit
                </Dropdown.Link>
                {phaseStatus.review && !assignment.instructorUpload && !assignment.template ? (
                  <Dropdown.Link
                    href={`/course/${assignment.courseId}/assignment/${assignment.assignmentId}/manage-reviews`}
                    route
                  >
                    Manage Reviews
                  </Dropdown.Link>
                ) : null}
                {assignment.enableGptZeroScan ? (
                  <Dropdown.Link
                    href="#"
                    onClick={() => {
                      setLoading(true);
                      runGPTZeroScan(
                        assignmentId,
                        () => {
                          setLoading(false);
                          modalDispatch(
                            openModal({
                              heading: 'GPTZero Scan Complete',
                              label: `Any flagged submissions have been reported under this assignment's progress page`,
                              buttonText: 'Continue',
                              cancelHide: true,
                            }),
                          );
                        },
                        () => {
                          setLoading(false);
                          return false;
                        },
                      );
                    }}
                  >
                    Run GPTZero Scan
                  </Dropdown.Link>
                ) : null}
                <Dropdown.Link
                  href="#"
                  onClick={() => {
                    modalDispatch(
                      openModal({
                        heading: 'Delete Assignment',
                        label: `Are you sure you want to delete "${assignment.assignmentName}"? This cannot be undone.`,
                        buttonText: 'Delete',
                        onConfirm: () => {
                          if (assignment.assignmentId)
                            deleteAssignment(assignment.assignmentId, () =>
                              navigate(`/course/${assignment.courseId}/assignments`),
                            );
                        },
                      }),
                    );
                  }}
                >
                  Delete
                </Dropdown.Link>
              </Dropdown>
            </>
          }
        />
        {accessPermission?.viewAndManageReportPermission ? <AssignmentActionsCard assignment={assignment} /> : null}
        {assignment.template ? null : <AssignmentActivityCard assignment={assignment} />}
      </div>
      {assignment.template ? null : (
        <div className="dashboard-side">
          <AssignmentTimelineCard assignment={assignment} course={course} timeRemaining={timeRemaining} />
        </div>
      )}
      {loading ? <LoadingSpinner /> : null}
    </div>
  );
}

export const assignmentCurrentTeacherTask = (assignment: Assignment): TaskStatus => {
  const { courseId, assignmentId, status } = assignment;
  const rootPath = `/course/${courseId}/assignment/${assignmentId}`;

  switch (status) {
    case 'UNPUBLISHED':
      if (assignment.template && assignment.publishableRubric) return { text: 'Template Ready' };
      if (assignment.publishableRubric) return { text: 'Publish', href: `${rootPath}/dashboard` };
      else return { text: 'Add Rubric', href: `${rootPath}/rubric` };
    case 'ACTIVE':
      return { text: 'In Progress' };
    case 'NEEDS_GRADING':
      if (assignment.benchmarkGradingEnabled && assignment.benchmarkingComplete === false)
        return { text: 'Benchmark', href: `${rootPath}/results/benchmark` };
      else return { text: 'Release Grades', href: `${rootPath}/results` };
    case 'COMPLETE':
      return { text: 'Complete' };
  }

  return { text: 'Waiting for Available Tasks', href: '' };
};

export default TeacherDashboard;
