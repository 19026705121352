import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ReviewByItem } from '../../../types/types';
import { getReviewItemsOfUser } from '../../../utils/requests';
import Button from '../../core/button/Button/Button';
import JumpButton from '../../core/button/JumpButton';
import DeleteReviewButton from './DeleteReviewButton';
import RatingDetailsTable from './RatingDetailsTable';
import { getCompletionStatus, getJumpButtons, StudentDetailsTabProps } from './StudentDetailsPage';
import ReviewDialogue from '../../core/display/ReviewDialogue/ReviewDialogue';

function ReviewsSentDetails({ assignmentProgress, updateData, updateKey }: StudentDetailsTabProps): JSX.Element {
  const { courseId, assignmentId, userId } = useParams() as { courseId: string; assignmentId: string; userId: string };

  const [reviewsSent, setReviewSent] = useState<ReviewByItem[] | null>(null);

  useEffect(
    () => getReviewItemsOfUser(assignmentId, userId, setReviewSent),
    [updateKey, assignmentProgress, assignmentId, userId],
  );

  if (reviewsSent && reviewsSent.length > 0)
    return (
      <>
        <div id="submitters-table" className="panel-sm panel-white">
          <h2 className="title" tabIndex={-1}>
            Submitters
          </h2>
          <table>
            <tbody>
              {reviewsSent.map((review) => (
                <tr key={`row-${review.reviewId}`}>
                  <th>
                    <b>{review.submissionUser.name}</b>
                  </th>
                  <td>{getCompletionStatus(review.complete)}</td>
                  <td>{getJumpButtons(`review-sent-${review.reviewId}`, 'Jump to Review')}</td>
                  <td>
                    <DeleteReviewButton reviewId={review.reviewId} updateData={updateData} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {reviewsSent.map((review) => (
          <div
            key={`review-sent-${review.reviewId}`}
            id={`review-sent-${review.reviewId}`}
            className="panel-sm panel-white review-wrapper"
          >
            <JumpButton type="focus-child" targetId="submitters-table" className="sr-only">
              Back to submitters table
            </JumpButton>
            <h2 className="title">Submitter: {review.submissionUser.name}</h2>
            <div className="status-wrapper">
              <span className="target">Peer Review</span> {getCompletionStatus(review.complete)}
              <Button
                variant="rad low sm"
                href={`/course/${courseId}/assignment/${assignmentId}/submission/${review.submissionId}`}
                route
              >
                Open Submission
              </Button>
            </div>

            {review.comments.length > 0 ? (
              <>
                <h3>Review Comments</h3>
                {review.comments.map((commentItem) => (
                  <section key={`dialogue-${commentItem.comment.commentId}`} className="dialogue-container">
                    <h4>
                      on &quot;<i>{commentItem.comment.commentName}</i>&quot;
                    </h4>
                    <ReviewDialogue commentWithReviewComments={commentItem} teacherView />
                  </section>
                ))}
              </>
            ) : null}

            {review.ratings.length > 0 ? (
              <RatingDetailsTable heading="Review Ratings" reviewableByItem={review} />
            ) : null}
          </div>
        ))}
      </>
    );
  return (
    <div className="panel-sm panel-white no-progress-message">
      <h2 className="title">No Reviews Sent Yet</h2>
      <div>
        <p>
          <b>{assignmentProgress.user.name}</b> has not yet sent peer reviews for this assignment.
        </p>
        <p>More details will be available once they have sent peer reviews.</p>
      </div>
    </div>
  );
}

export default ReviewsSentDetails;
