import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ReviewByItem } from '../../../types/types';
import { getReviewItemsForSubmission } from '../../../utils/requests';
import JumpButton from '../../core/button/JumpButton';
import DeleteReviewButton from './DeleteReviewButton';
import RatingDetailsTable from './RatingDetailsTable';
import { getCompletionStatus, getJumpButtons, StudentDetailsTabProps } from './StudentDetailsPage';
import ReviewDialogue from '../../core/display/ReviewDialogue/ReviewDialogue';

function ReviewsReceivedDetails({ assignmentProgress, updateData, updateKey }: StudentDetailsTabProps): JSX.Element {
  const { assignmentId } = useParams() as { assignmentId: string };

  const [reviewsReceived, setReviewReceived] = useState<ReviewByItem[] | null>(null);

  useEffect(() => {
    if (assignmentProgress.submissionId !== null)
      getReviewItemsForSubmission(assignmentId, assignmentProgress.submissionId, setReviewReceived);
  }, [updateKey, assignmentProgress, assignmentId]);

  if (reviewsReceived && reviewsReceived.length > 0)
    return (
      <>
        <div id="reviewers-table" className="panel-sm panel-white">
          <h2 className="title" tabIndex={-1}>
            Reviewers
          </h2>
          <table>
            <tbody>
              {reviewsReceived.map((review) => (
                <tr key={`row-${review.reviewId}`}>
                  <th>
                    <b>{review.user.name}</b>
                  </th>
                  <td>{getCompletionStatus(review.complete)}</td>
                  <td>{getJumpButtons(`review-received-${review.reviewId}`, 'Jump to Review')}</td>
                  <td>
                    <DeleteReviewButton reviewId={review.reviewId} updateData={updateData} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {reviewsReceived.map((review) => (
          <div
            key={`review-received-${review.reviewId}`}
            id={`review-received-${review.reviewId}`}
            className="panel-sm panel-white review-wrapper"
          >
            <JumpButton type="focus-child" targetId="reviewers-table" className="sr-only">
              Back to reviewers table
            </JumpButton>
            <h2 className="title">Reviewer: {review.user.name}</h2>
            <div className="status-wrapper">
              <span className="target">Peer Review</span> {getCompletionStatus(review.complete)}
            </div>

            {review.comments.length > 0 ? (
              <>
                <h3>Review Comments</h3>
                {review.comments.map((commentItem) => (
                  <section key={`dialogue-${commentItem.comment.commentId}`} className="dialogue-container">
                    <h4>
                      on &quot;<i>{commentItem.comment.commentName}</i>&quot;
                    </h4>
                    <ReviewDialogue commentWithReviewComments={commentItem} teacherView submitterView />
                  </section>
                ))}
              </>
            ) : null}

            {review.ratings.length > 0 ? (
              <RatingDetailsTable heading="Review Ratings" reviewableByItem={review} />
            ) : null}
          </div>
        ))}
      </>
    );
  return (
    <div className="panel-sm panel-white no-progress-message">
      <h2 className="title">No Reviews Received Yet</h2>
      <div>
        <p>
          <b>{assignmentProgress.user.name}</b> has not yet received peer reviews for this assignment.
        </p>
        <p>More details will be available once they have received peer reviews.</p>
      </div>
    </div>
  );
}

export default ReviewsReceivedDetails;
