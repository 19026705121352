import Tippy from '@tippyjs/react';
import React, { useState } from 'react';
import { Assignment, Result } from '../../types/types';
import Button from '../core/button/Button/Button';
import ConfirmButton from '../core/button/ConfirmButton';
import Icon from '../core/display/Icon';
import ProgressRing from '../core/display/Progress/ProgressRing';

interface Props {
  assignment: Assignment;
  canReset?: boolean;
  restartAssignment?: () => void;
  result?: Result;
}

function GradesCard({ assignment, canReset = false, restartAssignment = () => undefined, result }: Props): JSX.Element {
  const { grade, asyncResult } = result || {};

  const [showWeights, setShowWeights] = useState(false);

  let numSubGrades = 0;
  if (assignment.writingGradeWeight > 0) numSubGrades++;
  if (assignment.reviewingGradeWeight > 0) numSubGrades++;
  if (assignment.taskGradeWeight > 0) numSubGrades++;
  if (assignment.peerEvaluationGradeWeight > 0) numSubGrades++;

  if (grade) {
    return (
      <div className="panel-sm panel-white grades-card">
        <h2 className="title">Grades</h2>
        <div className={`grades-container ${showWeights ? 'show-weights' : ''}`}>
          {numSubGrades > 1 ? (
            <div className="sub-grade-wrapper">
              <div>
                {assignment.writingGradeWeight > 0 ? (
                  <div className="sub-grade">
                    <Tippy
                      interactive
                      content={
                        <div className="explainer">
                          <p>
                            <b>Submission Grade:</b> This is the grade received on your submission. Ratings from your
                            instructor and peers are used to generate this grade.
                          </p>
                          <p>({assignment.writingGradeWeight}% of Overall Grade)</p>
                        </div>
                      }
                    >
                      <div className="cell with-tooltip">
                        <ProgressRing
                          size="sm"
                          progress={Math.round(grade.submissionGrade)}
                          radius={20}
                          strokeWidth={6}
                          padding={0}
                        />

                        <span className="type-name">
                          <div>Submission</div>
                          {grade.submissionLatePenalty > 0 ? (
                            <div className="late-penalty">Late: -{grade.submissionLatePenalty}%</div>
                          ) : null}
                        </span>
                      </div>
                    </Tippy>
                    <div className="cell">
                      <span className="triangle-indicator-sm" />
                      <span className="weight-calc">{assignment.writingGradeWeight / 100}</span>
                    </div>
                  </div>
                ) : null}
                {assignment.reviewingGradeWeight > 0 ? (
                  <>
                    <div className="sub-grade">
                      <Tippy
                        interactive
                        content={
                          <div className="explainer">
                            <p>
                              <b>Accuracy</b> measures how closely your ratings track with peer and instructor ratings
                              on the same document.
                            </p>
                            <p>
                              ({assignment.reviewingGradeWeight / (assignment.feedbackEnabled ? 2 : 1)}% of Overall
                              Grade)
                            </p>
                          </div>
                        }
                      >
                        <div className="cell with-tooltip">
                          <ProgressRing
                            size="sm"
                            progress={Math.round(grade.accuracyGrade)}
                            radius={20}
                            strokeWidth={6}
                            padding={0}
                          />
                          <span className="type-name">Accuracy</span>
                        </div>
                      </Tippy>
                      <div className="cell">
                        <span className="triangle-indicator-sm" />
                        <span className="weight-calc">
                          {assignment.reviewingGradeWeight / (100 * (assignment.feedbackEnabled ? 2 : 1))}
                        </span>
                      </div>
                    </div>
                    {assignment.feedbackEnabled ? (
                      <div className="sub-grade">
                        <Tippy
                          interactive
                          content={
                            <div className="explainer">
                              <p>
                                <b>Helpfulness</b> is calculated based on the feedback ratings your comments received
                                from your peers.
                              </p>
                              <p>({assignment.reviewingGradeWeight / 2}% of Overall Grade)</p>
                            </div>
                          }
                        >
                          <div className="cell with-tooltip">
                            <ProgressRing
                              size="sm"
                              progress={Math.round(grade.helpfulnessGrade)}
                              radius={20}
                              strokeWidth={6}
                              padding={0}
                            />
                            <span className="type-name">Helpfulness</span>
                          </div>
                        </Tippy>
                        <div className="cell">
                          <span className="triangle-indicator-sm" />
                          <span className="weight-calc">{assignment.reviewingGradeWeight / 200}</span>
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : null}
                {assignment.taskGradeWeight ? (
                  <div className="sub-grade">
                    <Tippy
                      interactive
                      content={
                        <div className="explainer">
                          <p>
                            <b>Task Grade</b> is a simple measure of whether you did all the required tasks in the
                            assignment. If you completed all the tasks, you receive 100% of the task grade.
                          </p>
                          <p>({assignment.taskGradeWeight}% of Overall Grade)</p>
                        </div>
                      }
                    >
                      <div className="cell with-tooltip">
                        <ProgressRing
                          size="sm"
                          progress={Math.round(grade.taskGrade)}
                          radius={20}
                          strokeWidth={6}
                          padding={0}
                        />
                        <span className="type-name">Task</span>
                      </div>
                    </Tippy>
                    <div className="cell">
                      <span className="triangle-indicator-sm" />
                      <span className="weight-calc">{assignment.taskGradeWeight / 100}</span>
                    </div>
                  </div>
                ) : null}
                {assignment.peerEvaluationGradeWeight ? (
                  <div className="sub-grade">
                    <Tippy
                      interactive
                      content={
                        <div className="explainer">
                          <p>
                            <b>Evaluation Grade:</b> Evaluations from your peers are used to generate this grade.
                          </p>
                          <p>({assignment.peerEvaluationGradeWeight}% of Overall Grade)</p>
                        </div>
                      }
                    >
                      <div className="cell with-tooltip">
                        <ProgressRing
                          size="sm"
                          progress={Math.round(grade.peerEvaluationGrade)}
                          radius={20}
                          strokeWidth={6}
                          padding={0}
                        />
                        <span className="type-name">Evaluation</span>
                      </div>
                    </Tippy>
                    <div className="cell">
                      <span className="triangle-indicator-sm" />
                      <span className="weight-calc">{assignment.peerEvaluationGradeWeight / 100}</span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
          {numSubGrades > 1 ? <div className="triangle-indicator" /> : null}
          <div className="overall-grade-wrapper">
            <ProgressRing
              size="lg"
              progress={Math.round(grade.overallGrade)}
              radius={50}
              strokeWidth={10}
              padding={8}
            />
            <div>Overall</div>
          </div>
        </div>
        {numSubGrades > 1 ? (
          <>
            <p className="info">
              <span className="lg-view">Hover over</span>
              <span className="sm-view">Click on</span> a grade type for an explanation
            </p>
            <div className="ctrls">
              <div className="ctrl-wrapper">
                <input
                  id="showWeights"
                  name="showWeights"
                  type="checkbox"
                  checked={showWeights}
                  onChange={(e) => setShowWeights(e.target.checked)}
                />
                <label htmlFor="showWeights">Show Grade Weight Calculations</label>
              </div>
            </div>
          </>
        ) : null}
      </div>
    );
  } else if (asyncResult && result) {
    const { submissionCheck, reviewCheck, taskCheck } = {
      ...asyncResult,
      submissionCheck: result.submissionInfo != null,
    };
    return (
      <div className="panel-sm panel-white grades-card">
        <h2 className="title">Grades</h2>
        <div className="grades-container">
          <div className="sub-grade-wrapper">
            <div>
              <div className="sub-grade">
                <div className="cell">
                  <Icon
                    className={submissionCheck ? 'complete' : 'incomplete'}
                    code={submissionCheck ? 'done' : 'close'}
                    label={submissionCheck ? 'Pass' : 'Fail'}
                  />
                  <span>Submission</span>
                </div>
              </div>
              <div className="sub-grade">
                <div className="cell">
                  <Icon
                    className={reviewCheck ? 'complete' : 'incomplete'}
                    code={reviewCheck ? 'done' : 'close'}
                    label={reviewCheck ? 'Pass' : 'Fail'}
                  />
                  <span>Reviewing</span>
                </div>
              </div>
              <div className="sub-grade">
                <div className="cell">
                  <Icon
                    className={taskCheck ? 'complete' : 'incomplete'}
                    code={taskCheck ? 'done' : 'close'}
                    label={taskCheck ? 'Pass' : 'Fail'}
                  />
                  <span>Task</span>
                </div>
              </div>
            </div>
          </div>
          <div className="triangle-indicator" />
          <div className="overall-grade-wrapper">
            <ProgressRing
              size="lg"
              progress={Math.round(asyncResult.generatedGrade)}
              radius={60}
              strokeWidth={12}
              padding={8}
            />
            <div>Overall</div>
          </div>
        </div>
        {canReset ? (
          <ConfirmButton
            modalProps={{
              heading: 'Restart Assignment',
              label:
                'Are you sure you wish to restart the assignment. Your results, submission, and reviews will be removed and you will need to recomplete all assignment steps in order to receive a new result.',
              buttonText: 'Restart',
              maxWidth: '640px',
              onConfirm: restartAssignment,
            }}
          >
            <Button id="resubmit-btn" variant="alt">
              Resubmit
            </Button>
          </ConfirmButton>
        ) : null}
      </div>
    );
  }
  return <></>;
}

export default GradesCard;
