import React, { useEffect, useMemo, useState } from 'react';
import { RosterPermission } from '../../../types/types';
import { useSelector } from 'react-redux';
import { selectCourse } from '../../../store/selectors';
import { getGraderAccess, updateRosterPermission } from '../../../utils/requests';
import Toggle from '../../core/input/Toggle/Toggle';
import Table, { CustomColumn } from '../../core/display/Table/Table';
import { CellProps } from 'react-table';

function GraderPermission(): JSX.Element {
  const [graderRosterPermission, setGraderRosterPermission] = useState<RosterPermission[]>([]);
  const course = useSelector(selectCourse);

  useEffect(() => {
    if (course?.courseId) {
      getGraderAccess(course?.courseId, setGraderRosterPermission);
    }
  }, [course?.courseId]);

  const tableColumns = useMemo<CustomColumn<RosterPermission>[]>(
    () => [
      { Header: 'Access', accessor: 'accessName', className: 'left-align' },
      {
        Header: 'Permission',
        accessor: 'accessPermission',
        Cell: function render({ value, row }: CellProps<RosterPermission, boolean>) {
          const accessName: string = row.original.accessName.toString();
          return (
            <Toggle
              checked={value}
              hideLabel
              onChange={() => {
                if (course && course.courseId) {
                  updateRosterPermission(course.courseId, 'GRADER', accessName, setGraderRosterPermission);
                }
              }}
            >
              Modify Grader Permission
            </Toggle>
          );
        },
        className: 'left-align',
      },
    ],
    [course],
  );

  return <Table columns={tableColumns} data={graderRosterPermission} />;
}

export default GraderPermission;
