import React, { useEffect, useRef, useState } from 'react';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import DashboardPage from '../dashboard/DashboardPage';
import EvaluationPage from '../evaluation/EvaluationPage';
import FeedbackPage from '../feedback/FeedbackPage';
import GroupsPage from '../groups/GroupsPage';
import ResultsPage from '../results/ResultsPage';
import NewReviewPage from '../review/NewReviewPage';
import ReviewPage from '../review/ReviewPage';
import AssignmentProgressPage from '../roster/AssignmentProgressPage';
import StudentDetailsPage from '../roster/details/StudentDetailsPage';
import ReportPage from '../roster/ReportPage';
import AssignmentRubricController from '../rubric/AssignmentRubricController';
import InstructorSubmissionMenu from '../submission/InstructorSubmissionMenu';
import SubmissionPage from '../submission/SubmissionPage';
import { getPropsFromQuery } from '../../utils/functions';
import { useDispatch, useSelector } from 'react-redux';
import { getAssignmentData } from '../../utils/requests';
import { clearAssignment, setReduxAssignment } from '../../actions';
import { selectAssignment, selectUpdateKey } from '../../store/selectors';
import AssignmentWizardController from './AssignmentWizardController';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import ReflectionPage from '../reflect/ReflectionPage';
import NewReflectionPage from '../reflect/NewReflectionPage';
import ReviewManagement from './ReviewManagement/ReviewManagement';

function AssignmentController(): JSX.Element {
  const { courseId, assignmentId } = useParams() as { courseId: string; assignmentId: string };
  const [query, setQuery] = useState(new URLSearchParams(useLocation().search));

  const lastUpdate = useRef(0);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const assignment = useSelector(selectAssignment);
  const updateKey = useSelector(selectUpdateKey);

  useEffect(() => setQuery(new URLSearchParams(location.search)), [location]);

  useEffect(() => {
    if (assignment === null || assignmentId !== assignment.assignmentId || updateKey !== lastUpdate.current) {
      getAssignmentData(
        assignmentId,
        (data) => {
          dispatch(setReduxAssignment(data));
          lastUpdate.current = updateKey;
        },
        (err) => {
          lastUpdate.current = updateKey;
          if (err.response?.status === 403) {
            navigate(`/course/${courseId}/assignments`);
            return true;
          }
          return false;
        },
      );
    }
  }, [assignment, updateKey, lastUpdate, dispatch, navigate, assignmentId, courseId]);

  useEffect(
    () => () => {
      // On unmount, clear assignment data from stored user object
      dispatch(clearAssignment());
    },
    [dispatch],
  );

  const queryProps = getPropsFromQuery(query);

  if (assignment) {
    return (
      <Routes>
        <Route path={`/dashboard`} element={<DashboardPage />} />
        <Route path={`/wizard/:type`} element={<AssignmentWizardController />} />
        <Route path={`/rubric/*`} element={<AssignmentRubricController />} />
        <Route path={`/groups/*`} element={<GroupsPage />} />
        <Route path={`/circles/*`} element={<GroupsPage circles />} />
        <Route path={`/submission/:submissionId?`} element={<SubmissionPage />} />
        <Route path={`/instructor-submission`} element={<InstructorSubmissionMenu />} />
        <Route path={`/review/new`} element={<NewReviewPage />} />
        <Route path={`/review/:reviewId`} element={<ReviewPage />} />
        <Route
          path={`/review/:reviewId/feedback`}
          element={
            <FeedbackPage
              {...(queryProps as {
                feedbackCompleted?: number;
              })}
            />
          }
        />
        <Route path={`/evaluate/*`} element={<EvaluationPage />} />
        <Route path={`/progress`} element={<AssignmentProgressPage />} />
        <Route path={`/student/:userId`} element={<StudentDetailsPage />} />
        <Route path={`/results/*`} element={<ResultsPage />} />
        <Route path={`/report/:reportId`} element={<ReportPage />} />
        <Route path={`/manage-reviews`} element={<ReviewManagement />} />
        <Route path={`/reflection/new`} element={<NewReflectionPage />} />
        <Route path={`/reflection/:reflectionId`} element={<ReflectionPage />} />
      </Routes>
    );
  }
  return <LoadingSpinner />;
}

export default AssignmentController;
