import React, { useCallback } from 'react';
import { Rating as RatingType } from '../../types/types';
import Form from '../core/input/Form/Form';
import Rating from '../core/input/Rating/Rating';

interface Props extends RatingType {
  initScore?: number;
  onInvalid?: () => void;
  updateRatingMap: (ratingId: string, score: number) => void;
}

function EvalFormRatingPage({
  name,
  initScore,
  onInvalid,
  prompt,
  ratingId,
  ratingLevels,
  updateRatingMap,
}: Props): JSX.Element {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => updateRatingMap(ratingId, parseInt(e.target.value)),
    [updateRatingMap, ratingId],
  );

  return (
    <>
      <Form.Header>
        <Form.Title>{name}</Form.Title>
      </Form.Header>
      <Form.Body>
        <Rating
          key={`rating-${ratingId}`}
          id={ratingId + ''}
          variant="lg"
          legend={prompt}
          richLegend
          onChange={handleChange}
        >
          {ratingLevels.map((level) => (
            <Rating.Entry
              key={ratingId + '-' + level.ratingLevelId}
              variant="lg"
              id={level.ratingLevelId + ''}
              name={ratingId + ''}
              value={level.score}
              defaultChecked={initScore === level.score}
              onInvalid={onInvalid}
            >
              {level.levelDescription}
            </Rating.Entry>
          ))}
        </Rating>
      </Form.Body>
    </>
  );
}

export default EvalFormRatingPage;
