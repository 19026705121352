import Tippy from '@tippyjs/react';
import React, { useState, useEffect } from 'react';
import Icon from '../../display/Icon';

interface Props {
  download?: string;
  href?: string;
  iconCode: string;
  id?: string;
  label: string;
  onClick?: () => void;
}

function OptionButton({ download, href, iconCode, id, label, onClick }: Props): JSX.Element {
  const [buttonElem, setButtonElem] = useState<JSX.Element | null>(null);

  useEffect(() => {
    const icon = <Icon code={iconCode} label={label} />;
    // If passed a HREF property, this is a link
    if (href) {
      setButtonElem(
        <a
          id={id}
          className="option-btn"
          href={href}
          download={download}
          target="_blank"
          rel="noreferrer"
          onClick={onClick}
        >
          {icon}
        </a>,
      );
    }
    // If NOT passed a HREF property, this is a button
    else if (href === undefined) {
      setButtonElem(
        <button id={id} className="option-btn" type="button" onClick={onClick}>
          {icon}
        </button>,
      );
    }
  }, [download, href, iconCode, id, label, onClick]);

  return (
    <>
      {buttonElem ? (
        <Tippy content={label} interactive>
          {buttonElem}
        </Tippy>
      ) : (
        ''
      )}
    </>
  );
}

export default React.memo(OptionButton);
