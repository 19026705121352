import React, { useEffect, useRef } from 'react';
import { focusFirstElement } from '../../../../utils/functions';
import JumpButton from '../../button/JumpButton';

interface TabListProps {
  children: React.ReactNode;
  label: string;
  tabs: React.ReactNode;
}

function ScrollingTabList({ children, label, tabs }: TabListProps): JSX.Element {
  const containerEl = useRef<HTMLDivElement>(null);
  const tablistEl = useRef<HTMLDivElement>(null);

  const selectTab = (tabEl: HTMLElement, focus = false) => {
    const panelId = tabEl.getAttribute('aria-controls');
    const panelEl = document.getElementById(panelId || '');
    if (panelEl) {
      panelEl.scrollIntoView();
      if (focus) focusFirstElement(panelEl);
    }
  };

  const handleClick = (e: React.MouseEvent) => {
    const target = e.target as HTMLElement;
    if (target.getAttribute('role') === 'button') selectTab(target, true);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const target = e.target as HTMLElement;
    const parent = target.parentNode;
    if (parent) {
      let newTab: HTMLElement | null = null;
      switch (e.key) {
        case 'Enter':
        case ' ':
          newTab = target;
          break;
        case 'ArrowLeft':
          newTab = (target.previousElementSibling || parent.lastChild) as HTMLElement;
          break;
        case 'ArrowRight':
          newTab = (target.nextElementSibling || parent.firstChild) as HTMLElement;
          break;
        case 'Home':
          newTab = parent.firstChild as HTMLElement;
          break;
        case 'End':
          newTab = parent.lastChild as HTMLElement;
      }

      if (newTab) {
        e.preventDefault();
        selectTab(newTab, true);
      }
    }
  };

  const handleScroll = () => {
    let foundFirst = false;
    document.querySelectorAll('.tabpanel').forEach((tabPanel) => {
      const tabId = tabPanel.getAttribute('aria-labelledby');
      const tabEl = document.getElementById(tabId ?? '');
      if (!foundFirst && containerEl.current && panelIsInViewport(tabPanel, containerEl.current)) {
        tabEl?.classList.add('selected');
        foundFirst = true;
      } else {
        tabEl?.classList.remove('selected');
      }
    });
  };

  useEffect(() => handleScroll, []);

  return (
    <div className="tabs scrolling-tabs" ref={containerEl} onScrollCapture={handleScroll}>
      <div
        className="tablist"
        role="tablist"
        aria-label={label}
        ref={tablistEl}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
      >
        {tabs}
      </div>
      {children}
    </div>
  );
}

interface TabProps {
  children: React.ReactNode;
  controls: string;
  id: string;
}

function Tab({ children, controls, id }: TabProps): JSX.Element {
  return (
    <div className="tab" tabIndex={0} role="button" aria-selected="false" aria-controls={controls} id={id}>
      {children}
    </div>
  );
}

interface TabPanelProps {
  children: React.ReactNode;
  id: string;
  labeledBy: string;
  title: string;
}

function TabPanel({ children, id, labeledBy, title }: TabPanelProps): JSX.Element {
  return (
    <div className="tabpanel" role="tabpanel" id={id} aria-labelledby={labeledBy}>
      <h2 tabIndex={-1}>
        {title} <hr />
      </h2>
      <JumpButton className="sr-only sr-show-on-focus" type="focus" targetId={labeledBy}>
        Back to tab list
      </JumpButton>
      {children}
    </div>
  );
}

export const panelIsInViewport = (element: Element, viewPort: HTMLElement): boolean => {
  const elemRect = element.getBoundingClientRect();
  const viewportRect = viewPort.getBoundingClientRect();

  return elemRect.top < (viewportRect.height - viewportRect.top) / 2 && elemRect.bottom >= viewportRect.top + 8;
};

ScrollingTabList.Tab = Tab;
ScrollingTabList.TabPanel = TabPanel;

export default ScrollingTabList;
