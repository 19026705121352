import React from 'react';
import { appendClass } from '../../../../utils/functions';
import { Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';

interface Props {
  ariaCurrent?: boolean;
  ariaDescribedBy?: string;
  ariaHasPopup?: boolean;
  ariaHidden?: boolean;
  ariaLabel?: string;
  children: React.ReactNode;
  className?: string;
  classOverride?: boolean;
  disabled?: boolean;
  download?: string;
  formNoValidate?: boolean;
  href?: string;
  id?: string;
  onClick?: React.MouseEventHandler;
  refresh?: boolean;
  route?: boolean;
  tooltip?: string;
  type?: 'button' | 'submit' | 'reset';
  variant?: string;
}

function Button({
  ariaCurrent,
  ariaDescribedBy,
  ariaLabel,
  ariaHasPopup,
  ariaHidden,
  children,
  className,
  classOverride,
  disabled,
  download,
  formNoValidate,
  href,
  id,
  onClick,
  refresh = false,
  route = false,
  tooltip,
  type,
  variant = 'primary',
}: Props): JSX.Element {
  const getClassesFromVariant = (variant: string) => {
    if (classOverride) return className || '';
    if (!variant || variant === '') return 'peer-button';

    const variantList = variant.split(' ');
    let classes = 'peer-button';
    // if (variantList.includes('primary')) classes = appendClass(classes, 'peer-button');
    if (variantList.includes('secondary')) classes = appendClass(classes, 'button-secondary');
    if (variantList.includes('alt')) classes = appendClass(classes, 'button-alt');
    if (variantList.includes('rad')) classes = appendClass(classes, 'button-rad');
    if (variantList.includes('sm')) classes = appendClass(classes, 'button-sm');
    if (variantList.includes('xs')) classes = appendClass(classes, 'button-xs');
    if (variantList.includes('low')) classes = appendClass(classes, 'button-low');
    if (variantList.includes('alt-white')) classes = appendClass(classes, 'button-alt btn-white');
    if (variantList.includes('green')) classes = appendClass(classes, 'button-green');
    if (variantList.includes('link')) classes = appendClass(classes, 'button-link');
    if (className) classes = appendClass(classes, className);
    return classes;
  };

  let buttonElement = <></>;

  const ariaProps = {
    'aria-current': ariaCurrent,
    'aria-describedby': ariaDescribedBy,
    'aria-label': ariaLabel,
    'aria-haspopup': ariaHasPopup,
    'aria-hidden': ariaHidden,
  };

  if (href) {
    if (route) {
      buttonElement = (
        <Link className={getClassesFromVariant(variant)} id={id} to={href} onClick={onClick} {...ariaProps}>
          {children}
        </Link>
      );
    } else {
      if (download) {
        buttonElement = (
          <a
            className={getClassesFromVariant(variant)}
            id={id}
            href={href}
            download={download}
            {...ariaProps}
            tabIndex={ariaHidden ? -1 : undefined}
            onClick={onClick}
          >
            {children}
          </a>
        );
      } else {
        const newTabProps = refresh ? {} : { target: '_blank', rel: 'noreferrer' };
        buttonElement = (
          <a
            className={getClassesFromVariant(variant)}
            id={id}
            href={href}
            {...newTabProps}
            {...ariaProps}
            tabIndex={ariaHidden ? -1 : undefined}
            onClick={onClick}
          >
            {children}
          </a>
        );
      }
    }
  } else {
    buttonElement = (
      <button
        className={getClassesFromVariant(variant)}
        id={id}
        type={type}
        onClick={onClick}
        disabled={disabled}
        formNoValidate={formNoValidate}
        {...ariaProps}
        tabIndex={ariaHidden ? -1 : undefined}
      >
        {children}
      </button>
    );
  }

  if (tooltip) {
    if (disabled)
      return (
        <Tippy content={tooltip} interactive>
          <span className="tippy-btn-wrapper">{buttonElement}</span>
        </Tippy>
      );
    return (
      <Tippy content={tooltip} interactive>
        {buttonElement}
      </Tippy>
    );
  }

  return buttonElement;
}

export default Button;
