import React, { useRef, useState } from 'react';
import { curveMonotoneX } from '@visx/curve';
import { AnimatedAreaSeries, Axis, Grid, Tooltip, XYChart } from '@visx/xychart';
import { RenderTooltipParams } from '@visx/xychart/lib/components/Tooltip';
import moment from 'moment';
import { Assignment, DailyTaskEvents } from '../../../types/types';
import { getPhaseStatus, stringArrayIncludes } from '../../../utils/functions';
import AssignmentPhaseIcon from '../../core/display/AssignmentPhaseIcon/AssignmentPhaseIcon';
import ProgressRing from '../../core/display/Progress/ProgressRing';
import FilterTab from '../../core/layout/FilterTab/FilterTab';
import JumpButton from '../../core/button/JumpButton';
import _ from 'lodash';

export type DataDisplayType = 'TASKS' | 'PERCENT';

const genAccessor = (
  tasksAccessor: 'submissionTasks' | 'reviewTasks' | 'feedbackTasks' | 'evaluationTasks',
  percentAccessor: 'submissionPercent' | 'reviewPercent' | 'feedbackPercent' | 'evaluationPercent',
) => ({
  numAccessors: {
    xAccessor: (d: DailyTaskEvents) => d.date,
    yAccessor: (d: DailyTaskEvents) => d[tasksAccessor].length,
  },
  percentAccessors: {
    xAccessor: (d: DailyTaskEvents) => d.date,
    yAccessor: (d: DailyTaskEvents) => d[percentAccessor],
  },
});

type Accessor = {
  xAccessor: (d: DailyTaskEvents) => string;
  yAccessor: (d: DailyTaskEvents) => number;
};

type CombinedAccessor = { numAccessors: Accessor; percentAccessors: Accessor };

const selectAccessor = (combinedAccessor: CombinedAccessor, type: DataDisplayType) => {
  switch (type) {
    case 'TASKS':
      return combinedAccessor.numAccessors;
    case 'PERCENT':
      return combinedAccessor.percentAccessors;
  }
};

const submissionAccessors = genAccessor('submissionTasks', 'submissionPercent');
const reviewAccessors = genAccessor('reviewTasks', 'reviewPercent');
const feedbackAccessors = genAccessor('feedbackTasks', 'feedbackPercent');
const evaluationAccessors = genAccessor('evaluationTasks', 'evaluationPercent');

interface Props {
  assignment?: Assignment;
  dailyTaskEvents: DailyTaskEvents[];
  dataDisplay: DataDisplayType;
  height: number;
}

function ActivityGraph({ assignment, dailyTaskEvents, dataDisplay, height }: Props): JSX.Element {
  const id = useRef(_.uniqueId());
  const [filterList, setFilterList] = useState<string[]>([]);

  const { submission, review, feedback, evaluate } = assignment
    ? getPhaseStatus(assignment)
    : {
        submission: true,
        review: true,
        feedback: true,
        evaluate: true,
      };

  const getYValueByType = (combinedAccessor: CombinedAccessor, dailyTaskEvents: DailyTaskEvents) =>
    Math.round(selectAccessor(combinedAccessor, dataDisplay).yAccessor(dailyTaskEvents));

  const sharedProps = (color: string) => ({
    lineProps: { stroke: color },
    fill: color,
    data: dailyTaskEvents,
    opacity: 0.2,
    curve: curveMonotoneX,
  });

  const yDomain = dataDisplay === 'PERCENT' ? { domain: [0, 100] } : {};

  return (
    <>
      <div className="activity-graph" aria-hidden>
        <XYChart
          height={height}
          margin={{ top: 8, bottom: 16, right: 16, left: 32 }}
          xScale={{ type: 'band' }}
          yScale={{ type: 'linear', ...yDomain }}
        >
          <Grid columns={false} numTicks={4} strokeDasharray="5 2" lineStyle={{ stroke: '#EEEEEE', strokeWidth: 2 }} />

          {submission && stringArrayIncludes(filterList, 'Submission') ? (
            <AnimatedAreaSeries
              dataKey="submissions"
              {...selectAccessor(submissionAccessors, dataDisplay)}
              {...sharedProps('#7878F1')}
            />
          ) : null}
          {review && stringArrayIncludes(filterList, 'Review') ? (
            <AnimatedAreaSeries
              dataKey="reviews"
              {...selectAccessor(reviewAccessors, dataDisplay)}
              {...sharedProps('#E676E3')}
            />
          ) : null}
          {feedback && stringArrayIncludes(filterList, 'Feedback') ? (
            <AnimatedAreaSeries
              dataKey="feedback"
              {...selectAccessor(feedbackAccessors, dataDisplay)}
              {...sharedProps('#E4C445')}
            />
          ) : null}
          {evaluate && stringArrayIncludes(filterList, 'Evaluate') ? (
            <AnimatedAreaSeries
              dataKey="evaluations"
              {...selectAccessor(evaluationAccessors, dataDisplay)}
              {...sharedProps('#38B934')}
            />
          ) : null}

          <Axis
            orientation="bottom"
            numTicks={8}
            tickFormat={(date) => moment(date).format('MMM DD')}
            hideAxisLine
            hideTicks
          />
          <Axis
            orientation="left"
            numTicks={4}
            hideZero
            hideAxisLine
            hideTicks
            tickFormat={(val) => (Number.isInteger(val) ? val : '')}
          />

          <Tooltip
            snapTooltipToDatumX
            snapTooltipToDatumY
            showVerticalCrosshair
            verticalCrosshairStyle={{ stroke: 'black', opacity: 0.2 }}
            showSeriesGlyphs
            glyphStyle={{ fill: 'black', opacity: 0.2 }}
            renderTooltip={({ tooltipData }: RenderTooltipParams<DailyTaskEvents>) => {
              if (tooltipData && tooltipData.nearestDatum) {
                const { datum } = tooltipData.nearestDatum;
                const submissionValue = getYValueByType(submissionAccessors, datum);
                const reviewValue = getYValueByType(reviewAccessors, datum);
                const feedbackValue = getYValueByType(feedbackAccessors, datum);
                const evaluationValue = getYValueByType(evaluationAccessors, datum);
                return (
                  <div className="activity-tooltip">
                    <h4>{moment(datum.date).format('ddd MMMM D, YYYY')}</h4>
                    {submissionValue > 0 || reviewValue > 0 || feedbackValue > 0 || evaluationValue > 0 ? (
                      <table>
                        <tbody>
                          {submissionValue > 0 ? (
                            <tr>
                              <td className="icon-cell">
                                <AssignmentPhaseIcon phase="submit" size={24} />
                              </td>
                              <th>Submission</th>
                              <td>
                                {dataDisplay === 'TASKS' ? (
                                  `${submissionValue} tasks`
                                ) : (
                                  <ProgressRing progress={submissionValue} radius={16} strokeWidth={4} size="sm" />
                                )}
                              </td>
                            </tr>
                          ) : null}
                          {reviewValue > 0 ? (
                            <tr>
                              <td className="icon-cell">
                                <AssignmentPhaseIcon phase="review" size={24} />
                              </td>
                              <th>Review</th>
                              <td>
                                {dataDisplay === 'TASKS' ? (
                                  `${reviewValue} tasks`
                                ) : (
                                  <ProgressRing progress={reviewValue} radius={16} strokeWidth={4} size="sm" />
                                )}
                              </td>
                            </tr>
                          ) : null}
                          {feedbackValue > 0 ? (
                            <tr>
                              <td className="icon-cell">
                                <AssignmentPhaseIcon phase="feedback" size={24} />
                              </td>
                              <th>Feedback</th>
                              <td>
                                {dataDisplay === 'TASKS' ? (
                                  `${feedbackValue} tasks`
                                ) : (
                                  <ProgressRing progress={feedbackValue} radius={16} strokeWidth={4} size="sm" />
                                )}
                              </td>
                            </tr>
                          ) : null}
                          {evaluationValue > 0 ? (
                            <tr>
                              <td className="icon-cell">
                                <AssignmentPhaseIcon phase="evaluate" size={24} />
                              </td>
                              <th>Team Member Evaluation</th>
                              <td>
                                {dataDisplay === 'TASKS' ? (
                                  `${evaluationValue} tasks`
                                ) : (
                                  <ProgressRing progress={evaluationValue} radius={16} strokeWidth={4} size="sm" />
                                )}
                              </td>
                            </tr>
                          ) : null}
                        </tbody>
                      </table>
                    ) : (
                      <p id="no-activity">No Activity</p>
                    )}
                  </div>
                );
              }
              return <></>;
            }}
          />
        </XYChart>
        <FilterTab label="Show:" setFilterList={setFilterList}>
          {submission ? (
            <FilterTab.Button id="btn-submission" type="checkbox" name="line-filters" defaultChecked tabIndex={-1}>
              Submission
            </FilterTab.Button>
          ) : null}
          {review ? (
            <FilterTab.Button id="btn-review" type="checkbox" name="line-filters" defaultChecked tabIndex={-1}>
              Review
            </FilterTab.Button>
          ) : null}
          {feedback ? (
            <FilterTab.Button id="btn-feedback" type="checkbox" name="line-filters" defaultChecked tabIndex={-1}>
              Feedback
            </FilterTab.Button>
          ) : null}
          {evaluate ? (
            <FilterTab.Button id="btn-evaluate" type="checkbox" name="line-filters" defaultChecked tabIndex={-1}>
              Evaluate
            </FilterTab.Button>
          ) : null}
        </FilterTab>
      </div>

      <div className="accessible-table sr-only">
        <JumpButton id={`pre-table-btn-${id.current}`} targetId={`post-table-btn-${id.current}`} type="focus">
          Skip to after table
        </JumpButton>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              {submission && dataDisplay === 'TASKS' ? <th>Submission Tasks</th> : null}
              {submission && dataDisplay === 'PERCENT' ? <th>Submission Progress Percent Complete</th> : null}
              {review && dataDisplay === 'TASKS' ? <th>Review Tasks</th> : null}
              {review && dataDisplay === 'PERCENT' ? <th>Review Progress Percent Complete</th> : null}
              {feedback && dataDisplay === 'TASKS' ? <th>Feedback Tasks</th> : null}
              {feedback && dataDisplay === 'PERCENT' ? <th>Feedback Progress Percent Complete</th> : null}
              {evaluate && dataDisplay === 'TASKS' ? <th>Evaluation Tasks</th> : null}
              {evaluate && dataDisplay === 'PERCENT' ? <th>Evaluation Progress Percent Complete</th> : null}
            </tr>
          </thead>
          <tbody>
            {dailyTaskEvents.map((dailyTaskEvent) => (
              <tr key={dailyTaskEvent.date}>
                <td>{dailyTaskEvent.date}</td>
                {submission && dataDisplay === 'TASKS' ? <td>{dailyTaskEvent.submissionTasks.length}</td> : null}
                {submission && dataDisplay === 'PERCENT' ? (
                  <td>{Math.round(dailyTaskEvent.submissionPercent)}%</td>
                ) : null}
                {review && dataDisplay === 'TASKS' ? <td>{dailyTaskEvent.reviewTasks.length}</td> : null}
                {review && dataDisplay === 'PERCENT' ? <td>{Math.round(dailyTaskEvent.reviewPercent)}%</td> : null}
                {feedback && dataDisplay === 'TASKS' ? <td>{dailyTaskEvent.feedbackTasks.length}</td> : null}
                {feedback && dataDisplay === 'PERCENT' ? <td>{Math.round(dailyTaskEvent.feedbackPercent)}%</td> : null}
                {evaluate && dataDisplay === 'TASKS' ? <td>{dailyTaskEvent.evaluationTasks.length}</td> : null}
                {evaluate && dataDisplay === 'PERCENT' ? (
                  <td>{Math.round(dailyTaskEvent.evaluationPercent)}%</td>
                ) : null}
              </tr>
            ))}
          </tbody>
        </table>
        <JumpButton id={`post-table-btn-${id.current}`} targetId={`pre-table-btn-${id.current}`} type="focus">
          Skip to before table
        </JumpButton>
      </div>
    </>
  );
}

export default ActivityGraph;
