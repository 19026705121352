import React, { useEffect, useCallback } from 'react';
import { CourseUser, User } from '../../types/types';
import {
  activateCourse,
  archiveCourse,
  deleteCourse,
  getRosterInstructors,
  deleteUserFromCourse,
  joinCourseViaCode,
} from '../../utils/requests';
import Button from '../core/button/Button/Button';
import CourseCard from './CourseCard';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import { formDataToObject, setPageTitle } from '../../utils/functions';
import Dropdown from '../core/button/Dropdown/Dropdown';
import CourseListTable from './CourseListTable';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
  noSeats?: boolean;
  updateData: () => void;
  updateKey: number;
  user: User;
}

function CourseDashboard({ noSeats, updateData, updateKey, user }: Props): JSX.Element {
  useEffect(() => setPageTitle('Home'), []);

  const location = useLocation();
  const navigate = useNavigate();
  const { modalDispatch } = useModalContext();

  const handleSelect = useCallback(
    (courseUser: CourseUser) => navigate(`/course/${courseUser.course.courseId}/assignments`),
    [navigate],
  );

  useEffect(() => {
    if (noSeats === true)
      modalDispatch(
        openModal({
          heading: 'Unable to Join Course',
          label:
            'Cannot join course because there are no available seats. Please contact your instructor for more information.',
          buttonText: 'Okay',
          cancelHide: true,
        }),
      );
  }, [noSeats, modalDispatch]);

  const handleJoinCourse = useCallback(() => {
    modalDispatch(
      openModal({
        heading: 'Join Course',
        label: 'Enter your course join code below:',
        inputType: 'text',
        onSubmit: (formData) => {
          const code = formDataToObject(formData).result;
          joinCourseViaCode(
            code,
            (membership) => {
              updateData();
              const joinedCourse = membership.course;
              modalDispatch(
                openModal({
                  heading: 'Course Joined',
                  buttonText: 'Continue',
                  cancelHide: true,
                  closeButton: true,
                  children: (
                    <>
                      <p>Successfully joined the following course:</p>
                      <CourseCard
                        name={joinedCourse.courseName}
                        discipline={joinedCourse.discipline}
                        semester={joinedCourse.semester}
                        year={joinedCourse.year}
                        label={joinedCourse.label}
                        readOnly={true}
                      />
                    </>
                  ),
                }),
              );
            },
            (error) => {
              if (error.response?.status === 402) {
                navigate(`${location.pathname}?noSeats=true`);
                return true;
              }
              return false;
            },
          );
        },
      }),
    );
  }, [location.pathname, modalDispatch, navigate, updateData]);

  const handleArchive = useCallback(
    (courseId: string) => {
      modalDispatch(
        openModal({
          heading: 'Archive Course',
          label: 'Are you sure you want to archive this course?',
          buttonText: 'Archive',
          onConfirm: () => {
            archiveCourse(courseId, () => updateData());
          },
        }),
      );
    },
    [modalDispatch, updateData],
  );

  const handleActivate = useCallback(
    (courseId: string) => {
      modalDispatch(
        openModal({
          heading: 'Activate Course',
          label: 'Are you sure you want to activate this course?',
          buttonText: 'Activate',
          onConfirm: () => {
            activateCourse(courseId, () => updateData());
          },
        }),
      );
    },
    [modalDispatch, updateData],
  );

  const handleDelete = useCallback(
    (courseId: string) => {
      modalDispatch(
        openModal({
          heading: 'Delete Course',
          label: 'Are you sure you want to delete this course?',
          buttonText: 'Delete',
          onConfirm: () => {
            deleteCourse(courseId, () => {
              updateData();
              modalDispatch(
                openModal({
                  heading: 'Course Deleted',
                  label: 'Course has been deleted.',
                  buttonText: 'Continue',
                  cancelHide: true,
                }),
              );
            });
          },
        }),
      );
    },
    [modalDispatch, updateData],
  );

  const handleLeave = useCallback(
    (courseId: string) => {
      modalDispatch(
        openModal({
          heading: 'Leave Course',
          label: 'Are you sure you want to leave this course?',
          buttonText: 'Leave',
          onConfirm: () => {
            // Make sure instructor isn't trying to leave a course instructor-less
            getRosterInstructors(courseId, (roster) => {
              if (roster.length === 1 && roster[0].userId === user.userId) {
                modalDispatch(
                  openModal({
                    heading: 'Cannot Leave Course',
                    label: 'Courses cannot be without an instructor.',
                    buttonText: 'Okay',
                    cancelHide: true,
                  }),
                );
              } else {
                deleteUserFromCourse(user.userId, courseId, () => {
                  updateData();
                  modalDispatch(
                    openModal({
                      heading: 'Left Course',
                      label: 'You have left the course.',
                      buttonText: 'Continue',
                      cancelHide: true,
                    }),
                  );
                });
              }
            });
          },
        }),
      );
    },
    [modalDispatch, updateData, user.userId],
  );

  return (
    <section className="home-body" id="course-menu">
      <h1 className="sr-only">Courses</h1>
      <CourseListTable
        ctrlsInsert={
          <div className="col-3">
            {user.role === 'TEACHER' || user.role === 'SCHOOL ADMIN' ? (
              <Dropdown
                className="peer-button button-rad button-low"
                id="new-course-dropdown"
                buttonContent="New Course"
                iconCode="arrow_drop_down"
                align="left"
                top="100%"
              >
                <Dropdown.Link href="/course/dashboard/new" route>
                  Create Course
                </Dropdown.Link>
                <Dropdown.Link href="/course/dashboard/course-copy-list" route>
                  Copy Course
                </Dropdown.Link>
              </Dropdown>
            ) : (
              ''
            )}
            <Button variant="rad low" onClick={handleJoinCourse}>
              Join Course
            </Button>
          </div>
        }
        onRowSelect={handleSelect}
        updateKey={updateKey}
        onActivate={handleActivate}
        onArchive={handleArchive}
        onDelete={handleDelete}
        onLeave={handleLeave}
      />
    </section>
  );
}

export default CourseDashboard;
