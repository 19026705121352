import React from 'react';
import { focusFirstElement } from '../../../utils/functions';
import Button from './Button/Button';

interface Props {
  ariaHidden?: boolean;
  children: React.ReactNode;
  className?: string;
  id?: string;
  targetId: string;
  type: 'focus' | 'focus-child' | 'scroll';
  variant?: string;
}

function JumpButton({ ariaHidden, children, className, id, targetId, type, variant }: Props): JSX.Element {
  return (
    <Button
      id={id}
      className={className}
      variant={variant}
      onClick={() => {
        const targetEl = document.getElementById(targetId);
        if (targetEl) {
          switch (type) {
            case 'focus':
              targetEl.focus();
              break;
            case 'focus-child':
              focusFirstElement(targetEl);
              break;
            case 'scroll':
              targetEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }
      }}
      ariaHidden={ariaHidden}
    >
      {children}
    </Button>
  );
}

export default JumpButton;
