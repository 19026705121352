import React, { useCallback, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { focusFirstElement, handleKeySelect, outsideClick } from '../../../../utils/functions';
import JumpButton from '../../button/JumpButton';
import Icon from '../../display/Icon';
import CoreButton from '../../button/Button/Button';

interface Props {
  children: React.ReactNode;
  id?: string;
}

function Navbar({ children, id }: Props): JSX.Element {
  return (
    <>
      <JumpButton type="focus-child" targetId="site-content" className="sr-only sr-show-on-focus">
        Skip to Main Content
      </JumpButton>
      <div role="banner" className="navbar" id={id}>
        {children}
      </div>
    </>
  );
}

interface LogoProps {
  alt?: string;
  href: string;
  size?: 'sm' | 'lg';
  src: string;
  width?: number;
}

function Logo({ alt, href, size, src, width }: LogoProps): JSX.Element {
  return (
    <Link className={`navbar-logo${size ? ` logo-${size}` : ''}`} to={href}>
      <img src={src} width={width} alt={alt} />
    </Link>
  );
}

interface ContentProps {
  children: React.ReactNode;
}

function Content({ children }: ContentProps): JSX.Element {
  return <div className="navbar-content">{children}</div>;
}

interface ButtonProps {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  label: string;
  menuId: string;
}

function Button({ children, className, disabled = false, label, menuId }: ButtonProps): JSX.Element {
  const deselect = useCallback(() => {
    const menuEl = document.getElementById(menuId);
    const buttonEl = document.getElementById(`${menuId}-btn`);
    if (menuEl && buttonEl && menuEl.style.display === 'block') {
      menuEl.style.display = ``;
      buttonEl.classList.remove('navbar-button-selected');
      buttonEl.focus();
    }
  }, [menuId]);

  const handleMouseDown = useCallback(
    (e: MouseEvent) => {
      const menuEl = document.getElementById(menuId);
      const buttonEl = document.getElementById(`${menuId}-btn`);
      if (menuEl && buttonEl && outsideClick(e, [menuEl, buttonEl])) deselect();
    },
    [menuId, deselect],
  );

  const handleEscape = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') deselect();
    },
    [deselect],
  );

  const location = useLocation();

  useEffect(() => deselect(), [location, deselect]);

  useEffect(() => {
    window.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('keydown', handleEscape);

    return () => {
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('keydown', handleEscape);
    };
  }, [handleMouseDown, handleEscape]);

  const handleSelect = () => {
    // Deselect any selected buttons/menus
    const prevSelectedBtns = document.querySelectorAll('.navbar-button-selected');
    [...prevSelectedBtns].forEach((btn) => {
      if (btn.id !== `${menuId}-btn`) {
        btn.classList.remove('navbar-button-selected');
        const menu = document.getElementById(btn.id.replace(/-btn/g, ''));
        if (menu) menu.style.display = ``;
      }
    });

    const menuElem = document.getElementById(menuId);
    const buttonEl = document.getElementById(`${menuId}-btn`);
    if (menuElem && buttonEl) {
      if (menuElem.style.display === 'block') {
        menuElem.style.display = ``;
        buttonEl.classList.remove('navbar-button-selected');
      } else {
        menuElem.style.display = 'block';
        buttonEl.classList.add('navbar-button-selected');
        focusFirstElement(menuElem);
      }
    }
  };

  return (
    <div
      className={`navbar-button${className ? ` ${className}` : ''}`}
      id={`${menuId}-btn`}
      onClick={handleSelect}
      onKeyDown={(e) => handleKeySelect(e, handleSelect)}
      role="button"
      tabIndex={0}
      aria-label={label}
      aria-haspopup
      aria-disabled={disabled}
    >
      {children}
    </div>
  );
}

interface MenuProps {
  children: React.ReactNode;
  id: string;
  title: string;
}

function Menu({ children, id, title }: MenuProps): JSX.Element {
  const deselect = useCallback(() => {
    const menuEl = document.getElementById(id);
    const buttonEl = document.getElementById(`${id}-btn`);
    if (menuEl && buttonEl && menuEl.style.display === 'block') {
      menuEl.style.display = ``;
      buttonEl.classList.remove('navbar-button-selected');
      buttonEl.focus();
    }
  }, [id]);

  return (
    <div className="navbar-menu" id={id} role="dialog" aria-modal>
      <h2 tabIndex={-1}>{title}</h2>
      <hr />
      {children}
      <CoreButton className="menu-close button-mini" classOverride onClick={deselect}>
        <Icon code="close" />
      </CoreButton>
    </div>
  );
}

Navbar.Logo = Logo;
Navbar.Content = Content;
Navbar.Button = Button;
Navbar.Menu = Menu;

export default Navbar;
