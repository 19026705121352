import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CourseSection, CourseSectionUser } from '../../types/types';
import { useParams } from 'react-router-dom';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import {
  createCourseSection,
  deleteAllCourseSections,
  deleteCourseSection,
  importSections,
  removeUserFromCourseSection,
  retrieveCourseSections,
  updateCourseSection,
} from '../../utils/requests';
import Tippy from '@tippyjs/react';
import { SAVE_DEBOUNCE_MAX_WAIT, SAVE_DEBOUNCE_WAIT } from '../../utils/constants';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import Button from '../core/button/Button/Button';
import UploadMenu from '../groups/UploadMenu';
import Accordion from '../core/layout/Accordion/Accordion';
import Icon from '../core/display/Icon';
import Dropdown from '../core/button/Dropdown/Dropdown';
import { useLocation } from 'react-router';
import _ from 'lodash';
import Avatar from '../core/display/Avatar/Avatar';

function CourseSectionManagement(): JSX.Element {
  const [requestedLength, setRequestedLength] = useState(0);
  const { courseId } = useParams() as { courseId: string };
  const [courseSectionUsers, setCourseSectionUsers] = useState<CourseSectionUser[]>([]);
  const [courseSection, setCourseSections] = useState<CourseSection[]>([]);
  const [selectedId, setSelectedId] = useState('');
  const [updateKey, setUpdateKey] = useState(0);

  const { modalDispatch } = useModalContext();
  const location = useLocation();

  const basePath = useMemo(() => {
    const matches = location.pathname.match(/(\S+\/section)/g);
    return (matches || [''])[0];
  }, [location]);

  const forceUpdate = useCallback(() => {
    setUpdateKey((prevKey) => prevKey + 1);
  }, []);

  useEffect(() => {
    retrieveCourseSections(courseId, setCourseSectionUsers);
  }, [courseId, updateKey]);

  const requestImportSection = useCallback(
    (groups: string[][]) => {
      importSections(courseId, groups, forceUpdate);
    },
    [courseId, forceUpdate],
  );

  const deleteAllSectionHandler = () => {
    deleteAllCourseSections(courseId, forceUpdate);
  };

  const deleteSection = (courseSectionId: string) => {
    deleteCourseSection(courseId, courseSectionId, forceUpdate);
  };

  const requestRemoveUserFromSection = (courseSectionId: string, userId: string) => {
    removeUserFromCourseSection(courseId, courseSectionId, userId, forceUpdate);
  };

  const rawSaveGroup = useCallback(
    (section: CourseSection, callback: () => void = () => undefined) =>
      updateCourseSection(courseId, section.courseSectionId, section, callback),
    [courseId],
  );

  const debouncedSaveSection = useMemo(
    () =>
      _.debounce(rawSaveGroup, SAVE_DEBOUNCE_WAIT, {
        maxWait: SAVE_DEBOUNCE_MAX_WAIT,
      }),
    [rawSaveGroup],
  );

  const updateSection = useCallback(
    (section: CourseSection) => {
      setCourseSections((prevSections) => {
        let diff = true;
        const newSections = prevSections.map((prevSection) => {
          if (prevSection.courseSectionId === section.courseSectionId) {
            if (!_.isEqual(prevSection, section)) return section;
            else diff = false;
          }
          return prevSection;
        });
        if (diff) debouncedSaveSection(section);
        return newSections;
      });
    },
    [debouncedSaveSection],
  );

  const renderLoadingPlaceholders = () => {
    const loadingPlaceholders = [] as JSX.Element[];
    for (let i = courseSectionUsers.length; i < requestedLength; i++)
      loadingPlaceholders.push(
        <div key={`loading-placeholder-${i}`} id="group-add-loading-placeholder">
          <LoadingSpinner position="static" size={32} />
          <span>Loading</span>
        </div>,
      );
    return loadingPlaceholders;
  };

  return (
    <div className="course-section-manage-page">
      <div id="ctrls-bar">
        <Button
          id="section-upload-btn"
          variant="rad low"
          onClick={() => {
            modalDispatch(
              openModal({
                heading: 'Upload Section List',
                form: false,
                closeButton: true,
                noActionButtons: true,
                children: <UploadMenu requestImportGroups={requestImportSection} />,
              }),
            );
          }}
        >
          Upload Group List
        </Button>
        <Button
          id="new-section-btn"
          variant="rad low"
          onClick={() => {
            setRequestedLength((prevLength) => prevLength + 1);
            createCourseSection(courseId, `Unnamed Section ${requestedLength + 1}`, forceUpdate, (error) => {
              setRequestedLength((prevLength) => prevLength - 1);
            });
          }}
        >
          New Group
        </Button>

        <Button id="section-delete-btn" variant="rad low" onClick={deleteAllSectionHandler}>
          Delete All Sections
        </Button>
      </div>
      {courseSectionUsers.length > 0 ? (
        <div className="course-section-entries">
          {courseSectionUsers.map((section) => (
            <SectionEntry
              basePath={basePath}
              key={`section-${section.courseSection.courseSectionId}`}
              courseSectionUser={section}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              updateSection={updateSection}
              requestRemoveUserFromSection={requestRemoveUserFromSection}
              deleteSection={deleteSection}
            />
          ))}
          {renderLoadingPlaceholders()}
        </div>
      ) : (
        <div className="section-unavailable">
          <div className="page teacher-results-row">
            <div id="no-results-container" className="fadeIn">
              <div id="grades-unavailable-panel" className="panel-white">
                <h1>Course Section Unavailable</h1>
                <p>The course section is not set up yet. </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

interface SectionEntryProps {
  basePath: string;
  courseSectionUser: CourseSectionUser;
  requestRemoveUserFromSection: (scetionId: string, userId: string) => void;
  selectedId: string;
  setSelectedId: React.Dispatch<React.SetStateAction<string>>;
  updateSection: (section: CourseSection) => void;
  deleteSection: (sectionId: string) => void;
}

function SectionEntry({
  basePath,
  courseSectionUser,
  requestRemoveUserFromSection,
  selectedId,
  setSelectedId,
  updateSection,
  deleteSection,
}: SectionEntryProps): JSX.Element {
  const [name, setName] = useState(courseSectionUser.courseSection.courseSectionName);
  const numSectionMembers = courseSectionUser.instructors.length + courseSectionUser.students.length;

  const { modalDispatch } = useModalContext();
  useEffect(
    () => updateSection({ ...courseSectionUser.courseSection, courseSectionName: name }),
    [courseSectionUser, name, updateSection],
  );

  return (
    <Accordion
      name={name}
      id={`group-${courseSectionUser.courseSection.courseSectionId}`}
      className={
        'group-accordion' + (selectedId === courseSectionUser.courseSection.courseSectionId ? ' selected' : '')
      }
      defaultName={name}
      nameChangeOn={true}
      onNameChange={setName}
      collapsedValue={selectedId !== courseSectionUser.courseSection.courseSectionId}
      onToggle={(value) => {
        if (value) {
          setSelectedId('');
        } else {
          setSelectedId(courseSectionUser.courseSection.courseSectionId);
        }
      }}
      contentPadding="0.5rem"
      headingContent={
        <>
          <span className="group-size-indicator" role="img" aria-label={`${numSectionMembers} Members`}>
            <Icon code="person" ariaHidden />
            <span>{numSectionMembers}</span>
          </span>
          <Dropdown iconCode="more_vert" align="right">
            <Dropdown.Link
              href="#"
              onClick={() => {
                modalDispatch(
                  openModal({
                    heading: 'Delete Course Section',
                    label: `Are you sure you want to delete "${courseSectionUser.courseSection.courseSectionName}"?`,
                    inputType: 'none',
                    buttonText: 'Delete',
                    onConfirm: () => {
                      deleteSection(courseSectionUser.courseSection.courseSectionId);
                    },
                  }),
                );
              }}
            >
              Delete
            </Dropdown.Link>
          </Dropdown>
        </>
      }
    >
      <ul aria-label="Group Members">
        {(courseSectionUser.instructors || [])
          .sort((a, b) => (a.user.name || '').localeCompare(b.user.name || ''))
          .map((member) => (
            <li key={`member-${member.userId}`}>
              <div className="member-entry">
                <Avatar user={member.user} size={40} />
                <span className="entry-name">
                  <Tippy content="Group Leader" interactive>
                    <span className="leader-icon">
                      <Icon code="star" label="Group Leader" />
                    </span>
                  </Tippy>
                  <span>{member.user.name + ` (${member.role === 'TEACHING ASSISTANT' ? 'TA' : member.role})`}</span>
                </span>
                <Dropdown iconCode="expand_more" align="left">
                  <Dropdown.Link
                    href="#"
                    onClick={() => {
                      modalDispatch(
                        openModal({
                          heading: 'Remove From Group',
                          label: `Are you sure you want to remove "${member.user.sortableName}" from "${courseSectionUser.courseSection.courseSectionName}"?`,
                          inputType: 'none',
                          buttonText: 'Remove',
                          onConfirm: () => {
                            requestRemoveUserFromSection(
                              courseSectionUser.courseSection.courseSectionId,
                              member.userId,
                            );
                          },
                        }),
                      );
                    }}
                  >
                    Remove
                  </Dropdown.Link>
                </Dropdown>
              </div>
            </li>
          ))}
      </ul>
      <ul aria-label="Group Members">
        {(courseSectionUser.students || [])
          .sort((a, b) => (a.user.name || '').localeCompare(b.user.name || ''))
          .map((member) => (
            <li key={`member-${member.userId}`}>
              <div className="member-entry">
                <Avatar user={member.user} size={40} />
                <span className="entry-name">
                  <span>{member.user.name}</span>
                </span>
                <Dropdown iconCode="expand_more" align="left">
                  <Dropdown.Link
                    href="#"
                    onClick={() => {
                      modalDispatch(
                        openModal({
                          heading: 'Remove From Group',
                          label: `Are you sure you want to remove "${member.user.sortableName}" from "${courseSectionUser.courseSection.courseSectionName}"?`,
                          inputType: 'none',
                          buttonText: 'Remove',
                          onConfirm: () => {
                            requestRemoveUserFromSection(
                              courseSectionUser.courseSection.courseSectionId,
                              member.userId,
                            );
                          },
                        }),
                      );
                    }}
                  >
                    Remove
                  </Dropdown.Link>
                </Dropdown>
              </div>
            </li>
          ))}
      </ul>
      <Button
        className="add-members-btn"
        href={`${basePath}/${courseSectionUser.courseSection.courseSectionId}/add`}
        route
      >
        <Icon code="add" ariaHidden />
        <span>Add Members</span>
      </Button>
    </Accordion>
  );
}

export default CourseSectionManagement;
