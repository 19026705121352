import Tippy from '@tippyjs/react';
import React from 'react';
import { openModal, useModalContext } from '../../../contexts/ModalContext';
import { handleKeySelect } from '../../../utils/functions';
import Icon from './Icon';

interface Props {
  children: React.ReactNode;
  emphasize?: boolean;
  heading?: string;
  margin?: string;
  type?: 'tooltip' | 'modal';
}

function HelpTag({
  children,
  emphasize = false,
  heading = 'Help',
  margin = '0.5rem',
  type = 'tooltip',
}: Props): JSX.Element {
  const style: React.CSSProperties = {
    margin: margin,
    opacity: emphasize ? 1 : 0.7,
    verticalAlign: 'middle',
    userSelect: 'none',
    cursor: 'pointer',
    color: emphasize ? 'white' : undefined,
    backgroundColor: emphasize ? '#2e74a3' : undefined,
    borderRadius: emphasize ? '50%' : undefined,
    padding: emphasize ? '4px' : undefined,
  };

  const { modalDispatch } = useModalContext();

  const handleModalSelect = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    modalDispatch(
      openModal({
        heading: heading,
        inputType: 'none',
        buttonText: 'Okay',
        closeButton: true,
        noActionButtons: true,
        maxWidth: '560px',
        children: children,
      }),
    );
  };

  switch (type) {
    case 'tooltip':
      return (
        <Tippy content={children} hideOnClick={false} interactive>
          <span tabIndex={0}>
            <Icon code="help_outline" style={style} label="Help" />
          </span>
        </Tippy>
      );
    case 'modal':
      return (
        <span
          role="button"
          tabIndex={0}
          onClick={handleModalSelect}
          onKeyDown={(e) => handleKeySelect(e, handleModalSelect)}
        >
          <Icon code="help_outline" style={style} label="Help" />
        </span>
      );
  }
}

export default HelpTag;
