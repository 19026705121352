import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CourseUser } from '../../types/types';
import { useLocation, useNavigate, useParams } from 'react-router';
import Avatar from '../core/display/Avatar/Avatar';
import Icon from '../core/display/Icon';
import Button from '../core/button/Button/Button';
import { addUsersToSection, getUnsectionedUsers } from '../../utils/requests';

function CourseSectionMemberAdd(): JSX.Element {
  const { courseId, sectionId } = useParams() as { courseId: string; sectionId: string };
  const [usersSelected, setUsersSelected] = useState<CourseUser[]>([]);
  const [unsectionedUsers, setUnsectionedUsers] = useState<CourseUser[]>([]);

  const location = useLocation();
  const navigate = useNavigate();

  const basePath = useMemo(() => {
    const matches = location.pathname.match(/(\S+\/section)/g);
    return (matches || [''])[0];
  }, [location]);

  useEffect(() => {
    getUnsectionedUsers(courseId, setUnsectionedUsers);
  }, [courseId]);

  const selectUser = useCallback((user: CourseUser) => {
    setUsersSelected((prevSelectedUsers) => {
      const newSelectedUsers = [...prevSelectedUsers, user];
      return newSelectedUsers;
    });
  }, []);

  const deselectUser = useCallback((user: CourseUser) => {
    setUsersSelected((prevSelectedUsers) => prevSelectedUsers.filter((currUser) => currUser.userId !== user.userId));
  }, []);

  const addUsers = useCallback(() => {
    const userIds = usersSelected.map((user) => user.userId);
    addUsersToSection(courseId, sectionId, userIds, () => {
      navigate(`${basePath}`);
    });
  }, [usersSelected, courseId, sectionId, navigate, basePath]);

  return (
    <div className="course-section-add-members">
      <div id="section-add-members">
        <h1>Add Members</h1>
        <p>Select students to add:</p>
        <div id="section-selected-heading">
          <button className="button-mini exit-btn" onClick={() => window.history.back()}>
            <Icon code="close" label="Cancel" />
          </button>
          <h2>{usersSelected.length} Selected</h2>
        </div>
        <p id="section-selected-names">
          {usersSelected
            .sort((a, b) => (a.user.name || '').localeCompare(b.user.name || ''))
            .map((courseUser, i) => {
              if (courseUser.user) {
                const includeComma = i < usersSelected.length - 1;
                return <span key={`user-${i}`}>{`${courseUser.user.name}${includeComma ? ', ' : ''}`}</span>;
              }
              return null;
            })}
        </p>
        <Button id="section-confirm-btn" variant="rad" onClick={addUsers}>
          Add to Section
        </Button>
        {unsectionedUsers.length > 0 ? (
          <div id="section-user-list" role="list" aria-label="Students without groups">
            {unsectionedUsers
              .sort((a, b) => (a.user.name || '').localeCompare(b.user.name || ''))
              .map((courseUser, i) => (
                <UserEntry
                  key={`user-${i}`}
                  courseUser={courseUser}
                  selectUser={selectUser}
                  deselectUser={deselectUser}
                />
              ))}
          </div>
        ) : (
          <p>No available students. All students are already in groups.</p>
        )}
      </div>
    </div>
  );
}

interface UserEntryProps {
  deselectUser: (courseUser: CourseUser) => void;
  selectUser: (courseUser: CourseUser) => void;
  courseUser: CourseUser;
}

function UserEntry({ deselectUser, selectUser, courseUser }: UserEntryProps): JSX.Element {
  return (
    <div className="user-entry" role="listitem">
      <input
        type="checkbox"
        id={`user-${courseUser.userId}`}
        name="new_members"
        value={courseUser.userId}
        onChange={(e) => {
          if (e.target.checked) selectUser(courseUser);
          else deselectUser(courseUser);
        }}
      />
      <label htmlFor={`user-${courseUser.userId}`}>
        <span className="avatar-wrapper" role="presentation">
          <Avatar user={courseUser.user} size={40} />
        </span>
        <span className="name">{courseUser.user.name}</span>
        <Icon className="check-indicator" code="check_circle" ariaHidden />
      </label>
    </div>
  );
}

export default CourseSectionMemberAdd;
