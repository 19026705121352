import React from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';

type Category = {
  id: string;
  href: string;
  name: string;
};

interface Props {
  categories: Category[];
  categoryToComponentMap: { [index: string]: React.ReactNode };
  root?: string;
  title?: string;
}

function CategoryMenu({ categories, categoryToComponentMap, root, title }: Props): JSX.Element {
  const location = useLocation();

  return (
    <div className="category-menu">
      {title ? <h1>{title}</h1> : null}

      <div className="menu-body" role="navigation">
        <div className="links">
          <ul>
            {categories.map((category) => {
              const fullPath = `${root ?? ''}${category.href}`;
              const isCurrent = location.pathname === fullPath;
              return (
                <li key={category.id} className={isCurrent ? 'selected' : undefined}>
                  <Link to={fullPath} aria-current={isCurrent}>
                    {category.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>

        <div className="content">
          <Routes>
            {categories.map((category) => (
              <Route key={category.id} path={category.href} element={categoryToComponentMap[category.id]} />
            ))}
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default CategoryMenu;
