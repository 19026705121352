import React, { useRef } from 'react';
import _ from 'lodash';
import Icon from '../../display/Icon';

interface Props {
  placeholder?: string;
  setValue: (arg0: string) => void;
  value: string;
}

function SearchBar({ placeholder, setValue, value }: Props): JSX.Element {
  const id = useRef(_.uniqueId('searchbar-'));

  const clearStyle = value !== '' ? { opacity: '1' } : undefined;

  return (
    <div className="searchbar">
      <label htmlFor={id.current} className="sr-only">
        Search Term
      </label>
      <input
        id={id.current}
        type="text"
        placeholder={placeholder || 'Search'}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <button
        className="clear-search"
        type="button"
        style={clearStyle}
        onClick={() => setValue('')}
        disabled={value === ''}
      >
        <Icon code="cancel" label="Clear search" />
      </button>
    </div>
  );
}

export default SearchBar;
