import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CellProps } from 'react-table';
import { AccessPermission, Assignment, AssignmentProgress } from '../../types/types';
import { formatDate, getPhaseStatus, PhaseStatus } from '../../utils/functions';
import Icon from '../core/display/Icon';
import Table, { CustomColumn, getCellWithIndicators, getCellWithUnit } from '../core/display/Table/Table';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface Props {
  assignment?: Assignment;
  includeAssignmentNames?: boolean;
  progressData: AssignmentProgress[];
  showAllColumns?: boolean;
  accessPermission?: AccessPermission;
}

type TableData = {
  name: string;
  groupName?: string;
  assignmentName: string;
  time?: string;
  reviewsGiven?: number;
  reviewsReceived?: number;
  feedbackGiven?: number;
  feedbackReceived?: number;
  evaluationsGiven?: number;
  evaluationsReceived?: number;
  userId: string;
  assignmentId: string;
  grade: number | string;
  instructorReviewReceived: boolean;
  reflectionCompletion: number | string;
};

function ProgressTable({
  assignment,
  includeAssignmentNames = false,
  progressData,
  showAllColumns = false,
  accessPermission,
}: Props): JSX.Element {
  const { courseId } = useParams() as { courseId: string };
  const user = useSelector((state: RootState) => state.user);

  const [tableData, setTableData] = useState<TableData[]>([]);
  const [tableColumns, setTableColumns] = useState<CustomColumn<TableData>[]>([]);

  const navigate = useNavigate();

  const handleRowSelect = useCallback(
    (userId: string, assignmentId: string) =>
      navigate(`/course/${courseId}/assignment/${assignmentId}/student/${userId}`),
    [courseId, navigate],
  );

  useEffect(() => {
    if (assignment) {
      const phaseStatus = getPhaseStatus(assignment);
      const columns: CustomColumn<TableData>[] = [{ Header: 'Name', accessor: 'name', className: 'left-align' }];
      if (assignment.groupsEnabled || showAllColumns) {
        columns.push({ Header: 'Group', accessor: 'groupName', className: 'left-align' });
      }
      if (phaseStatus.submission || showAllColumns) {
        columns.push({ Header: 'Submission Time', accessor: 'time', className: 'left-align' });
      }
      if (phaseStatus.review || showAllColumns || assignment.instructorGradedOnly) {
        let reviewSubColumns: CustomColumn<TableData>[] = [];
        if (!assignment.instructorGradedOnly) {
          reviewSubColumns = [{ Header: 'Given', accessor: 'reviewsGiven' }];
        }
        if (!assignment.instructorUpload || showAllColumns)
          reviewSubColumns.push({
            Header: 'Received',
            accessor: 'reviewsReceived',
            Cell: function render({ cell: { value, row } }: CellProps<TableData>) {
              if (value === -1) return '';
              if (row.original.instructorReviewReceived) {
                return getCellWithIndicators(value, [
                  <Icon
                    key="instructor-reviewed-icon"
                    className="instructor-reviewed-icon"
                    code="exposure_plus_1"
                    label="Reviewed by Instructor"
                    tooltip
                  />,
                ]);
              }
              return value;
            },
          });
        columns.push({
          Header: 'Reviews',
          columns: reviewSubColumns,
        });
      }
      if (phaseStatus.feedback || showAllColumns) {
        columns.push({
          Header: 'Feedback',
          columns: [
            { Header: 'Given', accessor: 'feedbackGiven' },
            { Header: 'Received', accessor: 'feedbackReceived' },
          ],
        });
      }
      if (phaseStatus.evaluate || showAllColumns) {
        columns.push({
          Header: 'Evaluations',
          columns: [
            { Header: 'Given', accessor: 'evaluationsGiven' },
            { Header: 'Received', accessor: 'evaluationsReceived' },
          ],
        });
      }
      if (phaseStatus.reflection || showAllColumns) {
        columns.push({ Header: 'Reflection', accessor: 'reflectionCompletion' });
      }
      if (accessPermission?.viewStudentGradePermission) {
        columns.push({
          Header: 'Grade',
          accessor: 'grade',
          Cell: function render({ cell: { value } }: CellProps<TableData>) {
            if (typeof value !== 'number') return value;
            return getCellWithUnit(value.toFixed(2), ' %');
          },
        });
      }

      if (includeAssignmentNames) {
        columns.splice(2, 0, { Header: 'Assignment', accessor: 'assignmentName', className: 'left-align long-value' });
      }
      setTableColumns(columns);
    }
  }, [
    assignment,
    progressData,
    includeAssignmentNames,
    showAllColumns,
    user.role,
    accessPermission?.viewStudentGradePermission,
  ]);

  useEffect(() => {
    const assignmentPhaseStatusMap: { [index: string]: PhaseStatus } = {};
    const dataTable: TableData[] = [];
    progressData.forEach((progress) => {
      const { grade, asyncResult } = progress.result || {};

      let gradeForRow: number | string = 'N/A';
      if (grade) gradeForRow = grade.overallGrade;
      else if (asyncResult) gradeForRow = asyncResult.generatedGrade;

      const { assignment } = progress;
      const phaseStatus =
        assignmentPhaseStatusMap[assignment.assignmentId ?? ''] ?? getPhaseStatus(progress.assignment);
      assignmentPhaseStatusMap[assignment.assignmentId ?? ''] = phaseStatus;
      const newRow: TableData = {
        name: progress.user.sortableName,
        groupName: assignment.groupsEnabled ? (progress.group ? progress.group.groupName : 'N/A') : undefined,
        assignmentName: progress.assignment.assignmentName,
        time: phaseStatus.submission
          ? progress.submissionInfo
            ? formatDate(progress.submissionInfo.createdAt)
            : 'N/A'
          : undefined,
        reviewsGiven: phaseStatus.review ? progress.reviewsCompleted : undefined,
        reviewsReceived: phaseStatus.review || assignment.instructorGradedOnly ? progress.reviewsReceived : -1,
        feedbackGiven: phaseStatus.feedback ? progress.feedbackCompleted : undefined,
        feedbackReceived: phaseStatus.feedback ? progress.feedbackReceived : undefined,
        evaluationsGiven: progress.countEvaluationsComplete,
        evaluationsReceived: progress.countEvaluationsReceived,
        userId: progress.user.userId,
        assignmentId: progress.assignment.assignmentId ? progress.assignment.assignmentId : '',
        reflectionCompletion: phaseStatus.reflection ? (progress.hasReflected ? `Complete` : `Incomplete`) : '',
        grade: gradeForRow,
        instructorReviewReceived: progress.instructorReviewReceived !== null,
      };

      dataTable.push(newRow);
    });

    setTableData(dataTable);
  }, [progressData]);

  return (
    <Table
      columns={tableColumns}
      data={tableData}
      sortBy="name"
      title={`Assignment Progress - ${assignment ? assignment.assignmentName : 'All'}`}
      headingLevel={1}
      id="assignment-progress-card"
      defaultPageSize={50}
      informOfRow={(row) => handleRowSelect(row.original.userId, row.original.assignmentId)}
      hideDownload={accessPermission?.downloadResultPermission ? false : true}
    />
  );
}

export default ProgressTable;
