import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Assignment, Course } from '../../types/types';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import TeacherDashboard from './teacher/TeacherDashboard';
import { getPhaseTimeLeft, setPageTitle } from '../../utils/functions';
import { selectAssignment, selectCourse } from '../../store/selectors';
import StudentDashboard from './student/StudentDashboard';

export interface DashboardProps {
  assignment?: Assignment;
  course?: Course;
  timeRemaining: string;
}

function DashboardPage(): JSX.Element {
  useEffect(() => setPageTitle('Assignment Dashboard'), []);

  const course = useSelector(selectCourse);
  const assignment = useSelector(selectAssignment);
  const [timeRemaining, setTimeRemaining] = useState('');

  const user = useSelector((state: RootState) => state.user);
  const { courseRole } = user;

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (assignment) {
      const updateTimeRemaining = () => {
        setTimeRemaining(getPhaseTimeLeft(assignment).slice(0, 2).join(', '));
      };
      updateTimeRemaining();
      intervalId = setInterval(updateTimeRemaining, 60000);
    }
    return () => clearInterval(intervalId);
  }, [assignment]);

  if (user.userId !== '' && assignment && course) {
    switch (courseRole) {
      case 'STUDENT':
        return <StudentDashboard assignment={assignment} course={course} timeRemaining={timeRemaining} />;
      default:
        return <TeacherDashboard assignment={assignment} course={course} timeRemaining={timeRemaining} />;
    }
  }

  return <LoadingSpinner />;
}

export default DashboardPage;
