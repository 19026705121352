import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { openModal, useModalContext } from '../../../../contexts/ModalContext';
import Button from '../../button/Button/Button';
import Icon from '../../display/Icon';

interface Props {
  children: React.ReactNode;
  className?: string;
  backButton?: boolean;
  backUrl?: string;
  buttonPosition?: 'top' | 'bottom';
  customCtrls?: React.ReactNode;
  editable?: boolean;
  noWrapper?: boolean;
  onEdit?: (arg0: boolean) => void;
  onSubmit?: (arg0: FormData, callback: () => void) => void;
  submitText: string;
  title: string;
}

function SingleForm({
  children,
  className,
  backButton,
  backUrl,
  buttonPosition = 'bottom',
  customCtrls,
  editable = false,
  noWrapper = false,
  onEdit = () => undefined,
  onSubmit = () => undefined,
  submitText,
  title,
}: Props): JSX.Element {
  const formEl = useRef<HTMLFormElement>(null);

  const [editing, setEditing] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const { modalDispatch } = useModalContext();
  const navigate = useNavigate();

  const getSubmitButton = (className: string) => {
    if (editable) {
      if (editing) {
        return (
          <>
            <Button
              className={className}
              variant="alt"
              type="button"
              onClick={() => {
                setEditing(false);
                onEdit(false);
              }}
            >
              Cancel
            </Button>
            <Button type="submit" className={className}>
              {submitText}
            </Button>
          </>
        );
      } else {
        return (
          <Button
            className={className}
            type="button"
            onClick={() => {
              setEditing(true);
              onEdit(true);
            }}
          >
            Edit
          </Button>
        );
      }
    }
    return (
      <Button type="submit" className={className} disabled={submitting}>
        {submitText}
      </Button>
    );
  };

  return (
    <div className={`single-form${noWrapper ? '' : ' panel'} ${className}`}>
      <form
        ref={formEl}
        onSubmit={(e) => {
          e.preventDefault();
          setSubmitting(true);
          if (formEl.current) {
            document.body.classList.remove('no-scroll');
            const formData = new FormData(formEl.current);
            onSubmit(formData, () => setSubmitting(false));
            setEditing(false);
            onEdit(false);
          }
        }}
        onChange={() => {
          if (!changesMade) setChangesMade(true);
        }}
      >
        <div className={`header-wrapper ${noWrapper ? 'sr-only' : ''}`}>
          <span className="title-content">
            {backButton ? (
              <button
                className="button-mini exit-btn"
                type="button"
                onClick={() => {
                  if (editable ? changesMade && editing : changesMade) {
                    modalDispatch(
                      openModal({
                        heading: 'Discard Changes?',
                        label: 'Your changes have not been saved.',
                        inputType: 'none',
                        buttonText: 'Discard',
                        onConfirm: () => {
                          window.history.back();
                        },
                      }),
                    );
                  } else {
                    if (backUrl) navigate(backUrl);
                    else window.history.back();
                  }
                }}
              >
                <Icon code="close" />
              </button>
            ) : null}
            <h1 style={!backButton ? { textAlign: 'center' } : undefined}>{title}</h1>
          </span>

          {buttonPosition === 'top' ? getSubmitButton('top-btn') : null}

          {customCtrls ? <span className="custom-ctrls-wrapper">{customCtrls}</span> : null}
        </div>

        {children}
        {buttonPosition === 'bottom' ? getSubmitButton('bottom-btn') : null}
      </form>
    </div>
  );
}

function FormGroup({ children }: { children: React.ReactNode }) {
  return <div className="form-group">{children}</div>;
}

function InputGroup({
  children,
  flexDirection,
  iconCode,
}: {
  children: React.ReactNode;
  flexDirection?: 'row' | 'column';
  iconCode: string;
}) {
  let classes = 'inputs-container-row';
  if (flexDirection === 'column') classes = 'inputs-container-col';
  return (
    <div className="input-group">
      <Icon code={iconCode} ariaHidden />
      <div className={classes}>{children}</div>
    </div>
  );
}

function Row({ children }: { children: React.ReactNode }): JSX.Element {
  return <div className="peer-form-row">{children}</div>;
}

function Col({
  children,
  className,
  flexBasis,
}: {
  children: React.ReactNode;
  className?: string;
  flexBasis?: string;
}): JSX.Element {
  return (
    <div style={{ flexBasis: flexBasis }} className={`peer-form-col${className ? ` ${className}` : ''}`}>
      {children}
    </div>
  );
}

SingleForm.FormGroup = FormGroup;
SingleForm.InputGroup = InputGroup;
SingleForm.Col = Col;
SingleForm.Row = Row;

export default SingleForm;
