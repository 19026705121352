import Tippy from '@tippyjs/react';
import React from 'react';
import Icon from '../Icon';
import PieChart from '../PieChart/PieChart';

interface Props {
  children?: React.ReactNode;
  color: string;
  iconCode?: string;
  percent: number;
  r?: number;
  tooltip?: string;
}

function ProgressIcon({ children, color, iconCode, percent, r = 24, tooltip }: Props): JSX.Element {
  return (
    <Tippy content={tooltip} disabled={!tooltip} interactive>
      <div className="progress-icon" role="img" aria-label={`Progress ${Math.round(percent)}%`}>
        <PieChart r={r} color={color}>
          <PieChart.Slice percent={percent} color={color} />
        </PieChart>
        <div className="icon-wrapper">{iconCode ? <Icon code={iconCode} ariaHidden /> : children}</div>
      </div>
    </Tippy>
  );
}

export default ProgressIcon;
