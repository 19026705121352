import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import logoSrc from '../../assets/peerceptiv_logo_black_text.png';
import { activateUserAccount, verifyUserEmail } from '../../utils/requests';
import Icon from '../core/display/Icon';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';

function VerifyPage(): JSX.Element {
  const { userId, verificationCode, activationCode } = useParams() as {
    userId: string;
    verificationCode?: string;
    activationCode?: string;
  };

  const [status, setStatus] = useState<'LOADING' | 'SUCCESS' | 'FAIL'>('LOADING');

  useEffect(() => {
    if (verificationCode)
      verifyUserEmail(
        userId,
        verificationCode,
        () => {
          setStatus('SUCCESS');
        },
        () => {
          setStatus('FAIL');
          return true;
        },
      );
    else if (activationCode)
      activateUserAccount(
        userId,
        activationCode,
        () => {
          setStatus('SUCCESS');
        },
        () => {
          setStatus('FAIL');
          return true;
        },
      );
  }, [activationCode, userId, verificationCode]);

  useEffect(() => {
    if (status === 'SUCCESS') {
      const timer = setTimeout(() => window.location.replace(`/course/dashboard`), 1600);
      return () => clearTimeout(timer);
    }
  }, [status]);

  if (status === 'LOADING') return <LoadingSpinner />;
  else if (status === 'FAIL')
    return (
      <div className="page" id="email-verify-page">
        <img src={logoSrc} width="256" alt="Peerceptiv logo" />
        <div id="prompt">
          <Icon code="close" id="error-icon" ariaHidden />
          {verificationCode ? (
            <>
              <h1>Verification Failed</h1>
              <p>Your verification link is either expired or invalid. Please try again.</p>
            </>
          ) : null}
          {activationCode ? (
            <>
              <h1>Activation Failed</h1>
              <p>Your activation link is either expired or invalid. Please try again.</p>
            </>
          ) : null}
        </div>
      </div>
    );

  return (
    <div className="page" id="email-verify-page">
      <img src={logoSrc} width="256" alt="Peerceptiv logo" />
      <div id="prompt">
        <Icon code="done" id="done-icon" ariaHidden />
        {verificationCode ? (
          <>
            <h1>Email Verified</h1>
            <p>You may now proceed to your account. You will be redirected shortly.</p>
          </>
        ) : null}
        {activationCode ? (
          <>
            <h1>Account Activated</h1>
            <p>You may now create a password. You will be redirected shortly.</p>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default VerifyPage;
