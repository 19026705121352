import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import _ from 'lodash';
import { postContent, deleteContent } from '../../../../utils/requests';

import {
  EditorState,
  ContentState,
  convertFromRaw,
  convertToRaw,
  convertFromHTML,
  getDefaultKeyBinding,
  RichUtils,
  EntityInstance,
} from 'draft-js';

import Editor, { composeDecorators } from '@draft-js-plugins/editor';
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  UnorderedListButton,
  OrderedListButton,
} from '@draft-js-plugins/buttons';
import createLinkPlugin from '@draft-js-plugins/anchor';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import createImagePlugin from '@draft-js-plugins/image';
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import createFocusPlugin from '@draft-js-plugins/focus';
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop';

import { outsideClick, isJSON } from '../../../../utils/functions';

import Tex2SVG from 'react-hook-mathjax';
import Button from '../../button/Button/Button';
import { createLinkDecorator, onAddLink } from './ToolbarLink';
import { API_URL } from '../../../../utils/constants';
import Icon from '../../display/Icon';
import Tippy from '@tippyjs/react';
import FocusTrap from 'focus-trap-react';
import TabList from '../../layout/TabList/TabList';

const CULL_REMOVED_CONTENT_WAIT = 5000;
const CULL_REMOVED_CONTENT_MAX_WAIT = 5000;

const toolbarPlugin = createToolbarPlugin({
  theme: {
    buttonStyles: {
      active: 'active',
      button: 'rich-ctrl-btn',
      buttonWrapper: 'rich-ctrl-btn-wrapper',
    },
    toolbarStyles: { toolbar: 'rich-toolbar' },
  },
});

const focusPlugin = createFocusPlugin({
  theme: { unfocused: 'unfocused', focused: 'focused' },
});
const resizeablePlugin = createResizeablePlugin({
  horizontal: 'absolute',
  vertical: 'absolute',
  initialWidth: 'auto',
});
const blockDndPlugin = createBlockDndPlugin();

const linkDecorator = createLinkDecorator();

const decorator = composeDecorators(resizeablePlugin.decorator, focusPlugin.decorator, blockDndPlugin.decorator);

const linkPlugin = createLinkPlugin({ linkTarget: '_blank' });
const linkifyPlugin = createLinkifyPlugin({ target: '_blank' });
const imagePlugin = createImagePlugin({ decorator });

const { Toolbar } = toolbarPlugin;
const plugins = [toolbarPlugin, linkPlugin, linkifyPlugin, imagePlugin, resizeablePlugin, focusPlugin, blockDndPlugin];

interface RichEditorProps {
  initContent?: string;
  isEmpty?: (e: boolean) => void;
  onChange: (arg0: string) => void;
}

function RichEditor({ initContent, isEmpty = () => undefined, onChange }: RichEditorProps): JSX.Element {
  const [editorState, setEditorState] = useState<EditorState>(() => {
    if (initContent) {
      const content = validateAndParseRichInput(initContent);
      return EditorState.createWithContent(content, linkDecorator);
    } else return EditorState.createEmpty();
  });

  const [includedContent, setIncludedContent] = useState<string[]>([]);

  const parentEl = useRef<HTMLDivElement>(null);
  const editorEl = useRef<Editor | null>(null);

  const addIncludedContent = useCallback((contentId: string) => {
    setIncludedContent((prevContent) => [...prevContent, contentId]);
  }, []);

  const deleteIncludedContent = useCallback((contentId: string) => {
    setIncludedContent((prevContent) => {
      const newContent = [...prevContent];
      const i = newContent.indexOf(contentId);
      newContent.splice(i, 1);
      return newContent;
    });
  }, []);

  const handleChange = useCallback(
    (editorState: EditorState) => {
      if (onChange) {
        const contentState = editorState.getCurrentContent();
        onChange(JSON.stringify(convertToRaw(contentState)));
        setEditorState(editorState);
      }
    },
    [onChange],
  );

  const keyBinding = (e: React.KeyboardEvent) => {
    if (e.key === 'b' && e.ctrlKey && !e.shiftKey) {
      return 'bold';
    } else if (e.key === 'i' && e.ctrlKey && !e.shiftKey) {
      return 'italic';
    } else if (e.key === 'u' && e.ctrlKey && !e.shiftKey) {
      return 'underline';
    } else if (e.key === 'B' && e.ctrlKey && e.shiftKey) {
      return 'bullet-list';
    } else if (e.key === 'O' && e.ctrlKey && e.shiftKey) {
      return 'number-list';
    }
    return getDefaultKeyBinding(e);
  };

  const handleKeyCommand = (command: string) => {
    switch (command) {
      case 'bold':
        setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
        return 'handled';
      case 'italic':
        setEditorState(RichUtils.toggleInlineStyle(editorState, 'ITALIC'));
        return 'handled';
      case 'underline':
        setEditorState(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'));
        return 'handled';
      case 'bullet-list':
        setEditorState(RichUtils.toggleBlockType(editorState, 'unordered-list-item'));
        return 'handled';
      case 'number-list':
        setEditorState(RichUtils.toggleBlockType(editorState, 'ordered-list-item'));
        return 'handled';
    }
    return 'not-handled';
  };

  useEffect(() => {
    isEmpty(!editorState.getCurrentContent().hasText());
  }, [editorState, isEmpty]);

  interface EntityItem {
    entityKey: string;
    blockKey: string;
    entity: Draft.EntityInstance;
  }
  interface EntityIndex extends EntityItem {
    start: number;
    end: number;
  }
  const cullRemovedContent = useMemo(
    () =>
      _.debounce(
        (editorState: EditorState, includedContent: string[], deleteIncludedContent: (arg0: string) => void) => {
          const getEntities = (editorState: EditorState, entityType = null) => {
            const content = editorState.getCurrentContent();
            const entities = [] as EntityIndex[];
            content.getBlocksAsArray().forEach((block) => {
              let selectedEntity = null as EntityItem | null;
              block.findEntityRanges(
                (character) => {
                  if (character.getEntity() !== null) {
                    const entity = content.getEntity(character.getEntity());
                    if (!entityType || (entityType && entity.getType() === entityType)) {
                      selectedEntity = {
                        entityKey: character.getEntity(),
                        blockKey: block.getKey(),
                        entity: content.getEntity(character.getEntity()),
                      };
                      return true;
                    }
                  }
                  return false;
                },
                (start, end) => {
                  if (selectedEntity)
                    entities.push({
                      entityKey: selectedEntity.entityKey,
                      blockKey: selectedEntity.blockKey,
                      entity: selectedEntity.entity,
                      start,
                      end,
                    });
                },
              );
            });
            return entities;
          };

          // Initialize inclusion map memoization data structure
          const inclusionMap: Record<string, boolean> = {};
          includedContent.forEach((contentId) => {
            inclusionMap[contentId] = false; // Initialize as not included
          });

          const entities = getEntities(editorState);
          entities.forEach((e) => {
            const entity = e.entity as EntityInstance & {
              _map: { _root: { entries: { src: string }[][] } };
            };
            const src = entity._map._root.entries[2][1].src;
            if (src) {
              const path = '/content/';
              const i = src.indexOf(path) + path.length;
              const id = src.substring(i);
              inclusionMap[id] = true; // Set included
            }
          });

          // Cull any content no longer included from server
          for (const id in inclusionMap) {
            if (Object.prototype.hasOwnProperty.call(inclusionMap, id)) {
              if (inclusionMap[id] === false) {
                deleteContent(id);
                deleteIncludedContent(id);
              }
            }
          }
        },
        CULL_REMOVED_CONTENT_WAIT,
        {
          maxWait: CULL_REMOVED_CONTENT_MAX_WAIT,
        },
      ),
    [],
  );

  useEffect(() => {
    cullRemovedContent(editorState, includedContent, deleteIncludedContent);
  }, [editorState, includedContent, deleteIncludedContent, cullRemovedContent]);

  function TooltipWrap({ children, content }: { children: React.ReactNode; content: string }): JSX.Element {
    return (
      <Tippy content={content} interactive>
        <span>{children}</span>
      </Tippy>
    );
  }

  useEffect(() => {
    if (parentEl.current) {
      const editorTextbox = parentEl.current.querySelector('.public-DraftEditor-content');
      if (editorTextbox) editorTextbox.setAttribute('aria-label', 'Rich Text Editor Textbox');
    }
  }, []);

  return (
    <div className="rich-editor" ref={parentEl}>
      {editorEl.current !== null ? (
        <Toolbar>
          {(externalProps) => (
            <>
              <TooltipWrap content="Bold (Ctrl+B)">
                <BoldButton {...externalProps} buttonProps={{ 'aria-label': 'Bold (Ctrl+B)' }} />
              </TooltipWrap>
              <TooltipWrap content="Italic (Ctrl+I)">
                <ItalicButton {...externalProps} buttonProps={{ 'aria-label': 'Italic (Ctrl+I)' }} />
              </TooltipWrap>
              <TooltipWrap content="Underline (Ctrl+U)">
                <UnderlineButton {...externalProps} buttonProps={{ 'aria-label': 'Underline (Ctrl+U)' }} />
              </TooltipWrap>
              <span className="controls-separator" />
              <TooltipWrap content="Bulleted list (Ctrl+Shift+B)">
                <UnorderedListButton
                  {...externalProps}
                  buttonProps={{ 'aria-label': 'Bulleted list (Ctrl+Shift+B)' }}
                />
              </TooltipWrap>
              <TooltipWrap content="Numbered list (Ctrl+Shift+O)">
                <OrderedListButton {...externalProps} buttonProps={{ 'aria-label': 'Numbered list (Ctrl+Shift+O)' }} />
              </TooltipWrap>
              <span className="controls-separator" />
              <LinkButton editorState={editorState} onChange={handleChange} />
              <ImageButton
                editorState={editorState}
                onChange={setEditorState}
                modifier={imagePlugin.addImage}
                addIncludedContent={addIncludedContent}
              />
              <LatexButton
                editorState={editorState}
                onChange={setEditorState}
                modifier={imagePlugin.addImage}
                addIncludedContent={addIncludedContent}
              />
            </>
          )}
        </Toolbar>
      ) : null}
      <Editor
        editorState={editorState}
        onChange={handleChange}
        plugins={plugins}
        handleKeyCommand={handleKeyCommand}
        keyBindingFn={keyBinding}
        ref={(element) => (editorEl.current = element)}
      />
    </div>
  );
}

interface ToolbarButtonProps {
  editorState: EditorState;
  onChange: (arg0: EditorState) => void;
}

function LinkButton({ editorState, onChange }: ToolbarButtonProps): JSX.Element {
  const modalEl = useRef<HTMLDivElement>(null);

  const [show, setShow] = useState(false);
  const [linkUrl, setLinkUrl] = useState('');
  const [linkText, setLinkText] = useState('');

  useEffect(() => {
    if (modalEl) {
      const handleMouseDown = (e: MouseEvent) => {
        if (modalEl.current && outsideClick(e, [modalEl.current])) {
          setShow(false);
        }
      };
      window.addEventListener('mousedown', handleMouseDown);
      return () => window.removeEventListener('mousedown', handleMouseDown);
    }
  }, []);

  return (
    <div className="rich-ctrl-btn-wrapper">
      <Button
        className="rich-ctrl-btn"
        classOverride
        type="button"
        onClick={() => setShow(true)}
        ariaLabel="Insert link"
        tooltip="Insert Link"
      >
        <Icon code="link" />
      </Button>
      {show ? (
        <FocusTrap>
          <div
            ref={modalEl}
            className="editor-menu-modal panel-sm neumorphic-lite"
            onKeyDown={(e) => {
              if (e.key === 'Escape') setShow(false);
            }}
          >
            <label htmlFor="linkUrl">Link URL</label>
            <input
              id="linkUrl"
              name="linkUrl"
              type="url"
              value={linkUrl}
              onChange={(e) => setLinkUrl(e.target.value)}
            />
            <label htmlFor="linkText">Link Text (Optional)</label>
            <input
              id="linkText"
              name="linkText"
              type="text"
              value={linkText}
              onChange={(e) => setLinkText(e.target.value)}
            />
            <div className="modal-btns">
              <Button
                type="button"
                onClick={() => {
                  const valid = (document.getElementById('linkUrl') as HTMLInputElement).reportValidity();
                  if (valid) {
                    onAddLink(editorState, onChange, linkUrl, linkText.length > 0 ? linkText : linkUrl);
                    setShow(false);
                  }
                }}
              >
                Add
              </Button>
              <button className="button-alt button-sm" type="button" onClick={() => setShow(false)}>
                Cancel
              </button>
            </div>
          </div>
        </FocusTrap>
      ) : null}
    </div>
  );
}

interface ImageToolbarButtonProps extends ToolbarButtonProps {
  addIncludedContent: (arg0: string) => void;
  modifier: (arg0: EditorState, arg1: string, arg2: Record<string, unknown>) => EditorState;
}
function ImageButton({ addIncludedContent, editorState, onChange, modifier }: ImageToolbarButtonProps): JSX.Element {
  const [show, setShow] = useState(false);
  const textInputEl = useRef<HTMLInputElement>(null);
  const fileInputEl = useRef<HTMLInputElement>(null);
  const imgPreviewEl = useRef<HTMLImageElement>(null);
  const modalEl = useRef<HTMLDivElement>(null);
  const uniqueId = useRef(_.uniqueId());

  const previewImage = () => {
    if (fileInputEl.current) {
      const files = fileInputEl.current.files;
      if (files) {
        const file = files.length > 0 ? files[0] : null;
        if (file) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function () {
            if (imgPreviewEl.current) {
              if (reader.result) imgPreviewEl.current.src = reader.result as string;
              imgPreviewEl.current.style.display = 'block';
              const prevNode = imgPreviewEl.current.previousSibling as HTMLElement;
              if (prevNode) prevNode.style.display = 'none';
              const nextNode = imgPreviewEl.current.nextSibling as HTMLElement;
              if (nextNode) nextNode.innerHTML = file.name;
            }
          };
          reader.onerror = function (error) {
            console.error('Error: ', error);
          };
        }
      }
    }
  };

  useEffect(() => {
    if (modalEl) {
      const handleMouseDown = (e: MouseEvent) => {
        if (modalEl.current && outsideClick(e, [modalEl.current])) {
          setShow(false);
        }
      };
      window.addEventListener('mousedown', handleMouseDown);
      return () => window.removeEventListener('mousedown', handleMouseDown);
    }
  }, []);

  return (
    <div className="rich-ctrl-btn-wrapper">
      <Button
        className="rich-ctrl-btn"
        classOverride
        type="button"
        onClick={() => setShow(true)}
        ariaLabel="Insert Image"
        tooltip="Insert Image"
      >
        <Icon code="insert_photo" />
      </Button>
      {show ? (
        <FocusTrap>
          <div
            ref={modalEl}
            className="editor-menu-modal panel-sm neumorphic-lite"
            onKeyDown={(e) => {
              if (e.key === 'Escape') setShow(false);
            }}
          >
            <Button className="sr-only" classOverride onClick={() => setShow(false)}>
              <Icon code="close" />
            </Button>
            <TabList
              mini
              label="Purchase Management Menu"
              tabs={
                <>
                  <TabList.Tab id="upload-option" controls="upload-option-tab">
                    Upload
                  </TabList.Tab>
                  <TabList.Tab id="url-option" controls="url-option-tab">
                    URL
                  </TabList.Tab>
                </>
              }
            >
              <TabList.TabPanel id="upload-option-tab" labeledBy="upload-option">
                <div className="img-upload-wrapper">
                  <input
                    id={`img-upload-input-${uniqueId}`}
                    ref={fileInputEl}
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={previewImage}
                  />
                  <label htmlFor={`img-upload-input-${uniqueId}`} className="upload-btn button-sm button-low">
                    <Icon code="upload" ariaHidden /> Choose a file
                  </label>
                </div>
                <figure>
                  <div className="image-preview">Image Preview</div>
                  <img
                    ref={imgPreviewEl}
                    src=""
                    title="Upload Preview"
                    alt="Upload Preview"
                    style={{
                      display: 'none',
                    }}
                  />
                  <figcaption></figcaption>
                </figure>
                <div className="modal-btns">
                  <button
                    className="button-sm"
                    type="button"
                    onClick={() => {
                      if (fileInputEl.current) {
                        const files = fileInputEl.current.files;
                        if (files && files.length > 0) {
                          const file = files[0];
                          const formData = new FormData();
                          formData.append('content', file);

                          postContent(formData, (id: string) => {
                            onChange(modifier(editorState, `${API_URL}/content/${id}`, {}));
                            addIncludedContent(id);
                          });
                        }
                      }
                      setShow(false);
                    }}
                  >
                    Add
                  </button>
                  <button className="button-alt button-sm" type="button" onClick={() => setShow(false)}>
                    Cancel
                  </button>
                </div>
              </TabList.TabPanel>
              <TabList.TabPanel id="url-option-tab" labeledBy="url-option">
                <label>
                  Image URL:
                  <input ref={textInputEl} type="text" placeholder={'Enter an image URL'} />
                </label>
                <div className="modal-btns">
                  <button
                    className="button-sm"
                    type="button"
                    onClick={() => {
                      if (textInputEl.current) onChange(modifier(editorState, textInputEl.current.value, {}));
                      setShow(false);
                    }}
                  >
                    Add
                  </button>
                  <button className="button-alt button-sm" type="button" onClick={() => setShow(false)}>
                    Cancel
                  </button>
                </div>
              </TabList.TabPanel>
            </TabList>
          </div>
        </FocusTrap>
      ) : null}
    </div>
  );
}

function LatexButton({ addIncludedContent, editorState, onChange, modifier }: ImageToolbarButtonProps): JSX.Element {
  const [show, setShow] = useState(false);
  const [inputValue, setInputValue] = useState('G_{\\mu\\nu} + \\Lambda g_{\\mu\\nu} = \\kappa T_{\\mu\\nu}');
  const [lastValidInput, setLastValidInput] = useState('');
  const [error, setError] = useState<string | null>(null);
  const hasError = error !== null;

  const modalEl = useRef<HTMLDivElement>(null);
  const inputEl = useRef<HTMLTextAreaElement>(null);
  const texContainerEl = useRef<HTMLDivElement>(null);

  const getErrorFromHTML = useCallback(
    (html: HTMLElement) =>
      (
        (html.children[1]?.firstChild?.firstChild as HTMLElement | undefined)?.attributes[
          'data-mjx-error' as keyof NamedNodeMap
        ] as Attr
      ).value,
    [],
  );

  useEffect(() => {
    if (modalEl) {
      const handleMouseDown = (e: MouseEvent) => {
        if (modalEl.current && outsideClick(e, [modalEl.current])) {
          setShow(false);
        }
      };
      window.addEventListener('mousedown', handleMouseDown);
      return () => window.removeEventListener('mousedown', handleMouseDown);
    }
  }, []);

  return (
    <div className="rich-ctrl-btn-wrapper">
      <Button
        className="rich-ctrl-btn"
        classOverride
        type="button"
        onClick={() => setShow(true)}
        ariaLabel="Insert LaTeX Expression"
        tooltip="Insert LaTeX"
      >
        <Icon code="functions" />
      </Button>
      {show ? (
        <FocusTrap>
          <div
            ref={modalEl}
            className="editor-menu-modal panel-sm neumorphic-lite"
            onKeyDown={(e) => {
              if (e.key === 'Escape') setShow(false);
            }}
          >
            <label>
              Enter an expression:
              <textarea
                className={`${hasError ? 'error' : ''}`}
                ref={inputEl}
                defaultValue={inputValue}
                onChange={(e) => {
                  setInputValue(e.target.value);
                  setError(null);
                }}
              />
            </label>
            <label>
              Output:
              <div ref={texContainerEl} className="tex-container">
                <Tex2SVG
                  class="tex"
                  tabindex={-1}
                  latex={hasError ? lastValidInput : inputValue}
                  onSuccess={() => setLastValidInput(hasError ? lastValidInput : inputValue)}
                  onError={(html) => setError(getErrorFromHTML(html))}
                />
              </div>
              {hasError && <div className="error-msg">hint: {error}</div>}
            </label>
            <div className="modal-btns">
              <button
                className="button-sm"
                type="button"
                onClick={() => {
                  if (texContainerEl.current) {
                    const svg = texContainerEl.current.querySelectorAll('svg')[0];
                    const blob = new Blob([svg.outerHTML], {
                      type: 'image/svg+xml',
                    });
                    const file = new File([blob], 'latex.svg', {
                      type: 'image/svg+xml',
                    });
                    const formData = new FormData();
                    formData.append('content', file);

                    postContent(formData, (id: string) => {
                      onChange(modifier(editorState, `${API_URL}/content/${id}`, {}));
                      addIncludedContent(id);
                    });
                  }

                  setShow(false);
                }}
              >
                Add
              </button>
              <button className="button-alt button-sm" type="button" onClick={() => setShow(false)}>
                Cancel
              </button>
            </div>
          </div>
        </FocusTrap>
      ) : null}
    </div>
  );
}

export const validateAndParseRichInput = (contentString: string): ContentState => {
  // Check to see if the string is a valid JSON
  if (isJSON(contentString)) {
    return convertFromRaw(JSON.parse(contentString));
  }

  // If it's not a valid JSON string, treat as plaintext and
  // convert as if it's HTML
  const blocksFromHTML = convertFromHTML(contentString);
  return ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
};

export default RichEditor;
