import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { EvaluationByItem } from '../../../types/types';
import { getEvalTargetFormatted } from '../../../utils/functions';
import { getEvalItemsByPeer } from '../../../utils/requests';
import JumpButton from '../../core/button/JumpButton';
import DeleteEvalButton from './DeleteEvalButton';
import RatingDetailsTable from './RatingDetailsTable';
import { getCompletionStatus, getJumpButtons, StudentDetailsTabProps } from './StudentDetailsPage';
import ReviewDialogue from '../../core/display/ReviewDialogue/ReviewDialogue';

function EvalsReceivedDetails({ assignmentProgress, updateData, updateKey }: StudentDetailsTabProps): JSX.Element {
  const { assignmentId, userId } = useParams() as { assignmentId: string; userId: string };

  const [evalsReceived, setEvalsReceived] = useState<EvaluationByItem[] | null>(null);

  useEffect(
    () => getEvalItemsByPeer(assignmentId, userId, setEvalsReceived),
    [updateKey, assignmentProgress, assignmentId, userId],
  );

  if (evalsReceived)
    return (
      <>
        {evalsReceived.length > 0 ? (
          <div id="eval-evaluators-table" className="panel-sm panel-white">
            <h2 className="title" tabIndex={-1}>
              Evaluators
            </h2>
            <table>
              <tbody>
                {evalsReceived.map((peerEvalItem) => (
                  <tr key={`row-${peerEvalItem.peerEvalId}`}>
                    <th>
                      <b>{peerEvalItem.user.name}</b>
                    </th>
                    <td>{getEvalTargetFormatted(peerEvalItem.targetRole)}</td>
                    <td>{getCompletionStatus(peerEvalItem.complete)}</td>
                    <td>{getJumpButtons(`eval-received-${peerEvalItem.peerEvalId}`, 'Jump to Evaluation')}</td>
                    <td>
                      <DeleteEvalButton peerEvaluationId={peerEvalItem.peerEvalId} updateData={updateData} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="panel-sm panel-white no-progress-message">
            <h2 className="title">No Team Member Evaluations Received Yet</h2>
            <div>
              <p>
                <b>{assignmentProgress.user.name}</b> has not yet received team member evaluations for this assignment.
              </p>
              <p>More details will be available once they have received team member evaluations.</p>
            </div>
          </div>
        )}

        {evalsReceived.map((peerEvalItem) => (
          <div
            key={`eval-received-${peerEvalItem.peerEvalId}`}
            id={`eval-received-${peerEvalItem.peerEvalId}`}
            className="panel-sm panel-white eval-wrapper"
          >
            <JumpButton type="focus-child" targetId="eval-evaluators-table" className="sr-only">
              Back to evaluators table
            </JumpButton>
            <h2 className="title">Evaluator: {peerEvalItem.user.name}</h2>
            <div className="status-wrapper">
              <span className="target">{getEvalTargetFormatted(peerEvalItem.targetRole)} Evaluation</span>{' '}
              <span>{getCompletionStatus(peerEvalItem.complete)}</span>
            </div>

            {peerEvalItem.comments.length > 0 ? (
              <>
                <h3>Team Member Evaluation Comments</h3>
                {peerEvalItem.comments.map((commentItem) => (
                  <section key={`dialogue-${commentItem.comment.commentId}`} className="dialogue-container">
                    <h4>
                      on &quot;<i>{commentItem.comment.commentName}</i>&quot;
                    </h4>
                    <ReviewDialogue commentWithReviewComments={commentItem} teacherView submitterView />
                  </section>
                ))}
              </>
            ) : null}

            {peerEvalItem.ratings.length > 0 ? (
              <RatingDetailsTable heading="Team Member Evaluation Ratings" reviewableByItem={peerEvalItem} />
            ) : null}
          </div>
        ))}
      </>
    );
  return <></>;
}

export default EvalsReceivedDetails;
