import React, { useState } from 'react';
import { ReviewManagementMenuProps } from './ReviewManagement';
import Button from '../../core/button/Button/Button';
import Icon from '../../core/display/Icon';
import AlertBar from '../../core/display/AlertBar';
import { useSelector } from 'react-redux';
import { selectAssignment } from '../../../store/selectors';
import LoadingSpinner from '../../core/layout/LoadingSpinner/LoadingSpinner';
import { deleteAssignmentReviews } from '../../../utils/requests';
import { useParams } from 'react-router-dom';

function DeleteReviewMenu({ changeToMenu, updateData }: ReviewManagementMenuProps): JSX.Element {
  const { assignmentId } = useParams() as { assignmentId: string };

  const [checkConfirm, setCheckConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const assignment = useSelector(selectAssignment);

  if (assignment) {
    const showWarning = (assignment.progressStats?.reviewCount ?? 1) > 0;
    return (
      <div className="page" id="review-management">
        <div id="delete-assigned-reviews">
          <Button className="close-btn button-mini" classOverride onClick={() => changeToMenu('MAIN')}>
            <Icon code="close" />
          </Button>
          <h1>Delete All Reviews</h1>

          {showWarning ? (
            <>
              <AlertBar>
                Are you sure you want to delete all reviews for this assignment? This will delete student progress
                because reviews have already been distributed.
              </AlertBar>
              <div className="input-wrapper">
                <label htmlFor="check-confirm">Yes I am sure, proceed:</label>
                <input
                  id="check-confirm"
                  type="checkbox"
                  checked={checkConfirm}
                  onChange={(e) => setCheckConfirm(e.target.checked)}
                />
              </div>
            </>
          ) : (
            <p>Are you sure you want to delete all assigned reviews for this assignment?</p>
          )}

          <Button
            disabled={(showWarning && !checkConfirm) || loading}
            onClick={() => {
              setLoading(true);
              deleteAssignmentReviews(assignmentId, () => {
                updateData();
                changeToMenu('MAIN');
              });
            }}
          >
            Delete
          </Button>
          {loading ? <LoadingSpinner /> : null}
        </div>
      </div>
    );
  }
  return <LoadingSpinner />;
}

export default DeleteReviewMenu;
