import React, { useMemo, useState } from 'react';
import { closeModal, useModalContext } from '../../contexts/ModalContext';
import Button from '../core/button/Button/Button';
import AssignmentPhaseIcon from '../core/display/AssignmentPhaseIcon/AssignmentPhaseIcon';
import logoIcon from '../../assets/logoIcon.png';
import studentSubIcon from '../../assets/assignment-settings-icons/stu-sub.svg';
import studentRevIcon from '../../assets/assignment-settings-icons/stu-rev.svg';
import genFeedbackIcon from '../../assets/assignment-settings-icons/gen-fbk.svg';
import genEvalIcon from '../../assets/assignment-settings-icons/gen-eva.svg';

function AssignmentTutorial(): JSX.Element {
  const [pageIndex, setPageIndex] = useState(0);
  const pages = useMemo(
    () => [
      <PeerceptivPage key="peerceptiv" />,
      <SubmissionPage key="submit" />,
      <ReviewPage key="review" />,
      <FeedbackPage key="feedback" />,
      <EvaluationPage key="evaluation" />,
      <OutroPage key="outro" />,
    ],
    [],
  );

  const { modalDispatch } = useModalContext();

  return (
    <div id="assignment-tutorial">
      {pages[pageIndex]}

      <div className="page-ctrls">
        <Button
          variant="rad alt"
          onClick={() => setPageIndex((prevIndex) => Math.max(0, prevIndex - 1))}
          disabled={pageIndex <= 0}
        >
          Back
        </Button>
        {pageIndex < pages.length - 1 ? (
          <Button variant="rad" onClick={() => setPageIndex((prevIndex) => Math.min(prevIndex + 1, pages.length - 1))}>
            Next
          </Button>
        ) : (
          <Button variant="rad secondary" onClick={() => modalDispatch(closeModal())}>
            Get Started
          </Button>
        )}
      </div>
    </div>
  );
}

function PeerceptivPage(): JSX.Element {
  return (
    <>
      <div className="heading-wrapper">
        <img src={logoIcon} width={48} alt="Peerceptiv Logo" />
        <h1>Peerceptiv Workflow</h1>
      </div>
      <p>
        <i>Peerceptiv</i> guides students through an assignment workflow that will consist of some or all of the
        following <b>phases</b>:
      </p>
      <ul id="phase-list">
        <li>
          <AssignmentPhaseIcon phase="submit" />
          <b>Submission Phase</b>
        </li>
        <li>
          <AssignmentPhaseIcon phase="review" />
          <b>Review Phase</b>
        </li>
        <li>
          <AssignmentPhaseIcon phase="feedback" />
          <b>Feedback Phase</b>
        </li>
        <li>
          <AssignmentPhaseIcon phase="evaluate" />
          <b>Evaluation Phase</b>
        </li>
      </ul>
    </>
  );
}

function SubmissionPage(): JSX.Element {
  return (
    <>
      <div className="heading-wrapper">
        <AssignmentPhaseIcon phase="submit" size={48} />
        <h1>Submission Phase</h1>
      </div>
      <p>
        In the <b>Submission Phase</b>, work (e.g. research paper, lab report, etc.) is submitted for review.
      </p>
      <figure>
        <img src={studentSubIcon} width="160" alt="Individual Submission" />
        <figcaption>Individual Submission</figcaption>
      </figure>
    </>
  );
}

function ReviewPage(): JSX.Element {
  return (
    <>
      <div className="heading-wrapper">
        <AssignmentPhaseIcon phase="review" size={48} />
        <h1>Review Phase</h1>
      </div>
      <p>
        In the <b>Review Phase</b>, students and/or instructors review the submissions using a rubric.
      </p>
      <figure>
        <img src={studentRevIcon} width="240" alt="Peer Review" />
        <figcaption>Peer Review</figcaption>
      </figure>
    </>
  );
}

function FeedbackPage(): JSX.Element {
  return (
    <>
      <div className="heading-wrapper">
        <AssignmentPhaseIcon phase="feedback" size={48} />
        <h1>Feedback Phase</h1>
      </div>
      <p>
        In the <b>Feedback Phase</b>, the submitter responds to the reviewers about the quality and helpfulness of their
        reviewing comments.
      </p>
      <figure>
        <img src={genFeedbackIcon} width="240" alt="Feedback" />
        <figcaption>Feedback</figcaption>
      </figure>
    </>
  );
}

function EvaluationPage(): JSX.Element {
  return (
    <>
      <div className="heading-wrapper">
        <AssignmentPhaseIcon phase="evaluate" size={48} />
        <h1>Team Member Evaluation Phase</h1>
      </div>
      <p>
        The <b>Team Member Evaluation Phase</b> allows students to evaluate the contributions of their group members. It
        can be a stand-alone assignment or an optional phase for group assignments.
      </p>
      <figure>
        <img src={genEvalIcon} width="240" alt="Team Member Evaluation" />
        <figcaption>Team Member Evaluation</figcaption>
      </figure>
    </>
  );
}

function OutroPage(): JSX.Element {
  return (
    <>
      <div className="heading-wrapper">
        <h1>Create an Assignment</h1>
      </div>
      <p>Now it&apos;s time to create your assignment!</p>
      <p>
        <b>Standard Workflows</b> help you get started faster. Select pre-designed phases that fit your assignment.
      </p>
      <p>
        <b>Build a Custom Assignment</b> is for advanced users with more specialized needs.
      </p>
      <p>
        <b>Copy an Assignment</b> to use an assignment that has already been created by you, <i>Peerceptiv</i>, or a
        colleague.
      </p>
    </>
  );
}

export default AssignmentTutorial;
